import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../Login.css";
import { toast } from "react-toastify";
import { newPassword } from "../../../services/ProfileService";
import Logo from "../../../assets/img/brand/logo.png";

function ResetPassword() {
  const navigate = useNavigate();
  const { token, id } = useParams();
  const initialValues = {
    new_password: "",
    confirmPassword: "",
  };
  const [values, setValues] = useState(initialValues);
  const [showPassword, setShowPassword] = useState({
    newPass: false,
    newCPass: false,
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(10 * 60); // 10 minutes timer

  useEffect(() => {
    if (token && id) {
      console.log("token id", token, id);
    }
  }, [token, id]);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(countdown);
    } else {
      navigate("/login");
    }
  }, [timer, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!values.new_password) {
      validationErrors.new_password = "New password is required";
    }
    if (values.new_password !== values.confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setIsLoading(true);
    try {
      const payload = {
        user_id: id,
        token,
        password: values.new_password,
      };
      const resp = await newPassword(payload);
      toast.success(resp.data.message);
      navigate("/login");
      setValues(initialValues);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div className="container-fluid">
      <div className="background"></div>
      <div
        className="content"
        style={{ margin: "10vh auto", width: "90%", maxWidth: "500px" }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <img
            src={Logo}
            alt="Logo"
            className="logo"
            style={{
              width: "70px",
              height: "70px",
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
              borderRadius: "50%",
            }}
          />
        </div>
        <div className="club-title-container d-flex align-items-center justify-content-center">
          <h1
            className="club-title"
            style={{
              fontFamily: "'Raleway', sans-serif",
              fontSize: "2em",
              color: "white",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
              margin: "0",
            }}
          >
            Rourkela Club
          </h1>
        </div>
        <h2
          className="mb-3 d-flex justify-content-center"
          style={{ fontSize: "0.8em", color: "white" }}
        >
          Reset Your Password
        </h2>

        <div className="formCenter">
          <form className="formFields" onSubmit={handleSubmit}>
            <div className="formField">
              <label className="formFieldLabel" htmlFor="new_password">
                New Password
              </label>
              <input
                type={showPassword.newPass ? "text" : "password"}
                id="new_password"
                placeholder="Enter New Password"
                name="new_password"
                autoComplete="new-password"
                value={values.new_password}
                onChange={handleChange}
                className="form-control border-end-0"
                disabled={isLoading}
              />
              {errors.new_password && (
                <div className="text-danger small mt-1">
                  {errors.new_password}
                </div>
              )}
            </div>

            <div className="formField">
              <label className="formFieldLabel" htmlFor="confirmPassword">
                Confirm Password
              </label>
              <input
                type={showPassword.newCPass ? "text" : "password"}
                id="confirmPassword"
                placeholder="Confirm New Password"
                name="confirmPassword"
                autoComplete="new-password"
                value={values.confirmPassword}
                onChange={handleChange}
                className="form-control border-end-0"
                disabled={isLoading}
              />
              {errors.confirmPassword && (
                <div className="text-danger small mt-1">
                  {errors.confirmPassword}
                </div>
              )}
            </div>
            <button className="formFieldButton w-100 mt-2" disabled={isLoading || timer <= 0}>
              {isLoading ? (
                <div className="d-flex align-items-center">
                  <div className="spinner-border text-light me-2" role="status">
                    <span className="sr-only">Confirm...</span>
                  </div>
                  <span>Confirm...</span>
                </div>
              ) : timer > 0 ? (
                `Confirm (${formatTime(timer)})`
              ) : (
                "Time's up!"
              )}
            </button>
            <Link
              to="/login"
              className="d-flex justify-content-center align-items-center mt-2"
            >
              Go Back To Login
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
