import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal, Form, Button } from "react-bootstrap";
import { updateUserProfilePassword } from "../../services/ProfileService";

function ChangePassword() {
  const initialValues = {
    current_password: "",
    new_password: "",
    confirmPassword: "",
  };

  const [showPassword, setShowPassword] = useState({
    cPass: false,
    newPass: false,
    newCPass: false,
  });

  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleTogglePassword = (name) => {
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!values.current_password) {
      validationErrors.current_password = "Current password is required";
    }
    if (!values.new_password) {
      validationErrors.new_password = "New password is required";
    }
    if (values.new_password !== values.confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const resp = await updateUserProfilePassword(values);
      toast.success(resp.data.message);
      navigate("/");
      toggleModal(false);
      setValues(initialValues);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const toggleModal = (show) => {
    if (!show) {
      setValues(initialValues);
    }
    setShowModal(show);
  };

  const [values, setValues] = useState(initialValues);

  return (
    <>
      <button
        className="change-password-btn btn btn-sm btn-danger"
        size="sm"
        onClick={() => toggleModal(true)}
      >
        Change Password
      </button>
      <Modal
        id="changePasswordModal"
        show={showModal}
        centered={true}
        onHide={() => toggleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title  style={{color:"white"}}>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="current_password">
                Current Password <span className="text-danger">*</span>
              </Form.Label>
              <div className="input-group">
                <Form.Control
                  type={showPassword.cPass ? "text" : "password"}
                  id="current_password"
                  placeholder="Enter Current Password"
                  name="current_password"
                  autoComplete="new-password"
                  value={values.current_password}
                  onChange={handleChange}
                  className="form-control border-end-0"
                />
                
              </div>
              {errors.current_password && (
                <div className="text-danger small mt-1">
                  {errors.current_password}
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="new_password">
                New Password <span className="text-danger">*</span>
              </Form.Label>
              <div className="input-group">
                <Form.Control
                  type={showPassword.newPass ? "text" : "password"}
                  id="new_password"
                  placeholder="Enter New Password"
                  name="new_password"
                  autoComplete="new-password"
                  value={values.new_password}
                  onChange={handleChange}
                  className="form-control border-end-0"
                />
               
              </div>
              {errors.new_password && (
                <div className="text-danger small mt-1">
                  {errors.new_password}
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="confirmPassword">
                Confirm New Password <span className="text-danger">*</span>
              </Form.Label>
              <div className="input-group">
                <Form.Control
                  type={showPassword.newCPass ? "text" : "password"}
                  id="confirmPassword"
                  placeholder="Confirm New Password"
                  name="confirmPassword"
                  autoComplete="new-password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  className="form-control border-end-0"
                />
                
          
              </div>
              {errors.confirmPassword && (
                <div className="text-danger small mt-1">
                  {errors.confirmPassword}
                </div>
              )}
            </Form.Group>

            <div className="d-flex justify-content-between mt-5">
              <Button
                type="button"
                className="btn btn-secondary"
                onClick={() => toggleModal(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
              >
                Update Password
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChangePassword;



