import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { generatePaytmChecksum, generateRepaymentPaytmChecksum, callbackPayment } from "../../../../services/RoomService";

const loadRazorpayScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => reject(new Error("Script load error"));
    document.body.appendChild(script);
  });
};

const RazorpayPayment = ({
  price,
  roomId,
  bookingId,
  bookingDate,
  bookingTime,
  paymentSource,
  paymentMode,
  coupon_id,
  isPendingBooking
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadRazorpayScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);

  const initiatePayment = async () => {
    setIsProcessing(true);

    try {
      let paymentData;
      if (isPendingBooking) {
        const response = await generateRepaymentPaytmChecksum({ bookingId, price });
        console.log('price', price)
        paymentData = response.data.results;
      } else {
        paymentData = {
          price,
          room_id: roomId,
          bookingDate,
          bookingTime,
          coupon_id,
        };
        const resp = await generatePaytmChecksum(paymentData);
        paymentData = resp.data.results;
      }

      const amountInPaise = price * 100;
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amountInPaise,
        currency: paymentData.currency,
        name: "Rourkela Club",
        description: "Booking Payment",
        order_id: paymentData.order_id,
        handler: async (response) => {
          try {
            const paymentVerification = await callbackPayment({
              ...paymentData,
              paymentId: response.razorpay_payment_id,
              orderId: response.razorpay_order_id,
              signature: response.razorpay_signature,
              booking_slot: paymentData.booking_slot,
              booking_id: paymentData.booking_id,
              discount_amount: paymentData.discount_amount,
              amount: paymentData.amount,
              paymentSource,
              paymentMode
            });
            toast.success("Payment successful!"); 
            navigate(`/room-details/${roomId}?payment_id=${response.razorpay_payment_id}`);
          } catch (err) {
            toast.error("Payment verification failed");
            console.error("Payment verification failed", err);
            navigate(`/room-details/${roomId}?payment_id=${response.razorpay_payment_id}`);
          }
        },
        prefill: {
          name: "Customer Name",
          email: "customer@example.com",
          contact: "7605926223",
        },
        notes: {
          address: "Your Company Address",
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: () => {
            setIsProcessing(false);
          }
        }
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      toast.error(error.response?.data?.message || "Payment initiation failed");
      console.error("Payment initiation failed:", error);
      setIsProcessing(false);
    }
  };

  return (
    <div>
      <button
        type="submit"
        className={`btn btn-sm ${isPendingBooking? "btn-primary" : "btn-success"} me-2`}
        onClick={initiatePayment}
        disabled={isProcessing}
      >
        {isProcessing ? "Processing..." : "Pay Now"}
      </button>
    </div>
  );
};

export default RazorpayPayment;
