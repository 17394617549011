import axiosInstance from "./AxiosInstance";

const apiNotification = "api/notification";

// Fetch notifications
export function getNotification() {
  return axiosInstance.get(apiNotification).then((response) => response.data);
}

export function markNotificationsAsRead(ids, isRead = true) {
    const payload = {
      ids: Array.isArray(ids) ? ids : [ids],
      is_read: isRead,
    };
    
    return axiosInstance.put(apiNotification, payload);
  }
  