import React from "react";
import { Media } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { getUserNameIcon } from "../../../../utils";
import { connect } from "react-redux";

function BottomNavbar({ user, userRole }) {
  const navigate = useNavigate();

  return (
    <div className="bottom-navbar px-2 z-50">
      <div
        className="bottom-btn text-center mb-0"
        style={{ fontSize: "0.9rem" }}
        onClick={() =>
          navigate(userRole === "User" ? "/" : "/dashboard")
        }
      >
        <i className="fa-solid fa-house"></i> <br />
        Home
      </div>
        <div
        className="bottom-btn text-center mb-0"
        style={{ fontSize: "0.9rem" }}
          onClick={() =>
          navigate(userRole === "User" ? "/booking-history" : "/booking-list")
        }
        >
          <i className="fas fa-history"></i>
          <br />
          Booking History
        </div>
      <div
        className="bottom-btn profile-container"
        onClick={() =>
          navigate(userRole === "User" ? "/user-profile" : "/profile")
        }
      >
        <Media className="avatar avatar-sm rounded-circle profile-avatar">
          {user?.profile_photo ? (
            <img
              src={`${process.env.REACT_APP_BASE_URL}${user.profile_photo}`}
              alt="profile"
              style={{ height: "100%", width: "100%", marginTop: "0" }}
            />
          ) : (
            <div className="avatar avatar-sm rounded-circle">
              {getUserNameIcon(user?.first_name + " " + user?.last_name)}
            </div>
          )}
        </Media>
        <Media className="mb-0 text-sm text-gray-500 text-center">
          {user?.first_name} {user?.last_name}
        </Media>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    userRole: state.auth.user?.role || "User",
  };
};

export default connect(mapStateToProps)(BottomNavbar);
