import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../components/Headers/Header.js";

const Terms = () => {
    return (
        <>
            <Header />
            <Container className="Container mt-sm-0 mt-5 mb-sm-0 mb-7 p-sm-2 p-1" fluid>
                <div className="text-center mb-3">
                    <div className="d-flex align-items-center justify-content-center text-lg">
                        <img
                            className="me-3 header-logo h-10"
                            alt="..."
                            src={require("../../assets/img/brand/logo.png")}
                        />
                        <h1 className="m-0 text-uppercase">
                            <strong>Rourkela CLUB</strong>
                        </h1>
                    </div>
                    <h1 className="text-lg">Terms of Service</h1>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>1. Introduction</strong></h2>
                    <p>Welcome to Rourkela Club. These Terms of Service ("Terms") govern your access to and use of our website and services. By accessing or using our site, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you should not use our services.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>2. Eligibility</strong></h2>
                    <h3>2.1 Minimum Age Requirement</h3>
                    <p>You must be at least 18 years old to use our services. By using our site, you confirm that you are of legal age and have the authority to enter into this agreement.</p>

                    <h3>2.2 Legal Capacity</h3>
                    <p>If you are using our services on behalf of an organization, you represent and warrant that you have the authority to bind that organization and its affiliates to these Terms.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>3. User Responsibilities</strong></h2>
                    <h3>3.1 Account Security</h3>
                    <p>You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>

                    <h3>3.2 Prohibited Activities</h3>
                    <p>You agree not to engage in any illegal activities, harassment, spamming, or other disruptive behavior. This includes:</p>
                    <ul>
                        <li>Posting or transmitting content that is defamatory, obscene, offensive, or unlawful.</li>
                        <li>Attempting to gain unauthorized access to our systems or user accounts.</li>
                        <li>Interfering with the functioning of our services through the use of malware or other harmful software.</li>
                    </ul>

                    <h3>3.3 Content Responsibility</h3>
                    <p>You are responsible for the content you post or transmit through our services. You must ensure that all content complies with applicable laws and does not infringe upon any third-party rights.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>4. Intellectual Property</strong></h2>
                    <h3>4.1 Ownership</h3>
                    <p>All content, including text, images, logos, and other materials on our site, is owned by us or our licensors and is protected by intellectual property laws.</p>

                    <h3>4.2 License to Use</h3>
                    <p>You may access and use our content solely for your personal, non-commercial purposes. You may not copy, reproduce, distribute, modify, or create derivative works of our content without our express written permission.</p>

                    <h3>4.3 Trademarks</h3>
                    <p>The trademarks, service marks, and logos displayed on our site are our registered or unregistered trademarks. You may not use these trademarks without our prior written consent.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>5. Privacy</strong></h2>
                    <h3>5.1 Privacy Policy</h3>
                    <p>Our Privacy Policy explains how we collect, use, and protect your personal information. By using our site, you consent to the collection and use of your information as described in our Privacy Policy.</p>

                    <h3>5.2 Data Security</h3>
                    <p>We are committed to safeguarding your data and employing appropriate measures to protect it. However, you are also responsible for securing your account and any personal information you provide.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>6. Limitation of Liability</strong></h2>
                    <h3>6.1 Disclaimer of Warranties</h3>
                    <p>Our site and services are provided "as is" and "as available" without any warranties, either express or implied. We do not warrant that our site will be uninterrupted or error-free.</p>

                    <h3>6.2 Limitation of Liability</h3>
                    <p>To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our services. This includes, but is not limited to, damages for loss of profits, data, or other intangible losses.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>7. Termination</strong></h2>
                    <h3>7.1 Right to Terminate</h3>
                    <p>We reserve the right to suspend or terminate your account at our discretion, without prior notice, if you violate these Terms or for any other reason.</p>

                    <h3>7.2 Effect of Termination</h3>
                    <p>Upon termination of your account, your access to our services will be revoked, and you will no longer be able to use or access any content or features associated with your account.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>8. Governing Law</strong></h2>
                    <h3>8.1 Applicable Law</h3>
                    <p>These Terms are governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.</p>

                    <h3>8.2 Dispute Resolution</h3>
                    <p>Any disputes arising out of or in connection with these Terms or your use of our services will be resolved in the courts of [Your Jurisdiction].</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>9. Modifications to Terms</strong></h2>
                    <h3>9.1 Changes</h3>
                    <p>We reserve the right to modify these Terms at any time. Any changes will be effective when we post the updated Terms on our site. Your continued use of our services after such changes constitutes your acceptance of the new Terms.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>10. Miscellaneous</strong></h2>
                    <h3>10.1 Entire Agreement</h3>
                    <p>These Terms constitute the entire agreement between you and us regarding your use of our services and supersede all prior agreements and understandings.</p>

                    <h3>10.2 Severability</h3>
                    <p>If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</p>

                    <h3>10.3 No Waiver</h3>
                    <p>Our failure to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision.</p>
                </div>


                <div class="section contact-info px-4 py-2">
                    <h2><strong>6. Contact Information</strong></h2>
                    <p>For any queries or concerns regarding refunds, please contact:</p>
                    <p><strong>Email:</strong> <a href="mailto:booking@rourkelaclub.org">booking@rourkelaclub.org</a></p>
                    <p><strong>Phone:</strong> [98XXXXXXXX]</p>
                </div>
            </Container>
        </>
    );
};

export default Terms;
