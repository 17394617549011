import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { bookingRoom } from "../../../../services/RoomService";
import "./BookingPage.css";

const AdminPaymentDetails = ({ roomId, room, handleCancelBook, coupon, isMobile }) => {
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
  const [selectedPaymentSource, setSelectedPaymentSource] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [screenshot, setScreenshot] = useState(null);
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const handlePaymentStatusChange = (status) => {
    setSelectedPaymentStatus(status);
    if (status === "Paid") {
      setIsOffCanvasOpen(true);
    } else {
      setIsOffCanvasOpen(false);
    }
  };

  const handlePaymentModeSelect = (mode) => {
    const descriptions = {
      Cash: "Pay by cash at the venue.",
      PhonePe: "Transfer via PhonePe.",
      DebitCard: "Pay using your Debit Card.",
      GooglePay: "Transfer via GooglePay.",
      Paytm: "Transfer via Paytm.",
      NEFT: "Transfer via NEFT.",
      IMPS: "Transfer via IMPS.",
    };
    setAlertMessage(descriptions[mode]);
    setSelectedPaymentSource(mode);
  };

  const handleFileChange = (e) => {
    setScreenshot(e.target.files[0]);
  };

  const handleCloseOffCanvas = () => {
    setIsOffCanvasOpen(false);
    setSelectedPaymentStatus("");
  };

  const handleConfirmBooking = async (e) => {
    e.preventDefault();

    try {
      setIsBookingLoading(true);

      const formData = new FormData();
      formData.append("room_id", roomId);
      formData.append("booking_date", format(room?.booking_date, "yyyy-MM-dd"));
      formData.append("booking_time", room?.booking_time);
      formData.append("on_behalf_id", room?.on_behalf_id || "");
      formData.append("paymentSource", selectedPaymentSource);
      formData.append("transactionId", transactionId);
      formData.append("coupon_id", coupon?.id || null);

      if (screenshot) {
        formData.append("attachment", screenshot);
      }

      const resp = await bookingRoom(formData);
      toast.success(resp.data.message);
      navigate(`/room/${roomId}`);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred.");
    } finally {
      setIsBookingLoading(false);
    }
  };

  const handlePendingBooking = async (e) => {
    e.preventDefault();

    try {
      setIsBookingLoading(true);

      const payload = {
        room_id: roomId,
        booking_date: format(room?.booking_date, "yyyy-MM-dd"),
        booking_time: room?.booking_time,
        on_behalf_id: room?.on_behalf_id || "",
        paymentSource: selectedPaymentSource,
        coupon_id: coupon?.id || null,
      };

      const resp = await bookingRoom(payload);
      toast.success(resp.data.message);
      navigate(`/room/${roomId}`);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred.");
    } finally {
      setIsBookingLoading(false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 mt-3">
          <div>
            <strong>Booking Date: </strong>
            <span className="title text-dark">
              {format(room?.booking_date, "yyyy-MM-dd")}
            </span>
          </div>
          <div>
            <strong>Booking Time: </strong>
            <span className="title text-dark">
              {room?.booking_time}
            </span>
          </div>
        </div>

        <div className="col-md-6">
          <div className="payment-options d-flex flex-column align-items-center mb-3">
            <div
              className={`payment-option-wrapper d-flex justify-content-center ${
                selectedPaymentStatus === "Not Paid" ? "mb-0" : "mb-3"
              }`}
            >
              <div className="payment-option mx-2">
                <input
                  type="radio"
                  id="Paid"
                  name="payment-status"
                  value="Paid"
                  checked={selectedPaymentStatus === "Paid"}
                  onChange={() => handlePaymentStatusChange("Paid")}
                />
                <label className="form-check-label ml-2" htmlFor="Paid">
                  Paid
                </label>
              </div>

              <div className="payment-option mx-2">
                <input
                  type="radio"
                  id="NotPaid"
                  name="payment-status"
                  value="Not Paid"
                  checked={selectedPaymentStatus === "Not Paid"}
                  onChange={() => handlePaymentStatusChange("Not Paid")}
                />
                <label className="form-check-label me-6" htmlFor="NotPaid">
                  Not Paid
                </label>
              </div>
            </div>

            { selectedPaymentStatus === "Not Paid" && (
              <div className="dropdown-filter-status mb-2 w-100 mx-auto">
                <span
                  className="cash-dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {selectedPaymentSource || "Payment Source"}
                  <span className="dropdown-arrow"></span>
                </span>
                <div
                  className="dropdown-menu cash-dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {[
                    "Cash",
                    "PhonePe",
                    "GooglePay",
                    "Paytm",
                    "NEFT",
                    "IMPS",
                  ].map((mode) => (
                    <span
                      key={mode}
                      className="dropdown-item cash-dropdown-item"
                      onClick={() => handlePaymentModeSelect(mode)}
                    >
                      {mode}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="d-flex align-items-center justify-content-center">
            {selectedPaymentStatus === "Not Paid" && (
              <button
                type="submit"
                className="btn btn-sm btn-success"
                onClick={handlePendingBooking}
                disabled={isBookingLoading}
              >
                {isBookingLoading ? "Processing..." : "Confirm"}
              </button>
            )}
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={handleCancelBook}
            >
              Cancel
            </button>
          </div>

      {isOffCanvasOpen && (
        <div className={`offcanvas-container mt-sm-0 mt-5 ${isOffCanvasOpen ? "open" : ""}`}>
          <div className="offcanvas-content">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h1>
                <strong>Payment Details</strong>
              </h1>
              <button className="btn text-xl" onClick={handleCloseOffCanvas}>
                &times;
              </button>
            </div>
            <div className="mb-3">
              <label htmlFor="paymentSource">
                <strong style={{ fontSize: "0.85em" }} className="me-1">
                  Payment Source
                </strong>
                <span className="text-danger">*</span>
              </label>
              <select
                id="paymentSource"
                className="form-control"
                value={selectedPaymentSource}
                onChange={(e) => setSelectedPaymentSource(e.target.value)}
                required
              >
                <option value="">Select Payment Source</option>
                <option value="Cash">Cash</option>
                <option value="PhonePe">PhonePe</option>
                <option value="GooglePay">GooglePay</option>
                <option value="Paytm">Paytm</option>
                <option value="NEFT">NEFT</option>
                <option value="IMPS">IMPS</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="transactionId">
                <strong style={{ fontSize: "0.85em" }} className="me-1">
                  Transaction ID
                </strong>
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="transactionId"
                className="form-control"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="screenshot">
                <strong style={{ fontSize: "0.85em" }} className="me-1">
                  Upload Screenshot
                </strong>
              </label>
              <input
                type="file"
                id="screenshot"
                className="form-control"
                onChange={handleFileChange}
              />
            </div>
            <div className="d-flex align-items-center justify-content-evenly">
              <button
                type="submit"
                className="btn btn-sm btn-success"
                onClick={handleConfirmBooking}
                disabled={!selectedPaymentSource || !transactionId}
              >
                Confirm
              </button>
              <button
                type="submit"
                className="btn btn-sm btn-danger"
                onClick={handleCloseOffCanvas}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
      </div>
    </>
  );
};

export default AdminPaymentDetails;

