import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  UPDATE_PROFILE,
  AUTH_CHECK_CONFIRMED_ACTION,
  AUTH_CHECK_FAILED_CONFIRMED_ACTION,
  UPDATE_LAST_ACTIVITY_TIME,
  TOGGLE_SIDEBAR, 
  RESET_STORE,
  UPDATE_ORDERID
} from "../actions/AuthActions";
import { toast } from "react-toastify";

const initialState = {
  user: null,
  isCheckingAuth: true,
  lastActivityTime: Date.now(),
  errorMessage: "",
  successMessage: "",
  showLoading: false,
  sidebarToggle: false,
  visitorCount: null,
  orderId: null,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_CONFIRMED_ACTION:
      return {
        ...state,
        user: action.payload,
        lastActivityTime: Date.now(),
        isCheckingAuth: false,
        showLoading: false,
      };
    case AUTH_CHECK_CONFIRMED_ACTION:
      return {
        ...state,
        isCheckingAuth: action.payload,
      };
    case AUTH_CHECK_FAILED_CONFIRMED_ACTION:
      return {
        ...state,
        user: null,
        isCheckingAuth: false,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        showLoading: false,
      };
    case LOGOUT_ACTION:
      return {
        ...state,
        isCheckingAuth: false,
        user: null,
      };
    case LOGIN_FAILED_ACTION:
      toast.error(action.payload);
      return {
        ...state,
        showLoading: false,
      };
    case LOADING_TOGGLE_ACTION:
      return {
        ...state,
        showLoading: action.payload,
      };
    case UPDATE_LAST_ACTIVITY_TIME:
      return {
        ...state,
        lastActivityTime: Date.now(),
      };
    case UPDATE_ORDERID:
      return {
        ...state,
        orderId: action.payload,
      };
      case TOGGLE_SIDEBAR:
        return {
          ...state,
          sidebarToggle: !state.sidebarToggle,
        };
        case RESET_STORE:
        return {
          ...state,
          user:null
        };
    default:
      return state;
  }
}
