import React, { useEffect, useState } from "react";
import "./memberCustom.css";
import { Table, Container } from "reactstrap";
import Header from "../../components/Headers/Header";
import { getMemberBookingHistory } from "../../services/VerifyMemberService";
import { LuArrowUpDown } from "react-icons/lu";
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";

const UserDetailsPage = ({ userData, handleGoBack }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  useEffect(() => {
    getMemberBookingHistory(userData.id)
      .then((resp) => {
        setData(resp.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [userData.id]);

  const sortData = (key, direction) => {
    const sortedData = [...data].sort((a, b) => {
      if (key === "booking_slot.price") {
        const priceA = parseFloat(a.booking_slot.room?.price);
        const priceB = parseFloat(b.booking_slot.room?.price);
        return direction === "asc" ? priceA - priceB : priceB - priceA;
      } else if (key.includes(".")) {
        const keys = key.split(".");
        let aValue = a;
        let bValue = b;
        for (let nestedKey of keys) {
          aValue = aValue[nestedKey];
          bValue = bValue[nestedKey];
        }

        if (typeof aValue === 'string' && aValue.match(/^\d{2}-\d{2}-\d{4}$/)) {
          const dateA = new Date(`${aValue.split('-').reverse().join('-')} 00:00:00`);
          const dateB = new Date(`${bValue.split('-').reverse().join('-')} 00:00:00`);
          return direction === "asc" ? dateA - dateB : dateB - dateA;
        }
        return direction === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        if (typeof a[key] === 'string' && a[key].match(/^\d{2}-\d{2}-\d{4}$/)) {
          const dateA = new Date(`${a[key].split('-').reverse().join('-')} 00:00:00`);
          const dateB = new Date(`${b[key].split('-').reverse().join('-')} 00:00:00`);
          return direction === "asc" ? dateA - dateB : dateB - dateA;
        }
        return direction === "asc" ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
      }
    });

    setData(sortedData);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    sortData(key, direction);
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <HiArrowNarrowUp />
      ) : (
        <HiArrowNarrowDown />
      );
    }
    return (
      <>
        <LuArrowUpDown />
      </>
    );
  };

  return (
    <>
      <Header />
      <Container className="mt-sm-3 mt-6 mb-sm-0 mb-7" fluid>
        <div className="card p-3">
          <div className="d-flex align-items-center mx-3 mb-3">
            <button
              type="button"
              className="btn btn-sm border-black text-black rounded-circle"
              onClick={handleGoBack}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            <h3 className="primary">Back To MemberPage</h3>
          </div>
          <div
            className="p-4 border rounded shadow"
            style={{ backgroundColor: "#ffffff", marginBottom: "20px" }}
          >
            <div className="row">
              <div className="col-md-4">
                <div className="d-flex align-items-center justify-content-center mb-4 mt-6">
                  {userData.profile_photo ? (
                    <img
                      className="member-details-profile-photo"
                      alt="Profile"
                      src={`${process.env.REACT_APP_BASE_URL}${userData.profile_photo}`}
                    />
                  ) : (
                    <span className="text-avatar userTextProfile cursor-pointer">
                      {userData.first_name.slice(0, 1)}
                    </span>
                  )}
                </div>
                <h1 className="text-center text-xl mb-5">
                  {userData.first_name} {userData.last_name}
                </h1>
                <div style={{ marginBottom: "10px", color: "#555" }}>
                  <strong style={{ color: "#333" }}>Email : </strong>
                  {userData.email}
                </div>
                <div style={{ marginBottom: "10px", color: "#555" }}>
                  <strong style={{ color: "#333" }}>Phone Number : </strong>
                  {userData.phone_number}
                </div>
                <div style={{ marginBottom: "10px", color: "#555" }}>
                  <strong style={{ color: "#333" }}>Membership Id : </strong>
                  {userData?.membership_id}
                </div>
                <div style={{ marginBottom: "10px", color: "#555" }}>
                  <strong style={{ color: "#333" }}>Membership Type : </strong>
                  {userData?.membership_type}
                </div>
                <div style={{ marginBottom: "10px", color: "#555" }}>
                  <strong style={{ color: "#333" }}>Joining Date : </strong>
                  {userData?.join_date}
                </div>
              </div>
              <div className="col-md-8 booking-history-container">
                {data && data.length > 0 ? (
                  <>
                    <h6 className="mb-2 text-center">Booking History</h6>
                    <div className="table-wrapper">
                      <Table className="booking-table" responsive>
                        <thead className="booking-table-head disable-select">
                          <tr className="booking-table-header">
                            <th scope="col" className="py-2" onClick={() => handleSort("booking_number")}>
                              <div className="table-header-content">
                                Booking No.
                                <span className="sort-icon">{renderSortIcon("booking_number")}</span>
                              </div>
                            </th>
                            <th scope="col" className="py-2" onClick={() => handleSort("booking_slot.room.name")}>
                              <div className="table-header-content">
                                Room Name
                                <span className="sort-icon">{renderSortIcon("booking_slot.room.name")}</span>
                              </div>
                            </th>
                            <th scope="col" className="py-2" onClick={() => handleSort("booking_slot.booking_date")}>
                              <div className="table-header-content">
                                Booking Date
                                <span className="sort-icon">{renderSortIcon("booking_slot.booking_date")}</span>
                              </div>
                            </th>
                            <th scope="col" className="py-2" onClick={() => handleSort("booking_slot.booking_time")}>
                              <div className="table-header-content">
                                Booking Time
                                <span className="sort-icon">{renderSortIcon("booking_slot.booking_time")}</span>
                              </div>
                            </th>
                            <th scope="col" className="py-2" onClick={() => handleSort("booking_slot.price")}>
                              <div className="table-header-content">
                                Price
                                <span className="sort-icon">{renderSortIcon("booking_slot.price")}</span>
                              </div>
                            </th>
                            <th scope="col" className="py-2">
                              <div className="table-header-content">Status</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="booking-table-body">
                          {data.map((item, index) => (
                            <tr key={index} className={`booking-table-row ${item?.status === "Cancelled" ? "text-gray" : ""}`}>
                              <td>{item.booking_number}</td>
                              <td>{item.booking_slot?.room?.name}</td>
                              <td>{item.booking_slot?.booking_date}</td>
                              <td>{item.booking_slot?.booking_time}</td>
                              <td>{(item.booking_slot?.room?.price * 1.18).toFixed(2)}</td>
                              <td>
                                {item?.status === "Cancelled" ? (
                                  <span className="text-danger px-2">Cancelled</span>
                                ) : (
                                  <span className="text-success px-2">Booked</span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </>
                ) : (
                  <div className="text-center">No data available</div>
                )}
              </div>

            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default UserDetailsPage;
