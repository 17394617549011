import axiosInstance from "./AxiosInstance";

export function updateUserProfilePassword(data) {
    return axiosInstance.put("api/auth/profile",data);
  }

  
export function updateUserProfilePhoto(data) {
    return axiosInstance.post("api/auth/profile",data);
  }

export function passwordReset(email) {
    return axiosInstance.post('/api/auth/password_reset', {email});
  }

  export function newPassword(data) {
    return axiosInstance.post(`/api/auth/password_reset_confirm`, data);
  }
  