import axiosInstance from "./AxiosInstance";

export function getBookingAmountGraph(time_period) {
    return axiosInstance.get(
      `api/booking_amount_graph?time_period=${time_period}`
    );
  }
  export function getMostRoomBookingGraph(time_period) {
    return axiosInstance.get(
      `api/most_booking_room?time_period=${time_period}`
    );
  }
  export function getRoomTypeGraph(time_period) {
    return axiosInstance.get(
      `api/booking_room_type_graph?time_period=${time_period}`
    );
  }

  export function getCurrentBookings() {
    return axiosInstance.get(
      "api/current_booking"
    );
  }
  