import axiosInstance from "./AxiosInstance";

const apiCoupon = "api/coupon";
const apiApplyCoupon = "api/apply_coupon"

export function getCouponList() {
  return axiosInstance.get(apiCoupon);
}

export function createCoupon(data) {
  return axiosInstance.post(apiCoupon, data);
}

export function updateCoupon(data) {
  return axiosInstance.put(apiCoupon, data);
}

export function deleteCoupon(id) {
  return axiosInstance.delete(`${apiCoupon}/${id}`);
}

export function getApplyCouponList() {
  return axiosInstance.get(apiApplyCoupon );
}