import React from "react";

const NoResultFound = () => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 py-8" >
        <div className="text-center">
          <i
            className="fas fa-search-minus"
            style={{ fontSize: "2rem", opacity: 0.8, color: "#17a2b8" }}
          ></i>
          <h3 style={{ opacity: 0.5 }}>No Result Found</h3>
        </div>
        </div>
  );
};

export default NoResultFound;
