import React, { useState } from "react";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { verifyMemberAPI } from "../../services/VerifyMemberService";

const INPUT_OPTION_LIST = [
  { label: "SAIL RSP Member", value: "SAIL RSP Member" },
  { label: "GOVERNMENT Member", value: "GOVERNMENT Member" },
  { label: "PRIVATE Member", value: "PRIVATE Member" },
  { label: "Honorary Member", value: "Honorary Member" }
]

const VerifyMemberPopup = ({ member, data, setData }) => {
  const [showForm, setShowForm] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [membershipType, setmembershipType] = useState("SAIL RSP Member");

  const toggleForm = () => {
    if (showForm) {
      setmembershipType("SAIL RSP Member");
    } else {
      setmembershipType(member.membership_type);
    }
    setShowForm(!showForm);
  };



  const handleChange = (e) => {
    const { value } = e.target;
    setmembershipType(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSubmitLoading) {
      return;
    }
    setIsSubmitLoading(true);
    const formData = {
      membership_type: membershipType,
      member_id: member.id
    }
    verifyMemberAPI(formData)
      .then((resp) => {
        const { results, message } = resp.data;
        const updatedData = data.map((d) => d.id === results.id ? results : d);
        setData(updatedData);
        toast.success(message);
        toggleForm();
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  return (
    <div>
      {member.is_verified ? (
        <Button
          className="btn-sm"
          style={{
            color: "white",
            backgroundColor: "green",
            border: "none",
            transition: "background-color 0.3s ease, color 0.3s ease",
          }}>VERIFIED</Button>
      ) : (
        <Button
          className="btn-sm"
          onClick={toggleForm}
          style={{
            color: "white",
            backgroundColor: "grey",
            border: "none",
            transition: "background-color 0.3s ease, color 0.3s "
          }}>PENDING</Button>
      )
      }
      {
        showForm && (
          <div className="popup">
            <form className="popup-inner" onSubmit={handleSubmit}>
              <div className="popup-header">
                <h2>Verify Member</h2>
              </div>
              <div className="popup-body">
                <div className="form-group">
                  <label htmlFor={"membershipType"}>Membership Type:</label>

                  <div className="input-group">
                    <select
                      id={"membershipType"}
                      name={"membershipType"}
                      value={membershipType}
                      onChange={handleChange}
                      className="custom-select"
                    >
                      {INPUT_OPTION_LIST.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                  </div>
                </div>
              </div>
              <div className="btn-container">
                <button type="submit" className="submit-btn" disabled={isSubmitLoading}>Verify</button>
                <button type="button" onClick={toggleForm} className="cancel-btn">Cancel</button>
              </div>
            </form>
          </div>
        )
      }
    </div >
  );
};

export default VerifyMemberPopup;
