import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import "./Room.css"

const MemberDetailsPopup = ({ member, isOpen, toggle }) => {
  if (!member) {
    return null;
  }

  const { profile_photo, first_name, last_name, email, phone_number, membership_id, membership_type } = member;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="member-details-modal" centered>
      <ModalHeader toggle={toggle} className="modal-header-custom">
        <span className="memdel">Member Details</span>
      </ModalHeader>
      <ModalBody className="modal-body-custom p-4">
        <div className="member-details-container">
          <div className="profile-photo-section">
            {profile_photo ? (
              <img
                alt={`${first_name} ${last_name}`}

                className="profile-photo"
                src={`${process.env.REACT_APP_BASE_URL}${profile_photo}`}
              />
            ) : (
              <span className="text-avatar-lg">{first_name.slice(0, 1)}</span>
            )}
          </div>
          <div className="vertical-line"></div>
          <div className="member-info">
            <ul>
              <li className='mx-6 mt--3'><strong><h4>{first_name} {last_name}</h4></strong> </li>
              <li><strong>Email:</strong> {email}</li>
              <li><strong>Phone Number:</strong> {phone_number}</li>
              <li><strong>Membership ID:</strong> {membership_id}</li>
              <li><strong>Membership Type:</strong> {membership_type}</li>
            </ul>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MemberDetailsPopup;
