import axiosInstance from "./AxiosInstance";

const apiUrl = "api/room";
const apiOpenRoom = "api/open_room";
const apiBooking = "api/booking";
const apiRefundBooking = "api/refund_payment";
const apiBookingHistory = "api/booking_history";
const apiBookingStatus = "api/booking_status";
const apiSearchRoom = "api/search_room";
const apiMaintenance = "api/update_maintenance";
const apiRoomAvailable = "api/availability";
const apiRoomHistory = "api/room_history";
const apiGeneratePaytm = "api/initiate_payment";
const apiReGeneratePaytm = "api/reinitiate_payment";
const apiPaymentCallBack = "api/callback_payment";
const apiTransactionStatus = "api/transaction_status";
const apiReferenceId = "api/reference_number";
const apiPaymentList = "api/payment_history";
const apiPaymentSource = "api/payment_option";

// Room-related functions
export function getRoomList(page, page_size, query,  room_type, sort_by = "-created_at") {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}&room_type=${room_type}&sort_by=${sort_by}`
  );
}

export function getOpenRoomList(page, page_size, query, room_type="", sort_by = "-created_at") {
  return axiosInstance.get(
    `${apiOpenRoom}?page=${page}&page_size=${page_size}&query=${query}&room_type=${room_type}&sort_by=${sort_by}`
  );
}

export function getOpenRoom(id) {
  return axiosInstance.get(`${apiOpenRoom}/${id}`);
}

export function getRoom(id) {
  return axiosInstance.get(`${apiUrl}/${id}`);
}

export function createRoom(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateRoom(data) {
  return axiosInstance.put(apiUrl, data);
}

export function deleteRoom(roomId) {
  const url = `${apiUrl}/${roomId}`;
  return axiosInstance.delete(url);
}

export function getFilterRoomList(query, room_type, start_date, end_date, room_slot_type) {
  return axiosInstance.get(
    `${apiSearchRoom}?query=${query}&room_type=${room_type}&start_date=${start_date}&end_date=${end_date}&room_slot_type=${room_slot_type}`
  );
}

// Booking-related functions
export function getBookingList(page, page_size, query, room_type, start_date, end_date,sort_by) {
  return axiosInstance.get(
    `${apiBookingHistory}?page=${page}&page_size=${page_size}&query=${query}&room_type=${room_type}&start_date=${start_date}&end_date=${end_date}&sort_by=${sort_by}`
  );
}

export function bookingRoom(data) {
  return axiosInstance.post(apiBooking, data);
}


export function updateRoomBooking(data) {
  return axiosInstance.put(apiBooking, data);
}

export function CancelRefundRoomBooking(id) {
  return axiosInstance.post(apiRefundBooking, id);
}

export function approveRoomBooking(id) {
  return axiosInstance.patch(apiBooking, { id });
}

export function getBookingStatus(page, page_size, query = "", room_type = "", start_date = "", end_date = "", sort_by = "", status, export_type = false, export_all = false) {
  const params = {
    page,
    page_size,
    status,
    export: export_type,
    export_all: export_all
  };

  if (query) params.query = query;
  if (room_type) params.room_type = room_type;
  if (start_date) params.start_date = start_date;
  if (end_date) params.end_date = end_date;
  if (sort_by) params.sort_by = sort_by;
  if (status) params.status = status;

  return axiosInstance.get(apiBookingStatus, { params });
}


export function getPaymentList(page, page_size, query, status, start_date, end_date,sort_by, date_range) {
  return axiosInstance.get(
    `${apiPaymentList}?page=${page}&page_size=${page_size}&query=${query}&status=${status}&start_date=${start_date}&end_date=${end_date}&sort_by=${sort_by}&date_range=${date_range}`
  );
}

export function updateMaintenanceRoom(room_id, is_maintenance) {
  return axiosInstance.post(apiMaintenance, { room_id, is_maintenance });
}

export function getMaintenanceList(page, page_size, query) {
  return axiosInstance.get(
    `${apiMaintenance}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

// Room availability and history functions
export function getRoomAvailable(room_id) {
  return axiosInstance.get(`${apiRoomAvailable}?room_id=${room_id}`);
}

export function getRoomHistory(room_id) {
  return axiosInstance.get(
    `${apiRoomHistory}?room_id=${room_id}`
  );
}

export function generatePaytmChecksum(data) {
  return axiosInstance.post(apiGeneratePaytm, data);
}
export function generateRepaymentPaytmChecksum(data) {
  return axiosInstance.post(apiReGeneratePaytm, data);
}

export function callbackPayment(data) {
  return axiosInstance.post(apiPaymentCallBack, data);
}

export function CheckTransactionStatus(payment_id) {
  return axiosInstance.get(`${apiTransactionStatus}/${payment_id}`);
}

export function confirmBooking(data) {
  return axiosInstance.post(apiReferenceId, data);
}


export function getPaymentSourceList(page, page_size, query) {
  return axiosInstance.get(
    `${apiPaymentSource}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function createPaymentSource(data) {
  return axiosInstance.post(apiPaymentSource, data);
}

export function updatePaymentSource(data) {
  return axiosInstance.patch(apiPaymentSource, data);
}

export function deletePaymentSource(id) {
  return axiosInstance.delete(`${apiPaymentSource}/${id}`);
}
