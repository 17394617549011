import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "flatpickr/dist/themes/material_blue.css";
import { toast } from "react-toastify";
import { approveRoomBooking } from "../../services/RoomService";

const ApproveBookingModal = ({ booking, updateBookingData }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = (show) => {
    setShowModal(show);
  };

  const handleApprove = async () => {
    try {
      const response = await approveRoomBooking(booking.id);
      const { message, results } = response.data;
      updateBookingData(results);
      toast.success(message);
      toggleModal(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <>
      <div
        onClick={() =>
          (booking.modified_booking_date || booking.modified_booking_time) &&
          toggleModal(true)
        }
      >
        {booking.modified_booking_date || booking.modified_booking_time ? (
          <button className="btn btn-sm btn-outline-success">
            <i className="fa-solid fa-pen-to-square"></i>{" "}
            {booking.booking_slot?.booking_date}
          </button>
        ) : (
          <span>{booking.booking_slot?.booking_date}</span>
        )}
      </div>

      <Modal
        show={showModal}
        onHide={() => toggleModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-white">Modify Booking</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <h5 className="text-center">{booking.booking_slot?.room?.name}</h5>
          <ul>
            <li style={{ fontSize: "0.8rem" }}>
              <strong>Booking Number : </strong>
              <span>{booking?.booking_number}</span>
            </li>
            <li style={{ fontSize: "0.8rem" }}>
              <strong>Booking Date : </strong>
              <span>{booking?.modified_booking_date}</span>
            </li>
            <li style={{ fontSize: "0.8rem" }}>
              <strong>Booking Time : </strong>
              <span>{booking?.modified_booking_time}</span>
            </li>
            <li style={{ fontSize: "0.8rem" }}>
              <strong>Room Price : </strong>
              <span>{booking.booking_slot?.room?.price}</span>
            </li>
            <li style={{ fontSize: "0.8rem" }}>
              <strong>Member Name : </strong>
              <span>
                {booking.user?.first_name} {booking.user?.last_name}
              </span>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => toggleModal(false)}>
            Close
          </Button>
          <Button variant="success" onClick={handleApprove}>
            Approve
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ApproveBookingModal;
