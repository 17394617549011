import React, { useState, useRef } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import { createEvent } from "../../services/EventService.js";
import { toast } from "react-toastify";
import "./Events.css";

const EventForm = ({ data, setData }) => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [eventInput, setEventInput] = useState({
    title: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [photo, setPhoto] = useState(null);
  const [photoName, setPhotoName] = useState("");

  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    setEventInput({ ...eventInput, [name]: value });
  };

  const validateInputs = () => {
    let validationErrors = {};
    if (!eventInput.title) validationErrors.title = "Event Title is required";
    if (!eventInput.description)
      validationErrors.description = "Description is required";
    if (!photo) validationErrors.photo = "Event photo is required";
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      setIsSubmitLoading(true);
      const formData = new FormData();
      Object.entries(eventInput).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (photo) {
        formData.append("photo", photo);
      }
      const resp = await createEvent(formData);
      toast.success(resp.data.message);
      setData([resp.data.results, ...data]);
      setShowModal(false);
      resetForm();
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    resetForm();
  };

  const handleShow = () => setShowModal(true);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
    setPhotoName(file.name);
  };

  const handleDeletePhoto = () => {
    setPhoto(null);
    setPhotoName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const resetForm = () => {
    setEventInput({ title: "", description: "" });
    setPhoto(null);
    setPhotoName("");
    setErrors({});
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <button
        onClick={handleShow}
        className="btn ms-3 bg-black text-white py-2 px-4 rounded flex items-center"
      >
        <FaPlus className="mr-2" />
        Add
      </button>

      <Modal show={showModal} onHide={handleClose} className="event-modal" centered={true}>
        <Modal.Header closeButton>
          <Modal.Title className="text-white">Create Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} className="event-form">
            <div className="event-form-group">
              <label htmlFor="title" className="event-form-label">
                Event Title:
              </label>
              <input
                id="title"
                type="text"
                name="title"
                className={`form-control ${errors.title ? "is-invalid" : ""}`}
                placeholder="Event Title"
                value={eventInput.title}
                onChange={handleChange}
              />
              {errors.title && (
                <div className="invalid-feedback">{errors.title}</div>
              )}
            </div>
            <div className="event-form-group">
              <label htmlFor="description" className="event-form-label">
                Event Description:
              </label>
              <textarea
                id="description"
                name="description"
                className={`form-control ${
                  errors.description ? "is-invalid" : ""
                }`}
                placeholder="Description"
                rows="4"
                value={eventInput.description}
                onChange={handleChange}
              ></textarea>
              {errors.description && (
                <div className="invalid-feedback">{errors.description}</div>
              )}
            </div>
            <div className="event-form-group mb-3">
              <label htmlFor="photo" className="event-form-label">
                Upload Event Image:
              </label>
              <input
                type="file"
                id="photo"
                accept="image/*"
                ref={fileInputRef}
                className={`form-control ${errors.photo ? "is-invalid" : ""}`}
                onChange={handleImageUpload}
              />
              {errors.photo && (
                <div className="text-danger">{errors.photo}</div>
              )}
              {photo && (
                <div className="photo-preview mt-3">
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Event"
                    className="preview-img"
                  />
                  <button
                    type="button"
                    className="delete-photo-btn"
                    onClick={handleDeletePhoto}
                  >
                    <FaTrash />
                  </button>
                </div>
              )}
            </div>
            <button className="btn text-white bg-black" type="submit" disabled={isSubmitLoading}>
              {isSubmitLoading ? "Creating..." : "Create Event"}
            </button>
            <button
              className="btn btn-secondary btn-close"
              type="button"
              onClick={handleClose}
            >
              Close
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EventForm;
