import React from "react";
import { saveAs } from "file-saver";
import { BiExport } from "react-icons/bi";
import useWindowSize from "../../constant/WindowSize.jsx";
import axios from "axios";
import axiosInstance from "../../services/AxiosInstance";

function getCurrentBookings(params) {
  const Token = localStorage.getItem("TOKEN");
  return axiosInstance.get("api/current_booking", {
    headers:
      process.env.REACT_APP_HEADER_TOKEN === "LOCALSTORAGE" && Token
        ? { Authorization: `Bearer ${Token}` }
        : {},
    params,
    responseType: "blob",
  });
}

const DownloadExcel = ({
  currentPage,
  recordPage,
  searchQuery,
  roomType,
  startDate,
  endDate,
  fromDashboard = false,
  bookingType,
  status
}) => {

  const getBookingStatus = (params) => {
    const Token = localStorage.getItem("TOKEN");
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/booking_status`, {
        headers:
          process.env.REACT_APP_HEADER_TOKEN === "LOCALSTORAGE" && Token
            ? { Authorization: `Bearer ${Token}` }
            : {},
        params,
        withCredentials: true,
        responseType: "blob",
      })
      .then((response) => {
        let filename = "booking_status_report.xlsx";
        saveAs(new Blob([response.data]), filename);
        return response;
      })
      .catch((error) => {
        console.error("Error fetching booking status:", error);
        throw error;
      });
  };
  const handleDownloadCurrentPage = () => {
    const params = {
      page: currentPage,
      page_size: recordPage,
      query: searchQuery,
      room_type: roomType,
      export: true,
      export_all: false,
      start_date: startDate,
      end_date: endDate,
      status:status,
      bookingType: bookingType,
    };
    getBookingStatus(params);
  };

  const handleDownloadAll = () => {
    const params = {
      query: searchQuery,
      room_type: roomType,
      export: true,
      export_all: true,
      start_date: startDate,
      end_date: endDate,
      status:status,
      bookingType: bookingType,
    };
    getBookingStatus(params);
  };

  const handleDownload = () => {
    const params = { export: true };

    // Use the status prop to determine which type of export to perform
    switch (bookingType) {
      case "today":
        params.export_today = true;
        break;
      case "tomorrow":
        params.export_tomorrow = true;
        break;
      case "pending":
        params.export_pending = true;
        break;
      case "cancelled":
        params.export_cancelled = true;
        break;
      default:
        break;
    }

    getCurrentBookings(params)
      .then((response) => {
        let filename = `booking_status_${bookingType}.xlsx`;
        saveAs(new Blob([response.data]), filename);
      })
      .catch((error) => {
        console.error("Error fetching booking status:", error);
      });
  };

  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  return (
    <div className="dropdown-filter-status">
      {fromDashboard ? (
        <button
          className="btn text-white btn-success d-flex align-items-center cursor-pointer"
          onClick={handleDownload}
        >
          <BiExport style={{ fontSize: '24px' }} />
        </button>
      ) : (
        <button
          className="btn text-white btn-success dropdown-toggle d-flex align-items-center cursor-pointer"
          type="button"
          id="roomTypeDropdown"
        >
          <BiExport className="me-2" style={{ fontSize: '24px' }} />
        </button>
      )}
      {!fromDashboard && (
        <ul className="dropdown-filter-menu" aria-labelledby="roomTypeDropdown">
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={handleDownloadCurrentPage}
            >
              Current Page
            </a>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={handleDownloadAll}
            >
              All Pages
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default DownloadExcel;
