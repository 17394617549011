import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useWindowSize from "../../../../constant/WindowSize";
import AllRooms from "./AllRooms";
import { getOpenRoom, CheckTransactionStatus } from "../../../../services/RoomService";
import CalendarPopUp from "../PopUpPage/CalendarPopUp";
import PaymentStatusModal from "../PopUpPage/PaymentStatusModal";
import Loader from "../../../Loader/Loader";

const UserRoomDetails = ({ user }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  useEffect(() => {
    setIsLoading(true);
    getOpenRoom(id)
      .then((resp) => {
        setData(resp.data.result);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
        setData(null);
      });
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.state]);

  const toggleModal = () => {
    if(isModalOpen){
      setPaymentData(null)
    }
    setIsModalOpen(!isModalOpen);
  };

  async function getTransactionStatus(payment_id) {
    try {
      toggleModal()
      const resp = await CheckTransactionStatus(payment_id);
      const data = resp.data.results;
      setPaymentData(data)
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error checking payment status:", error);
      toggleModal()
    }
  }


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const payment_id = queryParams.get("payment_id");
    if (payment_id) {
      getTransactionStatus(payment_id)
      navigate(location.pathname, { replace: true });
    }
  }, [location.search]);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const handleBookNow = (room) => {
    if (room?.id) {
      navigate(`/booking/${room.id}`, { state: { room } });
    } else {
      console.error("Room ID is missing");
    }
  };

  return (
    <>
      <div className="container-fluid mt-8 pb-6">
        <div className="d-flex align-items-center px-3 border-primary/50">
          <button
            type="button"
            className="btn btn-sm btn-outline-dark rounded-circle"
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </button>
          <h3 className="py-2 pl-2 text-xl font-bold">Room Details</h3>
        </div>
        <div className="mt-2">
          <div
            className="p-4 border rounded shadow mb--3"
            style={{ backgroundColor: "#ffffff", marginBottom: "20px" }}
          >
            {isLoading ? (
              <div
                className="text-center my-7"
                style={{ width: "100%", height: "100%" }}
              >
               <Loader /> 
              </div>
            ) : data ? (
              <div className="row">
                <div className="col-md-5">
                  <Carousel
                    showArrows
                    autoPlay={false}
                    infiniteLoop
                    interval={3000}
                    transitionTime={1000}
                    className="w-100"
                    showThumbs={isMobile ? false : true}
                  >
                    {data?.roomphoto?.map((photo, index) => (
                      <div key={photo.id}>
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}${photo?.photo}`}
                          alt={data?.name}
                          className="img-fluid"
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
                <div className="col-md-7">
                  <div>
                    <h3
                      style={{
                        color: "#333",
                        fontWeight: "bold",
                        marginBottom: "5px",
                      }}
                    >
                      {data?.name}
                    </h3>
                  </div>
                  <div style={{ marginBottom: "10px", color: "#555" }}>
                    <strong style={{ color: "#333" }}>Price: </strong>
                    {data?.price}
                  </div>
                  <div style={{ marginBottom: "10px", color: "#555" }}>
                    {isMobile ? (
                      <>
                        {isReadMore
                          ? data?.description
                          : `${data?.description?.substring(0, 25)}...`}
                        <span
                          onClick={toggleReadMore}
                          style={{
                            color: "blue",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        >
                          {isReadMore ? "Show Less" : "Read More"}
                        </span>
                      </>
                    ) : (
                      data?.description
                    )}
                  </div>
                  <div className="mb-4">
                    <small
                      className="border-end me-3 pe-3"
                      style={{ color: "#777" }}
                    >
                      <i className="fa-regular fa-clock"></i>{" "}
                      {data?.room_slot_type}
                    </small>
                    <small
                      className="border-end me-3 pe-3"
                      style={{ color: "#777" }}
                    >
                      <i className="fa-solid fa-bed"></i> {data?.room_type}
                    </small>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                  {user ? (
                      <div>
                        <button
                          className="text-white bg-black px-3 py-1 rounded duration-200"
                          onClick={() => handleBookNow(data)}
                        >
                          Book Now
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="text-white bg-black px-3 py-1 rounded duration-200"
                          onClick={() =>
                            navigate(
                              `/login?next_url=${`/room-details/${data?.id}`}`
                            )
                          }
                        >
                          Book Now
                        </button>
                      </div>
                    )}
                    <CalendarPopUp
                        roomId={data ? data.id : null}
                        roomSlotType={
                          data ? data.room_slot_type : null
                        }
                      />
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="card d-flex justify-content-center align-items-center"
                style={{ height: "80vh", width: "100%" }}
              >
                <div className="text-center">
                  <i
                    className="fa fa-bed fa-3x mb-2"
                    style={{ fontSize: "2rem", opacity: 0.5 }}
                  ></i>
                  <h3 style={{ opacity: 0.5 }}>Room details not available</h3>
                </div>
              </div>
            )}
          </div>
        </div>
        <AllRooms />
      </div>

        <PaymentStatusModal
          isOpen={isModalOpen}
          toggleModal={toggleModal}
          paymentData={paymentData}
        />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(UserRoomDetails);