import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/img/brand/logo.png";
import { useDispatch } from "react-redux";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";
import "./Login.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import useWindowSize from "../../constant/WindowSize";
import PasswordReset from "./ForgotPassword/ResetMail";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);

  const size = useWindowSize();
  const isMobile = size.width <= 768;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const next_url = queryParams.get("next_url");
    if (next_url) {
      setNextUrl(next_url);
      navigate(location.pathname, { replace: true });
    }
  }, [location.search, navigate]);

  const onLogin = async (e) => {
    e.preventDefault();
      let formErrors = {};
      if (email === "") {
      formErrors.email = "Email is required";
    }
    if (password === "") {
      formErrors.password = "Password is required";
    }
      setErrors(formErrors);
      if (Object.keys(formErrors).length > 0) {
      return;
    }
      setIsLoading(true);
    dispatch(loadingToggleAction(true));
  
    try {
      await dispatch(loginAction(email, password, navigate, nextUrl));
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
      setIsLoading(false);
      dispatch(loadingToggleAction(false));
    }
  };
  
  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <>
      {isMobile ? (
        <div className="mobile-login-signup">
          <div className="background"></div>
          <div className="content" style={{ width: "95%" }}>
            <div>
              {" "}
              <Link to="/" className="text-white">
                <i className="fa-solid fa-house ms-1"></i>
              </Link>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={Logo}
                alt="Logo"
                className="logo"
                style={{
                  width: "70px",
                  height: "70px",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div className="club-title-container d-flex align-items-center justify-content-center">
              <h1
                className="club-title"
                style={{
                  fontFamily: "'Raleway', sans-serif",
                  fontSize: "2em",
                  color: "white",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                  margin: "0",
                }}
              >
                Rourkela Club
              </h1>
            </div>
            <small
              className="mb-3 d-flex justify-content-center"
              style={{ fontSize: "0.8em", color: "white" }}
            >
              Sign In with credentials
            </small>

            <div className="formTitle">
              <Link to="/login" className="formTitleLink-active">
                Sign In
              </Link>
              <span className="ms-3">or</span>
              <Link to="/signup" className="formTitleLink">
                Sign Up
              </Link>
            </div>

            <div className="formCenter">
              <form className="formFields mt-5" onSubmit={onLogin}>
                <div className="formField mb-5">
                  {/* <label className="formFieldLabel" htmlFor="email">
                    Membership ID/Email
                  </label> */}
                  <input
                    type="text"
                    className="formFieldInput"
                    placeholder="Membership ID/Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <span className="form-error">{errors.email}</span>
                  )}
                </div>

                <div className="formField mb-5">
                  <div className="password-input-container">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      className="formFieldInput"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="password-toggle-icon"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  {errors.password && (
                    <span className="form-error">{errors.password}</span>
                  )}
                </div>
                <PasswordReset />
                <button
                  className="formFieldButton w-100 my-3"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Sign In"
                  )}
                </button>
                <Link
                  to="/signup"
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    color: "#ffffffcf"
                  }}
                >
                  <span style={{ borderBottom: "1.5px solid #6b5b95" }}>
                    Create an account
                  </span>
                </Link>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="login-signup row">
          <div className="col-7 bg-image"></div>

          <div className="col-5  appForm">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={Logo}
                alt="Logo"
                className="logo"
                style={{
                  width: "70px",
                  height: "70px",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div className="club-title-container d-flex align-items-center justify-content-center">
              <h1
                className="club-title"
                style={{
                  fontFamily: "'Raleway', sans-serif",
                  fontSize: "2em",
                  color: "white",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                  margin: "0",
                }}
              >
                Rourkela Club
              </h1>
            </div>
            <small
              className="mb-3"
              style={{ fontSize: "0.8em", color: "white" }}
            >
              Sign In with credentials
            </small>
            <div className="formTitle">
              <Link to="/login" className="formTitleLink-active">
                Sign In
              </Link>
              <span className="ms-3">or</span>
              <Link to="/signup" className="formTitleLink">
                Sign Up
              </Link>
            </div>

            <div className="formCenter">
              <form className="formFields mt-4" onSubmit={onLogin}>
                <div className="formField">
                  <label className="formFieldLabel" htmlFor="email">
                    Membership ID / Email
                  </label>
                  <input
                    type="text"
                    className="formFieldInput"
                    placeholder="Membership ID / Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <span className="form-error">{errors.email}</span>
                  )}
                </div>

                <div className="formField">
                  <label className="formFieldLabel" htmlFor="password">
                    Password
                  </label>
                  <div className="password-input-container">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      className="formFieldInput"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="password-toggle-icon"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  {errors.password && (
                    <span className="form-error">{errors.password}</span>
                  )}
                </div>
                <div className="my-3">
                  <PasswordReset />
                </div>

                <div className="formField">
                  <button className="formFieldButton my-4" disabled={isLoading}>
                    {isLoading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Sign In"
                    )}
                  </button>
                  <Link
                    to="/signup"
                    className="formFieldLink mx-2"
                    style={{
                      color: "#ffffffcf",
                    }}
                  >
                    Create an account
                  </Link>
                </div>
                <Link to="/" className="d-flex justify-content-center">
                  Back to Home <i className="fa-solid fa-house ms-2 my-1"></i>
                </Link>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
