import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { getUserNameIcon } from "../../utils";
import LogoutModal from "../Logout/Logout";
import useWindowSize from "../../constant/WindowSize";
import { toggleSidebar, logoutAction } from "../../store/actions/AuthActions";
import Notification from "../Public&User/Component/Notification/Notification";
import { getNotification } from "../../services/NotificationService";

const AdminNavbar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const toggleExitModal = () => setExitModalOpen(!exitModalOpen);
  const size = useWindowSize();
  const isMobile = size.width <= 768;
  const isProfilePage = location.pathname === "/profile";
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getNotification()
      .then((data) => {
        setNotifications(data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch notifications", error);
      });
  }, []);

  function onLogout() {
    dispatch(logoutAction(navigate));
    toggleExitModal();
  }

  const handleProfileClick = () => {
    navigate("/profile");
  };

  const handleSidebarToggle = () => {
    dispatch(toggleSidebar());
  };

  return (
    <>
      {!isMobile && (
        <Navbar className="navbar-top navbar-dark custom-navbar" id="navbar-main">
          <Container className="admin-navbar" fluid>
            <div onClick={handleSidebarToggle}>
              <span>
                {props.sidebarToggle ? (
                  <i className={`fa-solid fa-times text-2xl ${isProfilePage ? "text-white" : "text-dark"} cursor-pointer`}></i>
                ) : (
                  <i className={`fa-solid fa-bars text-2xl ${isProfilePage ? "text-white" : "text-dark"} cursor-pointer`}></i>
                )}
              </span>
            </div>
            <div className="d-flex align-items-center">
   
              <Notification notifications={notifications} updateNotifications={setNotifications} />
              <div className="me-5">
                <i className={`fa-solid fa-gear text-lg rotate-icon ${isProfilePage ? "text-white" : "text-dark"} cursor-pointer`} onClick={() => navigate("/settings")}></i>
              </div>
              <Nav className="align-items-center ms-auto" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="pr-0" nav>
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        {props.user?.profile_photo ? (
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}${props.user.profile_photo}`}
                            alt="profile"
                            style={{ height: "100%", width: "100%" }}
                          />
                        ) : ( 
                          
                          <div className="avatar avatar-sm rounded-circle">
                            {getUserNameIcon(
                              props.user?.first_name +
                              " " +
                              props.user?.last_name
                            )}
                          </div>
                        )}
                      </span>
                      
                      <Media className="ml-2">
                        <span className={`mb-0 text-sm ${isProfilePage ? "text-white" : "text-dark"} font-weight-bold`}>
                          {props.user?.first_name} {props.user?.last_name}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Welcome!</h6>
                    </DropdownItem>
                    <DropdownItem onClick={handleProfileClick}>
                      <i className="ni ni-single-02" />
                      <span>My profile</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => setExitModalOpen(true)}>
                      <i className="fa-solid fa-power-off me-3"></i>
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </div>
          </Container>
        </Navbar>
      )}
      <LogoutModal show={exitModalOpen} onHide={() => setExitModalOpen(false)} onLogout={onLogout} />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  sidebarToggle: state.auth.sidebarToggle,
});

export default connect(mapStateToProps)(AdminNavbar);
