import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col
} from "reactstrap";
import { getRoomTypeGraph } from "../../services/DashBoardService";

// Updated alternative HD colors
const alternativeHdColors = [
  "#0077B6", // Ocean Blue
  "#32CD32", // Lime Green
  "#FF69B4", // Hot Pink
  "#FFC107", // Amber
  "#DC143C", // Crimson Red
];

var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

const BookingRoomGraph = () => {
  const [dataset, setDataSet] = useState(null);
  const [labels, setLabels] = useState([]);
  const [bgColors, setBgColors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState("weekly");

  const toggleTimePeriod = (tp) => {
    setTimePeriod(tp);
  };

  // Add timePeriod as a dependency in useEffect to refetch data when it changes
  useEffect(() => {
    setIsLoading(true);
    getRoomTypeGraph(timePeriod)
      .then((resp) => {
        const { results } = resp.data;
        const result = {
          ...results,
          datasets: [
            {
              ...results.datasets[0],
              backgroundColor: alternativeHdColors,
              borderColor: "#ffffff",
              borderWidth: 2, 
            },
          ],
        };

        setLabels(results.labels);
        setBgColors(alternativeHdColors);
        setDataSet(result);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error.response ? error.response.data.message : error);
        setIsLoading(false);
      });
  }, [timePeriod]); // Add timePeriod as a dependency here

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, 
      },
      tooltip: {
        backgroundColor: "rgba(0,0,0,0.8)",
        titleFont: { size: 14, weight: "bold" },
        bodyFont: { size: 12 },
        bodyColor: "#fff",
        padding: 10,
      },
    },
  };

  return (
    <Card
      className="shadow"
      style={{ borderRadius: "12px", border: "none" }}
    >
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <strong><h2 className="mb-0">Room Type</h2></strong>
          </div>

          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", {
                    active: timePeriod === "yearly",
                  })}
                  style={{
                    backgroundColor: timePeriod === "yearly" ? colors.theme.primary : "transparent",
                    color: timePeriod === "yearly" ? "#fff" : colors.theme.default,
                  }}
                  onClick={() => toggleTimePeriod("yearly")}
                >
                  <span className="text-dark">Y</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", {
                    active: timePeriod === "monthly",
                  })}
                  style={{
                    backgroundColor: timePeriod === "monthly" ? colors.theme.primary : "transparent",
                    color: timePeriod === "monthly" ? "#fff" : colors.theme.default,
                  }}
                  onClick={() => toggleTimePeriod("monthly")}
                >
                  <span className="text-dark">M</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", {
                    active: timePeriod === "weekly",
                  })}
                  style={{
                    backgroundColor: timePeriod === "weekly" ? colors.theme.primary : "transparent",
                    color: timePeriod === "weekly" ? "#fff" : colors.theme.default,
                  }}
                  onClick={() => toggleTimePeriod("weekly")}
                >
                  <span className="text-dark">W</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        {isLoading ? (
          <div className="text-center my-5">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : dataset ? (
          <>
            <div className="chart" style={{ height: "280px", position: "relative" }}>
              <Pie data={dataset} options={options} />
            </div>
            <div className="custom-legend">
              <Row>
                {labels.map((label, index) => (
                  <Col xs="6" sm="3" key={index} className="mb-3">
                    <div className="legend-item d-flex align-items-center">
                      <div
                        className="me-3"
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: bgColors[index],
                          borderRadius: "4px",
                          border: "2px solid #fff",
                        }}
                      />
                      <span
                        style={{
                          fontSize: "11px",
                          color: "#333",
                        }}
                      >
                        {label}
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </>
        ) : (
          <div className="text-center py-8">
          <i
            className="fas fa-search-minus"
            style={{ fontSize: "1.5rem", opacity: 0.8, color: "#17a2b8" }}
          ></i>
          <h3 style={{ opacity: 0.5 }}>No Booking Occurs</h3>
        </div>
        )}
      </CardBody>
      <style jsx>{`
        .custom-legend {
          margin-top: 20px;
        }
        .legend-item {
          display: flex;
          align-items: center;
        }
        .legend-item div {
          transition: transform 0.2s ease;
        }
        .legend-item div:hover {
          transform: scale(1.1);
        }
      `}</style>
    </Card>
  );
};

export default BookingRoomGraph;
