import React from "react";
import Navbar from "../Component/Navbar/Navbar";
import { connect } from "react-redux";
import { Outlet, Link } from "react-router-dom";
import Footer from "../Component/Footer/Footer";
import BottomNavbar from "../Component/BottomNavbar/BottomNavbar";

const Layout = ({ userRole }) => {

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
      {userRole === "User" && <BottomNavbar />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "Admin",
  };
};

export default connect(mapStateToProps)(Layout);
