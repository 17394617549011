import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import "./Login.css";

const TermsOfServiceModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered className="terms-modal">
      <Modal.Header closeButton className="terms-modal-header">
        <Modal.Title className="terms-modal-title">Terms of Service</Modal.Title>
      </Modal.Header>
      <Modal.Body className="terms-modal-body">
        <h5 className="terms-heading">Introduction</h5>
        <p className="terms-text">
          These Terms of Service govern your use of our website and services. By accessing or using our site, you agree to comply with these terms.
        </p>
        <h5 className="terms-heading">Eligibility</h5>
        <p className="terms-text">
          You must be at least 18 years old to use our services. By using our site, you warrant that you are of legal age and have the authority to enter into this agreement.
        </p>
        <h5 className="terms-heading">User Responsibilities</h5>
        <p className="terms-text">
          You agree to use our services responsibly and not engage in any illegal activities, harassment, or spamming. You are responsible for the content you post and must ensure it does not violate any laws or third-party rights.
        </p>
        <h5 className="terms-heading">Intellectual Property</h5>
        <p className="terms-text">
          All content on our site, including text, images, and logos, is owned by us and protected by intellectual property laws. You may not use our content without permission.
        </p>
        <h5 className="terms-heading">Privacy</h5>
        <p className="terms-text">
          Our Privacy Policy governs how we collect and use your personal information. We are committed to protecting your data, but you are also responsible for keeping your account secure.
        </p>
        <h5 className="terms-heading">Limitation of Liability</h5>
        <p className="terms-text">
          We are not liable for any damages arising from your use of our services. Our site is provided "as is" without any warranties.
        </p>
        <h5 className="terms-heading">Termination</h5>
        <p className="terms-text">
          We reserve the right to suspend or terminate your account at our discretion. Upon termination, your access to our services will be revoked.
        </p>
        <h5 className="terms-heading">Governing Law</h5>
        <p className="terms-text">
          These terms are governed by the laws of [Your Jurisdiction]. Any disputes will be resolved in accordance with these laws.
        </p>
        <h5 className="terms-heading">Contact Information</h5>
        <p className="terms-text">
          If you have any questions about these terms, please contact us at [Your Contact Information].
        </p>
      </Modal.Body>
      <Modal.Footer className="terms-modal-footer">
      </Modal.Footer>
    </Modal>
  );
};

export default TermsOfServiceModal;
