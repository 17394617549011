import Dashboard from "./views/Dashboard/index.js"
import Rooms from "./views/Rooms/Rooms.js";
import MemberListPage from "./views/Member";
import BookingList from "./views/examples/BookingList.js";
import BookingStatus from "./views/BookingStatus/BookingStatus.js";
import Events from "./views/Events/Events.js";
import Payments from "./views/PaymentPage/Payments.js";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary text-lg",
    component: Dashboard,
  },
  {
    path: "/rooms",
    name: "Rooms",
    icon: "fa-solid fa-hotel text-royalblue text-lg",
    component: Rooms,
  },
  {
    path: "/member",
    name: "Member",
    icon: "fas fa-user-tie text-rose text-lg",
    component: MemberListPage,
  },
  {
    path: "/booking-list",
    name: "Booking History",
    icon: "fas fa-history text-yellow text-lg",
    component: BookingList,
  },
  {
    path: "/booking-status",
    name: "Booking Status",
    icon: "fas fa-hourglass-start  text-red text-lg",
    component: BookingStatus ,
  },
  {
    path: "/events",
    name: "Events",
    icon: "fas fa-cocktail text-info text-lg",
    component: Events ,
  },
  {
    path: "/payments",
    name: "Payments",
    icon: "fa-solid fa-sack-dollar text-gold text-lg",
    component: Payments ,
  },


];

export default routes;



