import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useWindowSize from "../../constant/WindowSize";
import Header from "../../components/Headers/Header";
import { Container } from "reactstrap";
import {
  getRoom,
  updateRoom,
  getRoomHistory,
} from "../../services/RoomService";
import Details from "./RoomDetails"
import "./Room.css";
import Loader from "../../components/Loader/Loader";

function RoomDetails({ user }) {
  const [data, setData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [roomInput, setRoomInput] = useState({
    id: "",
    name: "",
    room_type: "",
    room_slot_type: "",
    price: "",
    description: "",
    room_photos: [],
    delete_photo_ids: [],
  });
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("No file chosen...");
  const [isActive, setIsActive] = useState(false);
 
  const { id } = useParams();

  const size = useWindowSize();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const resp = await getRoom(id);
        if (resp) {
          const resp_data = resp.data.result;
          setData(resp_data);
          fetchHistory();
        }
      } catch (error) {
        console.error("Error fetching room:", error);
        setData(null);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchHistory = async () => {
      setIsLoading(true);
      try {
        const resp = await getRoomHistory(id);
        setHistoryData(resp.data.results);
      } catch (error) {
        console.error("Error fetching room history:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handleEditClick = () => {
    setRoomInput({
      id: data.id,
      name: data.name,
      room_type: data.room_type,
      room_slot_type: data.room_slot_type,
      price: data.price,
      description: data.description,
      room_photos: data.roomphoto.map((photo) => ({
        id: photo.id,
        url: `${process.env.REACT_APP_BASE_URL}${photo.photo}`,
      })),
      delete_photo_ids: [],
    });
    setIsEditing(true);
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setRoomInput({ ...roomInput, [name]: value });
  };

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles([...files, ...selectedFiles]);
    setFileName(selectedFiles.map((file) => file.name).join(", "));
  };

  const handleDeletePhoto = (photoId, isExisting) => {
    if (isExisting) {
      setRoomInput((prevState) => ({
        ...prevState,
        room_photos: prevState.room_photos.filter(
          (photo) => photo.id !== roomInput.room_photos[photoId].id
        ),
        delete_photo_ids: [
          ...prevState.delete_photo_ids,
          roomInput.room_photos[photoId].id,
        ],
      }));
    } else {
      const updatedFiles = files.filter((file, index) => index !== photoId);
      setFiles(updatedFiles);
      setFileName(
        updatedFiles.map((file) => file.name).join(", ") || "No file chosen..."
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const reqBody = new FormData();
      Object.entries(roomInput).forEach(([fieldName, value]) => {
        if (fieldName === "room_photos") {
          value.forEach((photo) => reqBody.append("room_photos", photo.url));
        } else if (fieldName === "delete_photo_ids") {
          value.forEach((id) => reqBody.append("delete_photo_ids", id));
        } else {
          reqBody.append(fieldName, value);
        }
      });
      files.forEach((file) => {
        reqBody.append("photos", file);
      });

      const resp = await updateRoom(reqBody);
      const { results } = resp.data;
      setData(results);
      toast.success(resp.data.message);
      setIsEditing(false);
      setFiles([]);
      setFileName("No file chosen...");
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
  };
  const handleCancel = () => {
    setIsEditing(false);
    setFiles([]);
    setFileName("No file chosen...");
  };

  return (
    <>
      <Header />
      <Container className="Container mt-sm-0 mt-7 mb-sm-0 mb-7 p-sm-2 p-1" fluid>
        <div className="card p-sm-3 p-2">
          {isLoading ? (
                        <div
                        className="text-center my-7"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <Loader />
                      </div>
          ) : data !== null ? (
            isEditing ? (
              <div className="row">
                <div className="col-md-6">
                  <form className="row g-3 p-sm-3 px-0" onSubmit={handleSubmit}>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="validationDefault01"
                        className="form-label"
                      >
                        Room Name:
                      </label>
                      <input
                        id="name"
                        type="text"
                        name="name"
                        className="form-control my--1"
                        placeholder="Name"
                        value={roomInput.name}
                        onChange={handleChangeAdd}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="price" className="form-label">
                        Room Price:
                      </label>
                      <input
                        id="price"
                        type="number"
                        name="price"
                        className="form-control my--1"
                        placeholder="Room Price"
                        value={roomInput.price}
                        onChange={handleChangeAdd}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Room Type:</label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="room_type"
                          id="room"
                          value="Room"
                          checked={roomInput.room_type === "Room"}
                          onChange={handleChangeAdd}
                        />
                        <label className="form-check-label" htmlFor="room">
                          Room
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="room_type"
                          id="lounge"
                          value="Lounge"
                          checked={roomInput.room_type === "Lounge"}
                          onChange={handleChangeAdd}
                        />
                        <label className="form-check-label" htmlFor="lounge">
                        Lounge
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="room_type"
                          id="hall"
                          value="Hall"
                          checked={roomInput.room_type === "Hall"}
                          onChange={handleChangeAdd}
                        />
                        <label className="form-check-label" htmlFor="hall">
                          Hall
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="room_type"
                          id="mandap"
                          value="Mandap"
                          checked={roomInput.room_type === "Mandap"}
                          onChange={handleChangeAdd}
                        />
                        <label className="form-check-label" htmlFor="mandap">
                          Mandap
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Room Slot Time:</label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="room_slot_type"
                          id="wholeday"
                          value="Full Day"
                          checked={roomInput.room_slot_type === "Full Day"}
                          onChange={handleChangeAdd}
                        />
                        <label className="form-check-label" htmlFor="wholeday">
                          Full Day
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="room_slot_type"
                          id="slot"
                          value="Time Slot"
                          checked={roomInput.room_slot_type === "Time Slot"}
                          onChange={handleChangeAdd}
                        />
                        <label className="form-check-label" htmlFor="slot">
                          Specific Time
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12 mb-4">
                      <label
                        htmlFor="validationDefaultDescription"
                        className="form-label"
                      >
                        Description:
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        className="form-control"
                        placeholder="Description"
                        rows="4"
                        value={roomInput.description}
                        onChange={handleChangeAdd}
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="roomPhotos" className="form-label">
                        Upload Room Photos:
                      </label>
                      <div
                        className={`file-upload ${isActive ? "active" : ""}`}
                      >
                        <div className="file-select">
                          <div className="file-select-button" id="fileName">
                            Choose file
                          </div>
                          <div className="file-select-name" id="noFile">
                            {fileName}
                          </div>
                          <input
                            type="file"
                            id="roomPhotos"
                            multiple
                            accept="image/*"
                            onChange={handleFileInputChange}
                            onFocus={() => setIsActive(true)}
                            onBlur={() => setIsActive(false)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex  my-3">
                      <button
                        type="submit"
                        className="btn bg-black text-white mt-4 mx-1 w-50"
                        disabled={isSubmitLoading}
                      >
                        {isSubmitLoading ? (
                          <span>
                            <Spinner animation="border" size="sm" /> updating...
                          </span>
                        ) : (
                          "Update"
                        )}
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger mt-4 mx-1 w-50"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    {roomInput.room_photos.map((photo, index) => (
                      <div key={photo.id} className="col-md-6 mb-3">
                        <div className="position-relative">
                          <img
                            src={photo.url}
                            alt={`Room ${index}`}
                            className="img-fluid"
                            style={{
                              height: "200px",
                              objectFit: "cover",
                              borderRadius: "8px",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.transform = "scale(1.05)";
                              e.target.style.boxShadow =
                                "0 8px 16px rgba(0, 0, 0, 0.2)";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.transform = "scale(1)";
                              e.target.style.boxShadow =
                                "0 4px 8px rgba(0, 0, 0, 0.1)";
                            }}
                          />
                          <button
                            type="button"
                            className="btn bg-white text-black btn-sm mt-1 rounded-full position-absolute"
                            style={{ top: "6px", right: "10px" }}
                            onClick={() => handleDeletePhoto(index, true)}
                          >
                            <FaTrash style={{ fontSize: "13px" }} />
                          </button>
                        </div>
                      </div>
                    ))}
                    {files.map((file, index) => (
                      <div key={`file-${index}`} className="col-md-6 mb-3">
                        <div className="position-relative">
                          <img
                            src={URL.createObjectURL(file)}
                            alt={`Uploaded ${index}`}
                            className="img-fluid"
                            style={{
                              height: "200px",
                              objectFit: "cover",
                              borderRadius: "8px",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.transform = "scale(1.05)";
                              e.target.style.boxShadow =
                                "0 8px 16px rgba(0, 0, 0, 0.2)";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.transform = "scale(1)";
                              e.target.style.boxShadow =
                                "0 4px 8px rgba(0, 0, 0, 0.1)";
                            }}
                          />
                          <button
                            type="button"
                            className="btn bg-white text-black btn-sm mt-1 rounded-full position-absolute"
                            style={{ top: "6px", right: "10px" }}
                            onClick={() => handleDeletePhoto(index, false)}
                          >
                            <FaTrash style={{ fontSize: "13px" }} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <Details handleEditClick={handleEditClick}  data={data} historyData={historyData} isLoading={isLoading} />
            )
          ) : (
            <div className="card">
              <div className="card-body">
                <h4 className="text-center text-danger">
                  ⚠️ Room Not Found!
                  <br /> ID {id}
                </h4>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(RoomDetails);
