import React from "react";
import { Modal } from "react-bootstrap";

function LogoutModal({ show, onHide, onLogout }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title><span className="text-white">Logout Confirm</span></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center mb-3">Are you sure you want to logout?</p>
        <div className="d-flex align-items-center justify-content-center">
          <button
            type="submit"
            className="btn bg-black text-white btn-sm me-1"
            onClick={(e) => {
              e.stopPropagation();
              onLogout();
            }}
          >
            Yes
          </button>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onHide();
            }}
            className="btn btn-danger btn-sm mx-2"
          >
            No
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LogoutModal;
