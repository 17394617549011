import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import DynamicSelectInput from "../../constant/SelectInput";
import { LiaCircleSolid } from "react-icons/lia";
import { format } from "date-fns";
import "./Settings.css";
import { getCouponList, createCoupon } from "../../services/SettingService";

function Coupon() {
  const couponColors = [
    "gold",
    "silver",
    "#d7c6cf",
    "orange",
    "	#cee9ea",
    "pink",
    "#e6c79c",
    "#6fd08c",
    "#b7d6d5",
  ];

  const [copied, setCopied] = useState({});
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [couponInput, setCouponInput] = useState({
    coupon_code: "",
    discount_type: "",
    discount_value: "",
    valid_from: "",
    valid_to: "",
    coupon_type: "",
    specific_member: "",
    specific_membershiptype: "",
    user_limit: "",
    minimum_spend: "",
  });
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const navigate = useNavigate();

  const handleCloseOffCanvas = () => {
    setIsOffCanvasOpen(false);
  };

  const handleOpenOffCanvas = () => {
    setIsOffCanvasOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    getCouponList()
      .then((resp) => {
        setData(resp.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error.response ? error.response.data.message : error);
        setIsLoading(false);
      });
  }, []);

  const copyToClipboard = (code, index) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopied((prev) => ({ ...prev, [index]: true }));
        setTimeout(() => {
          setCopied((prev) => ({ ...prev, [index]: false }));
        }, 2000);
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setCouponInput({
      ...couponInput,
      valid_from: start ? format(start, "yyyy-MM-dd") : "",
      valid_to: end ? format(end, "yyyy-MM-dd") : "",
    });
  };

  const handleSelectChange = (name, value) => {
    setCouponInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setCouponInput((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const validateInputs = () => {
    const validationErrors = {};
    if (!couponInput.coupon_code)
      validationErrors.coupon_code = "Coupon code is required";
    if (!couponInput.discount_type)
      validationErrors.discount_type = "Discount type is required";
    if (!couponInput.discount_value)
      validationErrors.discount_value = "Discount value is required";
    if (!couponInput.valid_from || !couponInput.valid_to)
      validationErrors.valid_from = "Valid date range is required";
    if (!couponInput.coupon_type)
      validationErrors.coupon_type = "Coupon type is required";
    if (!couponInput.minimum_spend)
      validationErrors.minimum_spend = "Minimum spend is required";
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setIsSubmitLoading(true);
      const resp = await createCoupon(couponInput);
      toast.success(resp.data.message);
      setData([resp.data.results, ...data]);
      setIsOffCanvasOpen(false);
      setCouponInput({
        coupon_code: "",
        discount_type: "",
        discount_value: "",
        valid_from: "",
        valid_to: "",
        coupon_type: "",
        specific_member: "",
        specific_membershiptype: "",
        user_limit: "",
        minimum_spend: "",
      });
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between p-2 mb-1">
        <button
          type="button"
          className="btn btn-sm border-black text-black rounded-circle"
          onClick={() => navigate(-1)}
        >
          <i className="fa-solid fa-arrow-left"></i>
        </button>
        <button
          className="btn ms-3 bg-black text-white"
          onClick={handleOpenOffCanvas}
        >
          Add Coupon
        </button>
      </div>

      <div className="card p-sm-3 p-2">
        <div className="row g-4 mb-4">
          {data ? (data.map((item, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 mb-4 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div
                className="coupon"
                style={{
                  "--coupon-color": couponColors[index % couponColors.length],
                  position: "relative",
                }}
              >
                {item.status === "Available" && (
                  <span className="top-right-button">
                    <LiaCircleSolid />
                  </span>
                )}
                <div className="coupon-center">
                  <div>
                    <h2>
                      {item.discount_type === "Flat" && (
                        <span className="me-2 text-sm">
                          {item.discount_type}
                        </span>
                      )}
                      {item.discount_value}
                      {item.discount_type === "Percentage" && (
                        <span>%</span>
                      )}{" "}
                      OFF
                    </h2>
                    <small
                      className="mb-3"
                      style={{
                        fontSize: "0.65rem",
                        color: "black",
                      }}
                    >
                      On Minimum Spend of {item.minimum_spend}
                    </small>
                    <div className="coupon-box">
                      <span className="coupon-code px-3">{item.code}</span>
                      <button
                        className="copy-button"
                        onClick={() => copyToClipboard(item.code, index)}
                      >
                        {copied[index] ? "Copied!" : "Copy"}
                      </button>
                    </div>
                    <small
                      style={{
                        fontSize: "0.55rem",
                        color: "black",
                      }}
                    >
                      Valid till {item.valid_from} to {item.valid_to}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          ))) : (
            <div
                className="card d-flex justify-content-center align-items-center"
                style={{ height: "80vh", width: "100%" }}
              >
                <div className="text-center">
                  <i
                    className="fa fa-bed fa-3x mb-2"
                    style={{ fontSize: "2rem", opacity: 0.5 }}
                  ></i>
                  <h3 style={{ opacity: 0.5 }}>Coupons not available</h3>
                </div>
              </div>
          )}
        </div>
      </div>

      {isOffCanvasOpen && (
        <div
          className={`offcanvas-container ${
            isOffCanvasOpen ? "open" : ""
          } mt-sm-0 mt-5`}
        >
          <div className="offcanvas-content">
            <div className="d-flex align-items-center justify-content-between">
              <h1>
                <strong>Add Coupon</strong>
              </h1>
              <button className="btn text-xl" onClick={handleCloseOffCanvas}>
                &times;
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-1">
                <label htmlFor="coupon_code">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Coupon Code
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="coupon_code"
                  className="form-control"
                  placeholder="Enter Coupon Code"
                  value={couponInput.coupon_code}
                  onChange={handleInputChange}
                />
                {errors.coupon_code && (
                  <div className="text-danger text-xs">
                    {errors.coupon_code}
                  </div>
                )}
              </div>
              <div className="mb-1">
                <label htmlFor="discount_type">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Discount Type
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <div className="custom-select-wrapper">
                <select
                  id="discount_type"
                  className="form-control custom-select"
                  value={couponInput.discount_type}
                  onChange={handleInputChange}
                >
                  <option value="">Select Discount Type</option>
                  <option value="Flat">Flat</option>
                  <option value="Percentage">Percentage</option>
                </select>
                </div>
                {errors.discount_type && (
                  <div className="text-danger text-xs">
                    {errors.discount_type}
                  </div>
                )}
              </div>
              <div className="mb-1">
                <label htmlFor="discount_value">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Discount Value
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="discount_value"
                  className="form-control"
                  placeholder="Enter Discount Value"
                  value={couponInput.discount_value}
                  onChange={handleInputChange}
                />
                {errors.discount_value && (
                  <div className="text-danger text-xs">
                    {errors.discount_value}
                  </div>
                )}
              </div>
              <div className="mb-1">
                <label htmlFor="valid_from">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Valid Date Range
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <Flatpickr
                  id="valid_from"
                  options={{ mode: "range", dateFormat: "Y-m-d" }}
                  onChange={handleDateChange}
                  placeholder="Select date range"
                  value={[couponInput.valid_from, couponInput.valid_to]}
                  className="form-control custom-flatpickr w-full p-2 mx-auto border border-gray-800 bg-transparent rounded-md shadow-sm focus:outline-none focus:ring-2"
                />
                {errors.valid_from && (
                  <div className="text-danger text-xs">{errors.valid_from}</div>
                )}
              </div>
              <div className="mb-1">
                <label htmlFor="coupon_type">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Coupon Type
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <div className="custom-select-wrapper">
                <select
                  id="coupon_type"
                  className="form-control custom-select"
                  value={couponInput.coupon_type}
                  onChange={handleInputChange}
                >
                  <option value="">Select Coupon Type</option>
                  <option value="General">General</option>
                  <option value="Specific MembershipType">
                    Specific MembershipType
                  </option>
                  <option value="Specific Member">Specific Member</option>
                </select>
                </div>
                {errors.coupon_type && (
                  <div className="text-danger text-xs">
                    {errors.coupon_type}
                  </div>
                )}
              </div>
              {couponInput.coupon_type === "Specific Member" && (
                <div className="mb-1">
                  <label htmlFor="specific_member">
                    <strong style={{ fontSize: "0.85em" }} className="me-1">
                      Choose Specific Member
                    </strong>
                    <span className="text-danger">*</span>
                  </label>
                  <DynamicSelectInput
                    parentClassName="mb-0 order"
                    setParentInputValue={handleSelectChange}
                    endpoint_name="onbehalf_member"
                    name={"specific_member"}
                    id={"specific_member"}
                    isClearable={true}
                    is_multi={true}
                    isRequired={true}
                    placeholder={"Select Member"}
                    style={{ padding: "0.13rem" }}
                  />
                </div>
              )}
              {couponInput.coupon_type === "Specific MembershipType" && (
                <div className="mb-1">
                  <label htmlFor="specific_membershiptype">
                    <strong style={{ fontSize: "0.85em" }} className="me-1">
                      Choose MembershipType
                    </strong>
                    <span className="text-danger">*</span>
                  </label>
                  <div className="custom-select-wrapper">
                    <select
                      id="specific_membershiptype"
                      className="form-control custom-select"
                      value={couponInput.specific_membershiptype}
                      onChange={handleInputChange}
                    >
                      <option value="">Choose Membership Type</option>
                      <option value="SAIL RSP Member">SAIL RSP Member</option>
                      <option value="Government Member">
                        Government Member
                      </option>
                      <option value="Private Member">Private Member</option>
                      <option value="Honorary Member">Honorary Member</option>
                    </select>
                  </div>
                  {errors.coupon_type && (
                    <div className="text-danger text-xs">
                      {errors.coupon_type}
                    </div>
                  )}
                </div>
              )}
              {couponInput.coupon_type !== "Specific Member" && (
                <div className="mb-1">
                <label htmlFor="user_limit">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    User Limit
                  </strong>
                </label>
                <input
                  type="number"
                  id="user_limit"
                  className="form-control"
                  value={couponInput.user_limit}
                  onChange={handleInputChange}
                />
              </div>
              )}
              
              <div className="mb-4">
                <label htmlFor="minimum_spend">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Minimum Spend
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  id="minimum_spend"
                  className="form-control"
                  value={couponInput.minimum_spend}
                  onChange={handleInputChange}
                />
                {errors.minimum_spend && (
                  <div className="text-danger text-xs">
                    {errors.minimum_spend}
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-center mb-sm-0 mb-6">
                <button
                  type="submit"
                  className="btn bg-black text-white"
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading ? "Submitting..." : "Submit"}
                </button>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={handleCloseOffCanvas}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Coupon;
