import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentBookings } from "../../services/DashBoardService";

function HighlightData() {
  const [dataset, setDataSet] = useState({
    todays_bookings: {},
    tomorrows_bookings: {},
    pending_bookings: {},
    cancelled_bookings: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getCurrentBookings()
      .then((resp) => {
        setDataSet({
          todays_bookings: resp.data.todays_bookings,
          tomorrows_bookings: resp.data.tomorrows_bookings,
          pending_bookings: resp.data.pending_bookings,
          cancelled_bookings: resp.data.cancelled_bookings,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, []);

  const getCount = (type) => {
    if (type === "today") {
      return dataset.todays_bookings.count || 0;
    } else if (type === "tomorrow") {
      return dataset.tomorrows_bookings.count || 0;
    } else if (type === "pending") {
      return dataset.pending_bookings.count || 0;
    } else if (type === "cancelled") {
      return dataset.cancelled_bookings.count || 0;
    }
    return 0;
  };

  const handleNavigate = (type) => {
    navigate("/booking-status", {
      state: { fetchBookings: true, bookingType: type },
    });
  };

  return (
    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full max-w-9xl mb-3">
      <div
        className="relative px-sm-5 px-3 py-3 my-1 bg-gradient-to-r from-cyan-400 to-cyan-600 rounded-md overflow-hidden
    hover:cursor-pointer hover:scale-105 transition-transform duration-300"
        onClick={() => handleNavigate("today")}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="relative mb-4 text-white text-4xl leading-none font-semibold">
            {getCount("today")}
          </div>
          <i className="fa-solid fa-arrow-right text-white text-3xl"></i>
        </div>
        <div className="text-white leading-none font-semibold">
          Today Bookings
        </div>
      </div>
      <div
        className="relative px-sm-5 px-3 py-3 my-1 bg-gradient-to-r from-teal-400 to-teal-500 rounded-md overflow-hidden
    hover:cursor-pointer hover:scale-105 transition-transform duration-300"
        onClick={() => handleNavigate("tomorrow")}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="relative mb-4 text-white text-4xl leading-none font-semibold">
            {getCount("tomorrow")}
          </div>
          <i className="fa-solid fa-arrow-right text-white text-3xl"></i>
        </div>
        <div className="relative text-white leading-none font-semibold">
          Tomorrow Bookings
        </div>
      </div>
      <div
        className="relative px-sm-5 px-3 py-3 my-1 bg-gradient-to-r from-blue-400 to-blue-600 rounded-md overflow-hidden
    hover:cursor-pointer hover:scale-105 transition-transform duration-300"
        onClick={() => handleNavigate("pending")}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="relative mb-4 text-white text-4xl leading-none font-semibold">
            {getCount("pending")}
          </div>
          <i className="fa-solid fa-arrow-right text-white text-3xl"></i>
        </div>
        <div className="relative text-blue-200 leading-none font-semibold">
          Pending Bookings
        </div>
      </div>
      <div
        className="relative px-sm-5 px-3 py-3 my-1 bg-gradient-to-r from-red-400 to-red-600 rounded-md overflow-hidden
    hover:cursor-pointer hover:scale-105 transition-transform duration-300"
        onClick={() => handleNavigate("cancelled")}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="relative mb-4 text-white text-4xl leading-none font-semibold">
            {getCount("cancelled")}
          </div>
          <i className="fa-solid fa-arrow-right text-white text-3xl"></i>
        </div>
        <div className="relative text-red-200 leading-none font-semibold">
          Cancellations
        </div>
      </div>
    </div>
  );
}

export default HighlightData;
