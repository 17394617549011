import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./payment.css";

const PaymentStatusModal = ({ isOpen, toggleModal, paymentData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (paymentData) {
      setIsLoading(false);
      setStatus(paymentData.status);
    }
  }, [paymentData]);

  const handleClose = () => {
    setIsLoading(true);  
    setStatus(null); 
    toggleModal();  
  };

  return (
    <Modal show={isOpen} onHide={handleClose} centered>
      <Modal.Body>
        {isLoading ? (
          <div
            className="text-center my-7"
            style={{ width: "100%", height: "100%" }}
          >
            <div className="spinner-grow text-black me-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-black me-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-black me-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-black" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : status === "SUCCESS" ? (
          <div className="message-box _success">
            <i className="fa fa-check-circle"></i>
            <h2>Your payment was successful</h2>
            <p>
              Thank you for your payment. We will <br />
              be in contact with more details shortly
            </p>
          </div>
        ) : (
          <div className="message-box _failed">
            <i className="fa fa-times-circle"></i>
            <h2>Your payment failed</h2>
            <p>
              Unfortunately, your payment could not be processed. Please try
              again or contact support.
            </p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PaymentStatusModal;