import React, { useState, useEffect } from "react";
import { passwordReset } from "../../../services/ProfileService";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    generateCaptcha();
  }, []);

  const toggleForm = () => {
    setShowForm(!showForm);
    setError("");
    setEmail("");
    setUserInput("");
    generateCaptcha();
    setEmailError("");
    setCaptchaError("");
    setErrorMsg(null);
  };

  const generateCaptcha = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let captcha = '';
    for (let i = 0; i < 6; i++) {
      captcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptchaText(captcha);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
  };

  const handleCaptchaInputChange = (event) => {
    setUserInput(event.target.value);
    setCaptchaError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (!email) {
      setEmailError("Email is required.");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!userInput) {
      setCaptchaError("CAPTCHA is required.");
      valid = false;
    } else if (userInput !== captchaText) {
      setCaptchaError("CAPTCHA does not match. Please try again.");
      generateCaptcha();
      valid = false;
    } else {
      setCaptchaError("");
    }

    if (!valid) return;

    
    setLoading(true);
    try {
      const response = await passwordReset(email);
      toast.success(response.data.message);
      toggleForm();
    } catch (error) {
      if (error.response) {
        setErrorMsg(error.response.data.message);
        setError(error.response.data.message);
      } else {
        setError('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <span className=" cursor-pointer forgot-password-link" style={{fontSize:"0.8rem"}} onClick={toggleForm}>
        Forgot Password ?
      </span>
      <Modal show={showForm} onHide={toggleForm} centered={true}>
        <div className="modal-header d-flex align-items-center justify-content-between">
          <h4 className="text-white">Reset Password</h4>
          <button onClick={toggleForm} className="close-button">
            <i className="fa-solid fa-xmark fa-2x"></i>
          </button>
        </div>
        <div className="panel-body px-5">
          <div className="text-center">
            <h3 className="mb-3">
              <i className="fa fa-lock fa-4x"></i>
            </h3>
            <h2 className="text-center mb-3">Forgot Password?</h2>
            <p className="mb-3">Enter your email to get a reset password link.</p>
            <div className="panel-body">
              <form>
                <div className="input-group mb-3">
                  <span className="px-2 pt-2 email-icon" id="button-addon1">
                    <i className="fa-regular fa-envelope"></i>
                  </span>
                  <input
                    type="email"
                    value={email}
                    className="form-control email-input"
                    placeholder="Enter Email Id"
                    onChange={handleEmailChange}
                    required
                    aria-describedby="button-addon1"
                  />
                </div>
                {emailError && <p className="text-danger">{emailError}</p>}
                <div className="input-group mb-3 captcha-group">
                  <span className="px-2 pt-2 captcha-text" id="button-addon2">
                    <span className="captcha-background">{captchaText}</span>
                  </span>
                  <input
                    type="text"
                    value={userInput}
                    className="form-control captcha-input"
                    placeholder="Enter CAPTCHA"
                    onChange={handleCaptchaInputChange}
                    required
                    aria-describedby="button-addon2"
                  />
                  <button type="button" onClick={generateCaptcha} className="captcha-refresh"><i className="fa-solid fa-arrows-rotate"></i></button>
                </div>
                {captchaError && <p className="text-danger">{captchaError}</p>}
                {error && <p className="text-danger mb-1">{error}</p>}
                <button
                  className="btn btn-primary send-link-button mb-4"
                  type="button"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Send Link"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PasswordReset;
