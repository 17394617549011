import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../components/Headers/Header.js";

const Refund = () => {

  return (
    <>
      <Header />
      <Container className="Container mt-sm-0 mt-5 mb-sm-0 mb-7 p-sm-2 p-1" fluid>
      <div class="text-center mb-3">
      <div className="d-flex align-items-center justify-content-center text-lg">
            <img
                  className="me-3 header-logo h-10"
                  alt="..."
                  src={require("../../assets/img/brand/logo.png")}
                />
                <h1 className="m-0 text-uppercase">
                <strong>Rourkela CLUB</strong>
                </h1>
                </div>
            <h1 className="text-lg">Refund Policy</h1>
            
        </div>

<div class="section px-4 py-2">
    <h2 class="section-heading"><strong>1. General Terms</strong></h2>
    <h3>1.1 Booking and Cancellation</h3>
    <p>All bookings for rooms, lounges, halls, and mandaps at Rourkela Club are subject to availability and confirmation. Once a booking is made, the User agrees to the terms outlined below regarding cancellations and refunds.</p>

    <h3>1.2 Role of Rourkela Club</h3>
    <p>Rourkela Club acts as a facilitator in providing the facilities to the employees of Rourkela Steel Plant. The contract of service for utilizing the facilities is between the User and Rourkela Club.</p>
</div>

<div class="section px-4 py-2">
    <h2 class="section-heading"><strong>2. Refund Policy</strong></h2>
    <h3>2.1 Refund Eligibility</h3>
    <p>Refunds are applicable only if the cancellation request is made in accordance with the time frames specified below. Refunds will be processed as per the terms and conditions set forth by Rourkela Club and may be subject to deductions.</p>

    <h3>2.2 Cancellation by the User</h3>
    <ul>
        <li><strong>Rooms & Lounges:</strong> If the cancellation is made more than 24 hours before the scheduled booking time, a full refund will be provided, less a processing fee of 10% of the total booking amount.</li>
        <li><strong>Halls & Mandaps:</strong> If the cancellation is made more than 7 days before the scheduled booking time, a full refund will be provided, less a processing fee of 10% of the total booking amount. Cancellations made within 7 days of the booking will be subject to a cancellation fee of 50% of the total booking amount.</li>
    </ul>

    <h3>2.3 Cancellation by Rourkela Club</h3>
    <p>Rourkela Club reserves the right to cancel a booking due to unforeseen circumstances such as maintenance, emergencies, or other operational requirements. In such cases, Users will be provided with an alternative date or a full refund of the booking amount, as per the User’s preference.</p>

    <h3>2.4 Non-Refundable Fees</h3>
    <p>Any processing fees or charges incurred during the booking process are non-refundable. Special event arrangements or additional services requested at the time of booking that are non-refundable will be deducted from the total refund amount.</p>

    <h3>2.5 Partial Utilization</h3>
    <p>In the case of partial utilization of the booked facilities, refunds will not be applicable. Any additional services or amenities used will be charged separately.</p>
</div>

<div class="section px-4 py-2">
    <h2 class="section-heading"><strong>3. Refund Process</strong></h2>
    <h3>3.1 Request for Refund</h3>
    <p>To request a refund, Users must contact Rourkela Club’s booking department via email or phone, providing the booking reference number and reason for cancellation.</p>

    <h3>3.2 Processing Time</h3>
    <p>Refunds will be processed within 7-10 business days from the receipt of the cancellation request. The refund will be credited to the same payment method used for the booking.</p>

    <h3>3.3 Dispute Resolution</h3>
    <p>Any disputes regarding refunds will be resolved as per the internal grievance redressal mechanism of Rourkela Club.</p>
</div>

<div class="section px-4 py-2">
    <h2 class="section-heading"><strong>4. Travel Documents</strong></h2>
    <h3>4.1 Responsibility</h3>
    <p>Users are responsible for ensuring they have all necessary documents and permissions for utilizing the facilities. Rourkela Club will not be liable for any issues arising due to the lack of required documents.</p>
</div>

<div class="section px-4 py-2">
    <h2 class="section-heading"><strong>5. Amendments to the Policy</strong></h2>
    <h3>5.1 Changes to Policy</h3>
    <p>Rourkela Club reserves the right to amend the refund policy from time to time. Any changes will be updated on the website, and Users are advised to review the policy periodically.</p>
</div>

<div class="section contact-info px-4 py-2">
    <h2><strong>6. Contact Information</strong></h2>
    <p>For any queries or concerns regarding refunds, please contact:</p>
    <p><strong>Email:</strong> <a href="mailto:booking@rourkelaclub.org">booking@rourkelaclub.org</a></p>
    <p><strong>Phone:</strong> [98XXXXXXX]</p>
</div>
      </Container>
    </>
  );
};

export default Refund;
