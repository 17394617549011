import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, Button, Table, Row, Col } from "reactstrap";
import { getBookingStatus } from "../../services/RoomService";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import "./Dashboard.css";
import TableNoResultFound from "../../constant/NoResultFound/TableNoResultFound";

const RecentBookingGraph = () => {
  const [dataset, setDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const pageSize = 5; // Number of rows per page
  const navigate = useNavigate();

  useEffect(() => {
    fetchBookings(currentPage);
  }, [currentPage]);

  const fetchBookings = (page) => {
    setIsLoading(true);
    getBookingStatus(page, pageSize, "", "", "", "", "-created_at")
      .then((resp) => {
        setDataSet(resp.data.results);
        setTotalPages(Math.ceil(resp.data.count / pageSize)); // Calculate total pages
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching bookings:", error);
        setIsLoading(false);
      });
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Card className="shadow">
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">
              <b>Upcoming Booking</b>
            </h3>
          </div>
          <div className="col text-right">
            <Button
              color="primary"
              onClick={() => navigate("/booking-status")}
              size="sm"
            >
              See all
            </Button>
          </div>
        </Row>
      </CardHeader>
      <div className="table-responsive-wrapper">
        <Table className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">Room name</th>
              <th scope="col">Members</th>
              <th scope="col">Booking Date</th>
              <th scope="col">Price</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4" className="text-center my-7">
                  <div className="text-center my-5">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            ) : dataset ? (
              dataset.map((item, index) => (
                <tr key={index}>
                  <th scope="row">{item.booking_slot?.room?.name || "N/A"}</th>
                  <td>
                    {item?.user?.first_name || "N/A"}{" "}
                    {item?.user?.last_name || "N/A"}
                    {item?.is_on_behalf_booking && (
                      <div style={{ fontSize: "0.6rem", color: "gray" }}>
                        (Booked By Super Admin)
                      </div>
                    )}
                  </td>
                  <td>{item.booking_slot?.booking_date || "N/A"}</td>
                  <td>
                    ₹ {(item.booking_slot?.room?.price * 1.18 || 0).toFixed(2)}
                  </td>
                </tr>
              ))
            ) : (
              <TableNoResultFound />
            )}
          </tbody>
        </Table>
      </div>
      <div className="d-flex align-items-center justify-content-center pagination-container mb-3">
          <div className="text-left me-4">
            <Button
              color="primary"
              size="sm"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft className="text-base" />
            </Button>
          </div>
          <div className="text-right">
            <Button
              color="primary"
              size="sm"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardArrowRight className="text-base" />
            </Button>
          </div>
      </div>
    </Card>
  );
};

export default RecentBookingGraph;
