import axiosInstance from "./AxiosInstance";

const apiUrl = "api/event";
const apiOpenUrl = "api/open_event";

export function getOpenEventList(page, page_size, query) {
  return axiosInstance.get(
    `${apiOpenUrl}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getOpenEvent(id) {
  return axiosInstance.get(`${apiOpenUrl}/${id}`);
}

export function getEventList(page, page_size, query) {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getEvent(id) {
  return axiosInstance.get(`${apiUrl}/${id}`);
}

export function createEvent(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateEvent(data) {
  return axiosInstance.put(apiUrl, data);
}

export function deleteEvent(id) {
  return axiosInstance.delete(`${apiUrl}/${id}`);
}
