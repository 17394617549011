import React from "react";
import Flatpickr from "react-flatpickr";

const RenderCalendar = ({
    handleDateChange,
    bookingDate,
    disableDateList,
    calendarRef,
    handleOnDayCreate,
  }) => {
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(today.getDate() + 90);
  
    return (
      <Flatpickr
        ref={calendarRef}
        value={bookingDate}
        onChange={handleDateChange}
        className="form-control custom-flatpickr w-full mx-auto border border-gray-800 bg-transparent rounded-md shadow-sm focus:outline-none focus:ring-2"
        placeholder="Select booking date"
        options={{
          dateFormat: "Y-m-d",
          minDate: "today",
          maxDate: maxDate,
          showMonths: 1,
          altInput: true,
          altFormat: "d/m/Y",
          position: "below center",
          disableMobile: true,
          disable: disableDateList,
          onDayCreate: handleOnDayCreate,
        }}
      />
    );
  };

  export default RenderCalendar;
