import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "flatpickr/dist/themes/material_blue.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import "./modalStyles.css";
import RazorpayPayment from "../PopUpPage/RazorPay";

const formatDate = (dateStr) => {
  const parsedDate = parse(dateStr, "dd-MM-yyyy", new Date());
  return format(parsedDate, "dd/MM/yyyy");
};

const PendingBookingModal = ({ room }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = (show) => {
    setShowModal(show);
    console.log("date", room.booking_slot.room?.id);
  };

  return (
    <>
      <span
        className="text-orange px-2 cursor-pointer"
        onClick={() => toggleModal(true)}
      >
        <i className="fa-solid fa-pen-to-square"></i> Pending
      </span>

      <Modal show={showModal} onHide={() => toggleModal(false)} centered={true}>
        <Modal.Body className="py-3 px-4">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <div class="round-div border-0">
              <i class="fas fa-credit-card"></i>
            </div>
          </div>
          <h4 className="text-center mb-2">
            <strong>
              Your booking "{room?.booking_number}" is still pending. Please
              complete the payment to confirm your reservation.
            </strong>
          </h4>
          <div style={{ fontSize: "0.6rem", color: "gray" }} className="mb-3">
          <i class="fas fa-info-circle me-2"></i><span>Please be aware that if the payment is not completed within the next
            24 hours, your booking will be automatically canceled.</span> <br />
            <i class="fas fa-info-circle me-2"></i><span>To ensure
            your reservation is secured, please make the payment as soon as
            possible using pay now button.</span>
          </div>
          <div className="text-center">
            <RazorpayPayment
              price={Math.ceil(room?.booking_net_amount)}
              bookingId={room?.id}
              roomId={room?.booking_slot?.room?.id}
              bookingDate={room.booking_slot?.booking_date}
              bookingTime={room.booking_slot?.booking_time}
              paymentSource="Razorpay"
              paymentMode="Online"
              isPendingBooking={true}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PendingBookingModal;
