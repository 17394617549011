import React, { useState } from "react";

const RoomTypeDropDown = ({
  setFilter,
  filter,
  setCurrentPage,
  style,
  className,
  classNamedropdown
}) => {
  const [dropdown, setDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleFilterChange = (name, value) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
    setCurrentPage(1);
    setDropdown(false);
  };

  return (
    <div className={`dropdown-filter-status ${classNamedropdown}`}>
      <button
        className={`btn dropdown-toggle ${className}`}
        style={style}
        type="button"
        id="roomTypeDropdown"
        onClick={handleToggleDropdown}
      >
        {filter.room_type || "Room Type"}
      </button>
      {dropdown && (
        <ul className="dropdown-filter-menu" aria-labelledby="roomTypeDropdown">
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilterChange("room_type", "")}
            >
              All
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilterChange("room_type", "Room")}
            >
              Room
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilterChange("room_type", "Lounge")}
            >
              Lounge
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilterChange("room_type", "Hall")}
            >
              Hall
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilterChange("room_type", "Mandap")}
            >
              Mandap
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export const StatusTypeDropDown = ({
  setFilterStatus,
  filterStatus,
  setCurrentPage,
}) => {
  const [statusdropdown, setStatusDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setStatusDropdown(!statusdropdown);
  };

  const handleFilter = (status) => {
    setFilterStatus(status);
    setCurrentPage(1);
    setStatusDropdown(false);
  };

  return (
    <div className="dropdown-filter-status">
      <button
        className="btn dropdown-toggle"
        type="button"
        id="statusDropdown"
        onClick={handleToggleDropdown}
      >
        {filterStatus || "Status"}
      </button>
      {statusdropdown && (
        <ul className="dropdown-filter-menu" aria-labelledby="statusDropdown">
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilter(null)}
            >
              All
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilter("Pending")}
            >
              Pending
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilter("Modify")}
            >
              Modify
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilter("Booked")}
            >
              Booked
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilter("Cancelled")}
            >
              Cancelled
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export const SortTypeDropDown = ({
  setSortBy,
  sortBy,
  style,
  className,
  classNamedropdown
}) => {
  const [sortdropdown, setSortDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setSortDropdown(!sortdropdown);
  };

  
  const handleSortPrice = (order) => {
    if (order === "Low-High") {
      setSortBy("price");
    } else if (order === "High-Low") {
      setSortBy("-price");
    }
    setSortDropdown(false);
  };

  return (
    <div className={`dropdown-filter-status ${classNamedropdown}`}>
      <button
        className={`btn dropdown-toggle ${className}`}
        onClick={handleToggleDropdown}
        style={style}
        type="button"
        id="sortDropdown"
      >
        {sortBy === "-created_at"
          ? "Sort By"
          : sortBy === "-price"
          ? "High-Low"
          : "Low-High"}
      </button>
      {sortdropdown && (
        <ul className="dropdown-filter-menu" aria-labelledby="sortDropdown">
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleSortPrice("Low-High")}
            >
              Low-High
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleSortPrice("High-Low")}
            >
              High-Low
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default RoomTypeDropDown;
