import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { createMemberAPI } from "../../services/VerifyMemberService";
import { Modal } from "react-bootstrap";
const INPUT_FIELD_LIST = [
  {
    label: "First Name",
    name: "first_name",
    type: "text",
    placeholder: "Enter your first name",
  },
  {
    label: "Last Name",
    name: "last_name",
    type: "text",
    placeholder: "Enter your last name",
  },
  {
    label: "Membership ID",
    name: "membership_id",
    type: "text",
    placeholder: "Enter membership ID",
  },
  {
    label: "Membership Type",
    name: "membership_type",
    type: "select",
    placeholder: "Select membership type",
    options: [
      { label: "SAIL RSP Member", value: "SAIL RSP Member" },
      { label: "GOVERNMENT Member", value: "GOVERNMENT Member" },
      { label: "PRIVATE Member", value: "PRIVATE Member" },
      { label: "Honorary Member", value: "Honorary Member" },
    ],
  },
  {
    label: "Email ID",
    name: "email",
    type: "email",
    placeholder: "Enter your email address",
  },
  {
    label: "Phone Number",
    name: "phone_number",
    type: "number",
    placeholder: "Enter your phone number",
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    placeholder: "Enter your password",
  },
];
const initialFormState = {
  first_name: "",
  last_name: "",
  membership_id: "",
  membership_type: "",
  email: "",
  phone_number: "",
  password: "",
};

const AddMemberPopup = ({ data, setData }) => {
  const [addMemberButtonHover, setAddMemberButtonHover] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialFormState);

  const toggleForm = () => {
    if (showForm) {
      setFormData(initialFormState);
      setErrors(initialFormState);
    }
    setShowForm(!showForm);
  };

  const handleValidate = (name, value) => {
    if (name === "email") {
      if (!value) {
        setErrors({ ...errors, [name]: "Email is required" });
        return false;
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        setErrors({ ...errors, [name]: "Invalid Email format." });
        return false;
      } else {
        setErrors({ ...errors, [name]: "" });
        return true;
      }
    } else if (name === "phone_number") {
      if (!value) {
        setErrors({ ...errors, [name]: "Phone number is required" });
        return false;
      } else if (!/^\d{10}$/.test(value)) {
        setErrors({ ...errors, [name]: "Invalid phone number." });
        return false;
      } else {
        setErrors({ ...errors, [name]: "" });
        return true;
      }
    } else {
      const errorMsgObj = {
        first_name: "First name",
        last_name: "Last name",
        membership_id: "Membership Id",
        membership_type: "Membership type",
        password: "Password",
      };
      if (!value) {
        const error = { ...errors, [name]: `${errorMsgObj[name]} is required` };
        setErrors(error);
        return false;
      } else {
        setErrors({ ...errors, [name]: "" });
        return true;
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleValidate(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSubmitLoading) {
      return;
    }
    const isValidInput = Object.keys(formData).every(
      (key) => formData[key].length > 0
    );
    if (isValidInput) {
      setIsSubmitLoading(true);
      createMemberAPI(formData)
        .then((resp) => {
          const { results, message } = resp.data;
          setData([results, ...data]);
          toast.success(message);
          toggleForm();
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsSubmitLoading(false);
        });
    } else {
      let errorMessage = { ...errors };
      Object.keys(formData).forEach((name) => {
        const value = formData[name];
        if (name === "email") {
          if (!value) {
            errorMessage = { ...errorMessage, [name]: "Email is required" };
          } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
            errorMessage = { ...errorMessage, [name]: "Invalid Email format." };
          } else {
            errorMessage = { ...errorMessage, [name]: "" };
          }
        } else if (name === "phone_number") {
          if (!value) {
            errorMessage = {
              ...errorMessage,
              [name]: "Phone number is required",
            };
          } else if (!/^\d{10}$/.test(value)) {
            errorMessage = { ...errorMessage, [name]: "Invalid phone number." };
          } else {
            errorMessage = { ...errorMessage, [name]: "" };
          }
        } else {
          const errorMsgObj = {
            first_name: "First name",
            last_name: "Last name",
            membership_id: "Membership Id",
            membership_type: "Membership type",
            password: "Password",
          };
          if (!value) {
            errorMessage = {
              ...errorMessage,
              [name]: `${errorMsgObj[name]} is required`,
            };
          } else {
            errorMessage = { ...errorMessage, [name]: "" };
          }
        }
      });
      setErrors(errorMessage)
    }
  };

  return (
    <>
      <Button
        className="px-3"
        onClick={toggleForm}
        style={{
          color: addMemberButtonHover ? "#d9cf4e" : "white",
          backgroundColor: addMemberButtonHover ? "#d9cf4e" : "rgb(19 19 ,19)",
          border: "none",
          transition: "background-color 0.3s ease, color 0.3s ease",
        }}
        onMouseEnter={() => setAddMemberButtonHover(true)}
        onMouseLeave={() => setAddMemberButtonHover(false)}
      >
        {addMemberButtonHover ? (
          <>
            <i className="fa fa-user-plus"></i>
          </>
        ) : (
          <i className="fa fa-user-plus"></i>
        )}
      </Button>
      <Modal show={showForm} onHide={toggleForm} centered={true}>
        <Modal.Header>
          <Modal.Title>
            <span className="text-white">Add Member</span>
          </Modal.Title>
          <div onClick={toggleForm}>
            <i className="fa-solid fa-xmark text-white"></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          {INPUT_FIELD_LIST.map(
            ({ label, name, type, placeholder, options }) => (
              <div className="form-group px-2 py-1" key={name}>
                <label htmlFor={name}>{label}:</label>
                {type === "select" ? (
                  <div className="input-group mb-1">
                    <select
                      id={name}
                      name={name}
                      value={formData[name]}
                      onChange={handleChange}
                      className="custom-select"
                      onBlur={(e) =>
                        handleValidate(e.target.name, e.target.value)
                      }
                    >
                      <option value="" disabled>
                        {placeholder}
                      </option>
                      {options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {errors[name] && (
                      <div className="form-error">{errors[name]}</div>
                    )}
                  </div>
                ) : (
                  <div className="input-group mb-1">
                    <input
                      type={type}
                      name={name}
                      value={formData[name]}
                      className={`form-control ${errors[name]} ? "is-invalid" : ""}`}
                      onChange={handleChange}
                      onBlur={(e) =>
                        handleValidate(e.target.name, e.target.value)
                      }
                      placeholder={placeholder}
                    />
                    {errors[name] && (
                      <div className="form-error">{errors[name]}</div>
                    )}
                  </div>
                )}
              </div>
            )
          )}
          <div className="btn-container">
            <button
              type="submit"
              className="btn text-white bg-black"
              disabled={isSubmitLoading}
              onClick={handleSubmit}
            >
              Create
            </button>
            <button type="button" onClick={toggleForm} className="cancel-btn">
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddMemberPopup;
