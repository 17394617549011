import React, { useState, useEffect, useRef } from "react";
import Flatpickr from "react-flatpickr";
import { IoCloseOutline } from "react-icons/io5";
import { Modal } from "react-bootstrap";
import { getRoomAvailable } from "../../../../services/RoomService";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const CalendarPopup = ({ roomId, roomSlotType, userRole }) => {
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [data, setData] = useState([]);
  const [slotType, setSlotType] = useState("09:00 AM to 03:00 PM");

  const calendarRef = useRef(null);

  const togglePopup = () => {
    if (calendarPopup) {
      setData([]);
    }
    setCalendarPopup(!calendarPopup);
  };

  useEffect(() => {
    if (calendarPopup) {
      getRoomAvailable(roomId)
        .then((resp) => {
          setData(resp.data.results);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    }
  }, [calendarPopup, roomId]);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarElement = calendarRef.current;
      if (window.bootstrap && window.bootstrap.Tooltip) {
        const tooltipTriggerList = [].slice.call(
          calendarElement.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
      }
    }
  }, [data, slotType, calendarPopup]);

  const getDateStatus = (date, slotType) => {
    const found = data.find((item) => {
      const slotDate = new Date(item.booking_date);
      return (
        slotDate.toDateString() === date.toDateString() &&
        item.booking_time === slotType
      );
    });
    return found
      ? {
          status: found.status,
          username: `${found.booking_user?.first_name} ${found.booking_user?.last_name}`,
        }
      : {};
  };

  const renderCalendar = (slotType) => {
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(today.getDate() + 90);

    return (
      <Flatpickr
        options={{
          inline: true,
          dateFormat: "d-m-Y",
          minDate: "today",
          maxDate: maxDate,
          showMonths: 1,
          onDayCreate: (dObj, dStr, fp, dayElem) => {
            const { status, username } = getDateStatus(
              dayElem.dateObj,
              slotType
            );
            if (status === "Open") {
              dayElem.style.backgroundColor = "#2dce89";
            } else if (status === "Pending") {
              dayElem.style.backgroundColor = "orange";
            } else if (status === "Booked") {
              dayElem.style.backgroundColor = "rgb(239 68 68)";
              if (userRole === "SuperAdmin" || userRole === "Admin") {
                dayElem.setAttribute("data-bs-toggle", "tooltip");
                dayElem.setAttribute("data-bs-placement", "top");
                dayElem.setAttribute("title", `Booked by: ${username}`);
              }
            }

            if (["Open", "Booked", "Pending"].includes(status)) {
              dayElem.style.color = "white";
            }
            dayElem.style.margin = "3px";
          },
        }}
        className="flatpickr-black-theme w-1/3 p-1 calendar"
      />
    );
  };

  return (
    <>
      <div>
        <button
          className="text-white text-sm bg-black px-3 py-2 rounded duration-200"
          onClick={togglePopup}
        >
          Availability
        </button>
      </div>
      <Modal show={calendarPopup} onHide={togglePopup} centered={true}>
        <Modal.Header>
          <Modal.Title>
            <span className="text-white">Room Availability</span>
          </Modal.Title>
          <div onClick={togglePopup}>
            <IoCloseOutline className="text-white" />
          </div>
        </Modal.Header>
        <Modal.Body>
          {data.length > 0 ? (
            <>
              {roomSlotType === "Full Day" ? (
                <div className="my-4 px-sm-4 px-2 mb-3">
                  <div className="w-full mt--4 d-flex align-items-center justify-content-center">
                    {renderCalendar("09:00 AM to 11:00 PM")}
                  </div>
                  <div className="px-sm-3 px-0 d-flex align-items-center justify-content-between mt-4">
                    <div className="flex items-center">
                      <div className="w-5 h-5 bg-success rounded-full mr-2"></div>
                      <span className="text-medium font-medium">Available</span>
                    </div>
                    <div className="flex items-center">
                      <div className="w-5 h-5 bg-gray-500 rounded-full mr-2"></div>
                      <span className="text-medium font-medium">Pending</span>
                    </div>
                    <div className="flex items-center">
                      <div className="w-5 h-5 bg-red-500 rounded-full mr-2"></div>
                      <span className="text-medium font-medium">Booked</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="my-4 px-sm-4 px-2 mb-3">
                  <div>
                    <div className="w-full mt--3 d-flex align-items-center justify-around">
                      <div className="mb-4">
                        <input
                          type="radio"
                          id="morning"
                          name="slotType"
                          value="09:00 AM to 03:00 PM"
                          checked={slotType === "09:00 AM to 03:00 PM"}
                          onChange={(e) => setSlotType(e.target.value)}
                        />
                        <label htmlFor="morning" className="ml-2">
                          Morning
                        </label>
                      </div>
                      <div className="mb-4">
                        <input
                          type="radio"
                          id="evening"
                          name="slotType"
                          value="06:00 PM to 11:00 PM"
                          checked={slotType === "06:00 PM to 11:00 PM"}
                          onChange={(e) => setSlotType(e.target.value)}
                        />
                        <label htmlFor="evening" className="ml-2">
                          Evening
                        </label>
                      </div>
                    </div>
                    <div className="w-full mt--3 d-flex align-items-center justify-content-center">
                      {renderCalendar(slotType)}
                    </div>
                  </div>
                  <div className="px-sm-3 px-0 d-flex align-items-center justify-content-between mt-4">
                    <div className="flex items-center">
                      <div className="w-5 h-5 bg-success rounded-full mr-2"></div>
                      <span className="text-medium font-medium">Available</span>
                    </div>
                    <div className="flex items-center">
                      <div className="w-5 h-5 bg-gray-500 rounded-full mr-2"></div>
                      <span className="text-medium font-medium">Pending</span>
                    </div>
                    <div className="flex items-center">
                      <div className="w-5 h-5 bg-red-500 rounded-full mr-2"></div>
                      <span className="text-medium font-medium">Booked</span>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div
              className="text-center my-7"
              style={{ width: "100%", height: "100%" }}
            >
              <div className="spinner-grow text-black me-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-black me-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-black me-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-black" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "User",
});

export default connect(mapStateToProps)(CalendarPopup);
