import React, { useState, useEffect, useRef } from "react";
import { Media } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { NavbarLinks } from "./Navbar";
import LogoutModal from "../../../Logout/Logout";
import { logoutAction } from "../../../../store/actions/AuthActions";
import { getUserNameIcon } from "../../../../utils";
import { useDispatch } from "react-redux";
import "./ResponsiveMenu.css";

const ResponsiveMenu = ({ showMenu, setShowMenu, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleExitModal = () => setExitModalOpen(!exitModalOpen);

  function onLogout() {
    dispatch(logoutAction(navigate));
    toggleExitModal();
  }

  function profileClick() {
    navigate("/user-profile");
    setShowMenu(false);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowMenu]);

  return (
    <>
      <div
        ref={menuRef}
        className={`${
          showMenu ? "left-0" : "-left-[100%]"
        } responsive-menu fixed bottom-0 top-0 z-20 flex h-screen w-[70%] flex-col justify-between transition-all duration-500 ease-in-out md:hidden`}
      >
        <div className="responsive-menu-content p-3">
          <div className="profile-info flex items-center justify-center flex-col gap-3 mb-4">
            <Media className="align-items-center">
              <span
                className="avatar avatar-lg rounded-circle"
                onClick={profileClick}
              >{user ? (user?.profile_photo ? (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${user.profile_photo}`}
                    alt="profile"
                    className="profile-photo"
                  />
                ) : (
                  <>
                    {getUserNameIcon(user?.first_name + " " + user?.last_name)}
                  </>
                )) : <i class="fa-regular fa-user text-base"></i> } 
              </span>
            </Media>
            <div className="text-center my-2">
              <h4 className="text-lg font-semibold text-white">
                {  user?.first_name && user?.last_name
                  ? ` Hello, ${user.first_name} ${user.last_name}`
                  : "Guest"}
              </h4>
            </div>
          </div>
          <hr className="my-3 border-gray-300" />
          <nav className="menu-nav mx-1">
            <ul className="nav-links text-lg space-y-4">
              {NavbarLinks.map((data) => (
                <li key={data.name} className="nav-item">
                  <Link
                    to={data.link}
                    onClick={() => setShowMenu(false)}
                    className="nav-linkk mb-2 inline-block py-2  rounded-lg transition-all duration-300 hover:bg-opacity-75 flex items-center justify-between"
                    style={{ color: "white" }}
                  >
                    <div className="flex items-center">
                      <i className={`${data.icon} me-2 text-lg`}></i>
                      {data.name}
                    </div>
                    <i className="fa-solid fa-circle-chevron-right"></i>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className="logout-container flex flex-col items-center mt-auto mb-6 space-y-3">
            {user ? (
              <button
                onClick={toggleExitModal}
                className="logout-btn rounded px-3 py-1 text-lg mx-auto w-full"
              >
                <i className="fa-solid fa-power-off me-3"></i>
                <span>Logout</span>
              </button>
            ) : (
              <>
                <Link
                  to="/login"
                  className="login-btn btn bg-black text-white w-full py-2 px-4 rounded-lg"
                  onClick={() => setShowMenu(false)}
                >
                  Login
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      <LogoutModal
        show={exitModalOpen}
        onHide={toggleExitModal}
        onLogout={onLogout}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ResponsiveMenu);
