import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getOpenEventList } from "../../../../services/EventService";
import Loader from "../../../Loader/Loader";

const Events = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const recordsPage = 15;

  useEffect(() => {
    setIsLoading(true);
    getOpenEventList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error.response ? error.response.data.message : error);
        setIsLoading(false);
      });
  }, [currentPage, searchQuery]);

  const handleExploreAll = () => {
    navigate("/events");
  };

  const handleShowDetails = (event) => {
    navigate(`/event-details/${event.id}`, { state: { event } });
  };

  return (
    <>
      <div className="dark:bg-gray-900 dark:text-white py-4">
        <section data-aos="fade-up" className="container ">
          <h1 className=" my-5 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-bold">
            Our Latest Events
          </h1>
          <>
            {isLoading ? (
              <div
                className="text-center my-9"
                style={{ width: "100%", height: "100%" }}
              >
                <Loader />
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
                {data.slice(0, 6).map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleShowDetails(item)}
                    className="p-4 shadow-lg transition-all duration-500 hover:shadow-xl dark:bg-slate-950 dark:text-white"
                  >
                    <div className="overflow-hidden">
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}${item?.photo}`}
                        alt="No image"
                        className="mx-auto h-[250px] w-full object-cover transition duration-700 hover:skew-x-2 hover:scale-110"
                      />
                    </div>
                    <div className="flex justify-between pt-2 text-slate-600">
                      <p className="font-bold">{item?.created_at}</p>
                      <p className="line-clamp-1">
                        By <span className="font-bold">{item?.upload_by}</span>
                      </p>
                    </div>
                    <div className="space-y-2 py-3">
                      <h1 className="line-clamp-1 font-bold">{item?.title}</h1>
                      <p className="line-clamp-2">{item?.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
          <div className="text-center mt-4">
            <button
              className="btn bg-black text-white px-4 py-2 rounded"
              onClick={handleExploreAll}
            >
              Explore All
            </button>
          </div>
        </section>
      </div>
    </>
  );
};

export default Events;
