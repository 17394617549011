import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  getMaintenanceList,
  updateMaintenanceRoom,
} from "../../services/RoomService.js";
import Loader from "../../components/Loader/Loader.js";

const recordsPage = 15;

const MaintenanceRoom = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [removeMaintenance, setRemoveMaintenance] = useState({});
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getMaintenanceList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [currentPage, searchQuery]);

  const handleConfirmMaintenance = async (roomId, isMaintenance) => {
    try {
      const maintenanceData = isMaintenance ? "YES" : "NO";
      const resp = await updateMaintenanceRoom(roomId, maintenanceData);
      setData((prevData) => prevData.filter((item) => item.id !== roomId));
      toast.success(resp.data.message);
      setRemoveMaintenance((prev) => ({ ...prev, [roomId]: false }));
      navigate("/rooms");
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred.");
    }
  };

  const handleCheckboxChange = (roomId) => {
    setRemoveMaintenance((prev) => {
      const newState = Object.keys(prev).reduce((acc, id) => {
        acc[id] = false;
        return acc;
      }, {});
      newState[roomId] = !prev[roomId];
      return newState;
    });
  };

  return (
    <>
      <div className="row g-4 mb-4">
        {isLoading ? (
          <div
            className="text-center my-7"
            style={{ width: "100%", height: "100%" }}
          >
            <Loader />
          </div>
        ) : data ? (
          data.map((item, key) => (
            <div
              key={key}
              className="col-lg-4 col-md-6 mb-4 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div
                className="room-item rounded overflow-hidden bg-white"
                style={{
                  transition: "transform 0.2s, box-shadow 0.2s",
                }}
              >
                <Carousel
                  showArrows={true}
                  autoPlay={false}
                  interval={3000}
                  infiniteLoop={true}
                  showIndicators={true}
                  showThumbs={false}
                  swipeable={true}
                >
                  {item.roomphoto.map((photo, index) => (
                    <div key={index}>
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}${photo.photo}`}
                        alt="Room"
                        className="img-fluid"
                        style={{
                          height: "230px",
                          width: "100%",
                          objectFit: "cover",
                          filter: "brightness(90%)",
                        }}
                      />
                    </div>
                  ))}
                </Carousel>

                <div
                  className="p-3 border rounded shadow-sm"
                  style={{ backgroundColor: "rgb(231 234 239)" }}
                >
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5
                      className="mb-0 text-truncate"
                      style={{ maxWidth: "70%" }}
                    >
                      {item.name}
                    </h5>
                    <h5 className="mb-0 text-black font-bold">
                      <strong>Rs.</strong> {item.price}
                    </h5>
                  </div>
                  <div className="d-flex mb-2">
                    <small className="border-end me-2 pe-2 text-muted">
                      {item.room_slot_type}
                    </small>
                    <small className="border-end me-2 pe-2 text-muted">
                      {item.room_type}
                    </small>
                  </div>
                  <p
                    className="text-body mb-2 small text-truncate"
                    style={{ maxHeight: "3em", overflow: "hidden" }}
                  >
                    {item.description}
                  </p>
                  <div className="form-check form-check-inline mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="maintenance"
                      id={`maintenance-${item.id}`}
                      value="Maintenance"
                      checked={!!removeMaintenance[item.id]}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`maintenance-${item.id}`}
                    >
                      Remove From Maintenance
                    </label>
                    {removeMaintenance[item.id] && (
                      <button
                        className="btn btn-sm btn-success ms-2"
                        onClick={() => handleConfirmMaintenance(item.id, false)}
                      >
                        Confirm
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ): (<div
        className="card d-flex justify-content-center align-items-center"
        style={{ height: "50vh", width: "100%" }}
      >
        <div className="text-center">
          <i
            className="fa-solid fa-door-open fa-3x mb-2"
            style={{ fontSize: "2rem", opacity: 0.5, color: "#17a2b8" }}
          ></i>
          <h3 style={{ opacity: 0.5 }}>No rooms in maintenance</h3>
        </div>
      </div>
    )}
      </div>
    </>
  );
};

export default MaintenanceRoom;
