import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../constant/Pagination";
import { getOpenEventList } from "../../../../services/EventService";
import Loader from "../../../Loader/Loader";

const AllEvents = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const recordsPage = 12;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getOpenEventList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [currentPage, searchQuery]);

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const handleShowDetails = (event) => {
    navigate(`/event-details/${event.id}`, { state: { event } });
  };

  return (
    <>
      <div
        className="dark:bg-gray-900 dark:text-white bg-gray-50 py-6"
        style={{ minHeight: "100vh" }}
      >
        <section data-aos="fade-up" className="container">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <h1 className="mb-5 mt-3 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-bold">
              Our Latest Events
            </h1>
            <div className="d-flex align-items-end">
              <div className="mb-3">
                <div className="search-bar">
                  <input
                    type="text"
                    className="form-control roomsearch-input"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <i className="fas fa-search search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <>
            {isLoading ? (
              <div
                className="text-center my-7"
                style={{ width: "100%", height: "100%" }}
              >
                <Loader />
              </div>
            ) : data.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {data.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleShowDetails(item)}
                    className="p-3 shadow-lg transition-all duration-500 hover:shadow-xl dark:bg-slate-950 dark:text-white"
                  >
                    <div className="overflow-hidden">
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}${item?.photo}`}
                        alt="No image"
                        className="mx-auto h-[250px] w-full object-cover transition duration-700 hover:skew-x-2 hover:scale-110"
                      />
                    </div>
                    <div className="flex justify-between pt-2 text-slate-600">
                      <p>{item?.created_at}</p>
                      <p className="line-clamp-1">By {item?.upload_by}</p>
                    </div>
                    <div className="space-y-2 py-3">
                      <h1 className="line-clamp-1 font-bold">{item?.title}</h1>
                      <p className="line-clamp-2">{item?.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-results-found text-center my-7">
                <p>No results found</p>
                <i className="fas fa-exclamation-circle text-5xl text-gray-400 mt-4"></i>
              </div>
            )}
          </>

          <Pagination
            currentPage={currentPage}
            recordsPage={recordsPage}
            dataLength={total}
            handlePagination={handlePagination}
          />
        </section>
      </div>
    </>
  );
};

export default AllEvents;
