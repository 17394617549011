import axiosInstance from "./AxiosInstance";
const apiUrl = "api/member";
const apiMemberHistory = "api/member_bookinghistory"

export function getMemberListAPI(page, page_size, query="", status="",sort_by) {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}&status=${status}&sort_by=${sort_by}`
  );
}

export function createMemberAPI(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateMemberAPI(data) {
  return axiosInstance.put(apiUrl, data);
}


export function verifyMemberAPI(data) {
  return axiosInstance.patch(apiUrl, data);
}

export function getMemberBookingHistory(user_id) {
  return axiosInstance.get(`${apiMemberHistory}?user_id=${user_id}`);
}

