import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { format, parse } from "date-fns";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  updateRoomBooking,
  getRoomAvailable,
} from "../../../../services/RoomService";

const RenderCalendar = ({
  handleDateChange,
  bookingDate,
  disableDateList,
  handleOnDayCreate,
}) => {
  const today = new Date();
  const maxDate = new Date();
  maxDate.setDate(today.getDate() + 90);

  return (
    <Flatpickr
      value={bookingDate}
      onChange={handleDateChange}
      className="form-control custom-flatpickr w-full p-2 mx-auto border border-gray-800 bg-transparent rounded-md shadow-sm focus:outline-none focus:ring-2"
      placeholder="Select booking date"
      options={{
        dateFormat: "Y-m-d",
        minDate: "today",
        maxDate: maxDate,
        showMonths: 1,
        altInput: true,
        altFormat: "d/m/Y",
        position: "below center",
        disableMobile: true,
        disable: disableDateList,
        onDayCreate: handleOnDayCreate,
      }}
    />
  );
};

const EditBookingModal = ({ booking, updateBookingData }) => {
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);
  const [bookingDate, setBookingDate] = useState(null);
  const [bookingSlot, setBookingSlot] = useState(null);
  const [socketBookingProgress, setSocketBookingProgress] = useState([]);
  const [availableData, setAvailableData] = useState([]);
  const [disableDateList, setDisableDateList] = useState([]);
  const [bookedDisableDateList, setBookedDisableDateList] = useState([]);
  const [showCancelBooking, setShowCancelBooking] = useState(false);
  const [bookingToCancel, setBookingToCancel] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [cancelReason, setCancelReason] = useState("");

  const calendarRef = useRef(null);
  const socket = useRef(null);

  const roomId = booking.booking_slot?.room?.id;
  const userId = booking.booking_user?.user?.id;

  useEffect(() => {
    setBookingSlot(booking.booking_slot?.booking_time || null);
  }, [booking]);

  useEffect(() => {
    if (isOffCanvasOpen && roomId) {
      getRoomAvailable(roomId)
        .then((resp) => {
          const { results } = resp.data;
          setAvailableData(results);
          const bookedDate = results
            .filter(
              (item) =>
                item.status === "Booked" &&
                item.booking_time === booking.booking_slot?.booking_time
            )
            .map((item) => item.booking_date);
          setBookedDisableDateList([...bookedDisableDateList, ...bookedDate]);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    }
  }, [isOffCanvasOpen, roomId, booking.booking_slot?.booking_time]);

  const sendMessage = (data) => {
    if (socket.current && socket.current.readyState === WebSocket.OPEN) {
      socket.current.send(JSON.stringify(data));
    }
  };

  const updateSocketData = (booking_time, booking_dates) => {
    let updatedSocketBookingProgress = socketBookingProgress.map((item) => {
      if (item.booking_time === booking_time) {
        return {
          ...item,
          booking_date: [...item.booking_date, ...booking_dates],
        };
      }
      return item;
    });

    if (updatedSocketBookingProgress.length === 0) {
      updatedSocketBookingProgress = [
        { booking_time, booking_date: booking_dates },
      ];
    }
    setSocketBookingProgress(updatedSocketBookingProgress);
    if (booking_time === bookingSlot) {
      setDisableDateList(booking_dates);
    }
  };

  const handleSocketData = (data) => {
    if (data.roomId === roomId) {
      if (data.action === "BOOKING_INIT" && data.user_id === userId) {
        if (data.booking_dates.length > 0) {
          updateSocketData(data.booking_time, [...data.booking_dates]);
        }
      } else if (data.action === "BOOKING_START" && data.user_id !== userId) {
        updateSocketData(data.booking_time, [
          ...disableDateList,
          data.booking_date,
        ]);
      } else if (data.action === "BOOKING_CANCEL") {
        const updatedDisableDateList = disableDateList.filter(
          (item) => item !== data.booking_date
        );
        updateSocketData(data.booking_time, updatedDisableDateList);
      }
    }
  };

  const togglePopup = (isBookingSuccess = false) => {
    if (isOffCanvasOpen) {
      if (!isBookingSuccess && booking.booking_date) {
        const socketPayload = {
          event_type: "BOOKING_CANCEL",
          userId,
          roomId,
          booking_date: "",
          booking_time: booking.booking_time,
        };
        sendMessage(socketPayload);
      }
      setDisableDateList([]);
      setSocketBookingProgress([]);
    } else {
      const updateBookingInput = {
        roomId,
        booking_time:
          booking.booking_slot?.booking_time === "09:00 AM to 11:00 PM"
            ? "09:00 AM to 11:00 PM"
            : "09:00 AM to 03:00 PM",
        booking_date: "",
      };
      socket.current = new WebSocket(
        `${process.env.REACT_APP_WS_BASE_URL}/ws/bookings`
      );
      socket.current.onopen = () => {
        const { room_id, booking_time } = updateBookingInput;
        const socketPayload = {
          event_type: "BOOKING_INIT",
          userId,
          room_id,
          booking_time,
        };
        sendMessage(socketPayload);
      };
      socket.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleSocketData(data.message);
      };
      socket.current.onclose = (event) => {
        console.log("Socket Disconnected ", event);
      };
    }
    setIsOffCanvasOpen(!isOffCanvasOpen);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (booking.booking_date) {
        const socketPayload = {
          event_type: "BOOKING_CANCEL",
          userId,
          roomId,
          booking_date: booking.booking_date
            ? format(booking.booking_date, "yyyy-MM-dd")
            : "",
          booking_time: booking.booking_time,
        };
        sendMessage(socketPayload);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [booking.booking_date]);

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length > 0) {
      setBookingDate(selectedDates[0]);
      setDateError(false);
    }
  };

  const handleOnDayCreate = (dObj, dStr, fp, dayElem) => {
    if (booking.booking_user !== null) {
      if (disableDateList) {
        dayElem.setAttribute("data-bs-toggle", "tooltip");
        dayElem.setAttribute("data-bs-placement", "top");
        dayElem.setAttribute(
          "title",
          `Booked by:${booking.user?.first_name} ${booking.user?.last_name}`
        );
      }
    }
  };

  const handleTimeChange = (e) => {
    const value = e.target.value;
    setBookingSlot(value);
    setBookedDisableDateList(
      availableData
        .filter(
          (item) => item.status === "Booked" && item.booking_time === value
        )
        .map((item) => item.booking_date)
    );
  };

  const handleCancelShow = () => {
    setBookingToCancel(booking);
    setShowCancelBooking(true);
  };

  const handleUpdate = () => {
    try {
      if (!bookingDate) {
        setDateError(true);
        return;
      }

      const updatedBooking = {
        id: booking.id,
        booking_date: format(new Date(bookingDate), "yyyy-MM-dd"),
        booking_time: bookingSlot,
      };

      updateRoomBooking(updatedBooking)
        .then((resp) => {
          const { message, results } = resp.data;
          updateBookingData(results);
          toast.success(message);
          setIsOffCanvasOpen(false);
          toast.info(
            "Please contact the admin to confirm the booking within 24 hours. Otherwise, the previous date will be automatically booked.",
            {
              autoClose: false,
            }
          );
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error("An error occurred. Please try again.");
          }
        });
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  const handleConfirm = () => {
    if (bookingToCancel) {
      const payload = {
        id: bookingToCancel.id,
        cancel_booking: "YES",
        cancel_reason: cancelReason,
      };

      updateRoomBooking(payload)
        .then((resp) => {
          const { message, results } = resp.data;
          updateBookingData(results);
          toast.success(message);
          setShowCancelBooking(false);
          setIsOffCanvasOpen(false);
        })
        .catch((error) => {
          console.log("error", error);
          if (error?.response) {
            toast.error(error.response?.data?.message || "An error occurred");
          }
        });
    }
  };

  const handleCloseModal = () => {
    setIsOffCanvasOpen(false);
    setBookingDate(null);
    let defaultBookingSlot;
    if (booking.booking_slot?.booking_time === "09:00 AM to 11:00 PM") {
      defaultBookingSlot = "09:00 AM to 11:00 PM";
    } else if (booking.booking_slot?.booking_time === "09:00 AM to 03:00 PM") {
      defaultBookingSlot = "09:00 AM to 03:00 PM";
    } else if (booking.booking_slot?.booking_time === "06:00 PM to 11:00 PM") {
      defaultBookingSlot = "06:00 PM to 11:00 PM";
    } else {
      defaultBookingSlot = "";
    }
    setBookingSlot(defaultBookingSlot);

    setDisableDateList([]);
    setSocketBookingProgress([]);
  };

  const handleCancelClose = () => {
    setShowCancelBooking(false);
    setCancelReason("");
  };

  return (
    <>
      {booking.modified_booking_date || booking.modified_booking_time ? (
        <span className="text-yellow">Requested</span>
      ) : (
        <button
          className="btn btn-sm btn-outline-success"
          onClick={togglePopup}
        >
          <i className="fa-solid fa-pen-to-square"></i> Booked
        </button>
      )}

      {isOffCanvasOpen && (
        <div
          className={`offcanvas-container ${
            isOffCanvasOpen ? "open" : ""
          } mt-sm-0 mt-5`}
        >
          <div className="offcanvas-content">
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <h1>
                <strong>Edit Booking</strong>
              </h1>
              <button className="btn text-xl" onClick={handleCloseModal}>
                &times;
              </button>
            </div>
            <ul>
              <li style={{ fontSize: "0.9rem" }}>
                <strong>Room Name : </strong>
                <span>{booking.booking_slot?.room?.name}</span>
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                <strong>Booking Number : </strong>
                <span>{booking?.booking_number}</span>
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                <strong>Booking Date : </strong>
                <span> {booking.booking_slot?.booking_date}</span>
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                {" "}
                <strong>Booking Time : </strong>
                <span> {booking.booking_slot?.booking_time}</span>
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                {" "}
                <strong>Room Price : </strong>
                <span> {booking.booking_slot?.room?.price}</span>
              </li>
            </ul>
            <Form.Group className="mb-3 mt-3">
              <Form.Label>Booking Slot</Form.Label>
              <br />
              {bookingSlot === "09:00 AM to 11:00 PM" ? (
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="booking_time"
                    id="wholeday"
                    checked
                    readOnly
                  />
                  <label className="form-check-label ml-2" htmlFor="wholeday">
                    Full Day
                  </label>
                </div>
              ) : (
                <>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="booking_time"
                      id="morning"
                      value="09:00 AM to 03:00 PM"
                      checked={bookingSlot === "09:00 AM to 03:00 PM"}
                      onChange={handleTimeChange}
                    />
                    <label className="form-check-label ml-2" htmlFor="morning">
                      9 AM - 3 PM
                    </label>
                  </div>
                  <div className="form-check form-check-inline ml-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="booking_time"
                      id="evening"
                      value="06:00 PM to 11:00 PM"
                      checked={bookingSlot === "06:00 PM to 11:00 PM"}
                      onChange={handleTimeChange}
                    />
                    <label className="form-check-label ml-2" htmlFor="evening">
                      6 PM - 11 PM
                    </label>
                  </div>
                </>
              )}
            </Form.Group>
            <div className="mb-3">
              <label htmlFor="date" className="block">
                Booking Date
              </label>
              <RenderCalendar
                handleDateChange={handleDateChange}
                bookingDate={bookingDate}
                disableDateList={[...disableDateList, ...bookedDisableDateList]}
                calendarRef={calendarRef}
                handleOnDayCreate={handleOnDayCreate}
              />
              {dateError && (
                <div className="text-danger mt-1">Please select a date.</div>
              )}
            </div>
            <div className="mb-5">
              <span className="me-4">Want to Cancel Booking?</span>
              <button
                className="btn btn-sm btn-danger"
                onClick={handleCancelShow}
              >
                Cancel Booking
              </button>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div className="mb-3">
                <Button
                  variant="success"
                  className="btn btn-sm"
                  onClick={handleUpdate}
                  disabled={!bookingSlot || !bookingDate}
                >
                  Update
                </Button>

                <Button
                  type="button"
                  className="btn btn-sm btn-danger ms-2"
                  variant="secondary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        show={showCancelBooking}
        onHide={() => setShowCancelBooking(false)}
        centered={true}
        backdrop="static"
        keyboard={false}
        size="sm"
      >
        <Modal.Body className="p-4">
          <label htmlFor="description" className="form-label">
            Reason For Cancel Booking:
          </label>
          <textarea
            id="description"
            name="description"
            className="form-control"
            placeholder="Reason"
            rows="4"
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          ></textarea>{" "}
        </Modal.Body>
        <div className="d-flex align-items-center justify-content-center mb-3">
          <Button variant="secondary" onClick={() => handleCancelClose()}>
            No
          </Button>
          <Button variant="danger" onClick={handleConfirm}>
            Yes
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default EditBookingModal;
