import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { getEventList } from "../../services/EventService.js";
import Pagination from "../../constant/Pagination";
import Header from "../../components/Headers/Header.js";
import EventForm from "./AddEvent";
import EventDetails from "./EventDetails.js";
import Loader from "../../components/Loader/Loader.js";

const AllEvents = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const recordsPage = 12;

  const fetchEvents = () => {
    setIsLoading(true);
    getEventList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  useEffect(() => {
    fetchEvents();
  }, [currentPage, searchQuery]);

  return (
    <>
      <Header />
      <Container className="Container mt-sm-0 mt-6 mb-sm-0 mb-7 p-sm-2 p-1" fluid>
        <div className="d-flex align-items-center justify-content-between p-2">
          <h3 className="mb-3 text-dark fw-bold pb-2">
            <i className="fas fa-cocktail me-2"></i>
            Events
          </h3>
          <div className="d-flex align-items-center justify-content-end">
              <div className="search-bar">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <i className="fas fa-search search-icon"></i>
              </div>
            <EventForm data={data} setData={setData} />
          </div>
        </div>
        <div className="card p-sm-3 p-2">
            {isLoading ? (
              <div
                className="text-center my-7"
                style={{ width: "100%", height: "100%" }}
              >
                <Loader />
              </div>
            ) :
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3"> 
            {
              data.map((item) => (
                <EventDetails item={item} data={data} setData={setData} />
              ))
            }
            </div>
            }
          </div>
          <Pagination
            currentPage={currentPage}
            recordsPage={recordsPage}
            dataLength={total}
            handlePagination={handlePagination}
          />
      </Container>
    </>
  );
};

export default AllEvents;
