

import React, { useState } from "react";
import { FaBell } from "react-icons/fa";
import moment from "moment";
import { markNotificationsAsRead } from "../../../../services/NotificationService";
import { useNavigate } from "react-router-dom";

import "./Notification.css";

const Notification = ({ notifications, updateNotifications }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [removingNotifications, setRemovingNotifications] = useState([]);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    if (isSidebarOpen) {
      const readNotificationIds = notifications
        .filter((notif) => notif.is_read)
        .map((notif) => notif.id);

      if (readNotificationIds.length > 0) {
        markNotificationsAsRead(readNotificationIds, true);
      }
    }
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const unreadCount = notifications.filter((notif) => !notif.is_read).length;

  const sortedNotifications = [...notifications]
    .filter((notif) => isSidebarOpen || !notif.is_read)
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const handleNotificationClick = (index, notif) => {
    const { id, notification_type, booking, user } = notif;

    if (!notifications[index].is_read) {
      markAsRead(id);
    }
    if (
      [
        "NEW BOOKING",
        "BOOKING MODIFY REQUEST",
        "BOOKING CANCELLED",
        "PAYMENT RECEIVED",
        "REFUND PROCESSED",
      ].includes(notification_type)
    ) {
      navigate(`/booking-status`, {
        state: { bookingNumber: booking?.booking_number },
      });
    } else if (notification_type === "USER REGISTERED") {
      navigate(`/member`, { state: { membershipId: user?.membership_id } });
    }

    closeSidebar();
    updateNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n.id !== id)
    );
  };

  const markAsRead = (id) => {
    markNotificationsAsRead(id, true)
      .then(() => {
        updateNotifications((prevNotifications) =>
          prevNotifications.map((notif) =>
            notif.id === id ? { ...notif, is_read: true } : notif
          )
        );
      })
      .catch((error) => {
        console.error("Error marking notification as read", error);
      });
  };

  const handleClearNotification = (id) => {
    markAsRead(id); // Mark as read before clearing
    setRemovingNotifications((prev) => [...prev, id]);
    setTimeout(() => {
      updateNotifications((prevNotifications) =>
        prevNotifications.filter((notif) => notif.id !== id)
      );
      setRemovingNotifications((prev) =>
        prev.filter((notifId) => notifId !== id)
      );
    }, 300); // Allow time for the animation to play
  };

  const markAllAsRead = () => {
    const unreadNotificationIds = notifications
      .filter((notif) => !notif.is_read)
      .map((notif) => notif.id);

    if (unreadNotificationIds.length > 0) {
      markNotificationsAsRead(unreadNotificationIds, true)
        .then(() => {
          updateNotifications((prevNotifications) =>
            prevNotifications.map((notif) => ({ ...notif, is_read: true }))
          );
        })
        .catch((error) => {
          console.error("Error marking all notifications as read", error);
        });
    }
  };

  const clearAllNotifications = () => {
    const notificationIds = notifications.map((notif) => notif.id);
    markNotificationsAsRead(notificationIds, true); // Mark all as read
    setRemovingNotifications(notificationIds);
    setTimeout(() => {
      updateNotifications([]);
    }, 300); // Allow time for the animation to play
  };

  return (
    <>
      <div className="notification-toggle" onClick={toggleSidebar}>
        <FaBell className="notification-icon" />
        {unreadCount > 0 && (
          <span className="notification-badge">{unreadCount}</span>
        )}
      </div>

      <div className={`notification-sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="notification-header">Notifications</div>
        <div className="notification-content">
          {sortedNotifications.length > 0 ? (
            sortedNotifications.map((notif, index) => (
              <div
                key={notif.id}
                className={`notification-item ${
                  removingNotifications.includes(notif.id) ? "removing" : ""
                }`}
                style={{
                  backgroundColor: notif.is_read
                    ? "transparent"
                    : "rgba(255, 255, 255, 0.2)",
                }}
              >
                <span
                  className="notification-message"
                  onClick={() => handleNotificationClick(index, notif)}
                >
                  {notif.message} by {notif.user.first_name}{" "}
                  {notif.user.last_name}
                </span>
                <div className="notification-time">
                  {moment(notif.created_at).fromNow()}
                </div>
                {/* Clear button for individual notification */}
                <button
                  className="clear-button"
                  onClick={() => handleClearNotification(notif.id)}
                >
                  Clear
                </button>
              </div>
            ))
          ) : (
            <div className="no-notification-item">No notifications</div>
          )}
        </div>
        {sortedNotifications.length > 0 && (
          <div className="notification-footer">
            <button className="read-all-button" onClick={markAllAsRead}>
              Mark all as Read
            </button>
            <button
              className="clear-all-button"
              onClick={clearAllNotifications}
            >
              Clear All
            </button>
          </div>
        )}
      </div>

      {isSidebarOpen && (
        <div className="notification-overlay" onClick={closeSidebar}></div>
      )}
    </>
  );
};

export default Notification;
