import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { getRoomAvailable } from "../../../../services/RoomService";
import DynamicSelectInput from "../../../../constant/SelectInput";
import { format } from "date-fns";
import "./BookingPage.css";
import Header from "../../../Headers/Header";
import useWindowSize from "../../../../constant/WindowSize";
import AdminPaymentDetails from "./AdminPaymentDetails";
import { FaAngleRight } from "react-icons/fa";
import CouponPage from "./CouponPage";
import RenderCalendar from "./RenderCalendar";
import UserPaymentMode from "./UserPaymentMode";
import { Container } from "react-bootstrap";
import PriceTable from "./PriceTable";

const BookingPage = ({ userRole, userId, orderId }) => {
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [bookInput, setBookInput] = useState({
    room_id: "",
    booking_time: "",
    booking_date: "",
    on_behalf_id: "",
  });
  const [availableData, setAvailableData] = useState([]);
  const [disableDateList, setDisableDateList] = useState([]);
  const [bookedDisableDateList, setBookedDisableDateList] = useState([]);
  const [showConfirmPage, setShowConfirmPage] = useState(false);
  const [socketBookingProgress, setSocketBookingProgress] = useState([]);
  const [dateError, setDateError] = useState(false);
  const [showCouponPage, setShowCouponPage] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isApplyDisabled, setIsApplyDisabled] = useState(true);
  const calendarRef = useRef(null);
  const socket = useRef(null);
  const location = useLocation();
  const room = location.state?.room || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendMessage = (data) => {
    if (socket.current?.readyState === WebSocket.OPEN) {
      socket.current.send(JSON.stringify(data));
    }
  };

  const handleUpdateSocketData = (booking_time, booking_dates) => {
    let updateSocketData = socketBookingProgress.map((item) => {
      if (item.booking_time === booking_time) {
        return {
          booking_time,
          booking_date: [...item.booking_date, ...booking_dates],
        };
      }
      return { booking_time, booking_date: booking_dates };
    });

    if (updateSocketData.length === 0) {
      updateSocketData = [{ booking_time, booking_date: booking_dates }];
    }
    setSocketBookingProgress(updateSocketData);
  };

  const handleSocketData = (data) => {
    const room_id = room?.id;
    if (data.room_id === room_id) {
      if (data.action === "BOOKING_INIT" && data.user_id === userId) {
        if (data.booking_dates.length > 0) {
          handleUpdateSocketData(data.booking_time, [...data.booking_dates]);
          if (bookInput.booking_time === data.booking_time) {
            setDisableDateList((prevDates) => [
              ...new Set([...prevDates, ...data.booking_dates]),
            ]);
          }
        }
      } else if (data.action === "BOOKING_START") {
        const newDisbaledDates = [...disableDateList, data.booking_date];
        if (bookInput.booking_time === data.booking_time) {
          setDisableDateList((prevDates) => [
            ...new Set([...prevDates, ...newDisbaledDates]),
          ]);
        }
        handleUpdateSocketData(data.booking_time, newDisbaledDates);
      } else if (data.action === "BOOKING_CANCEL") {
        console.log("sss cancel", disableDateList, data.booking_date);
        const updatedDisableDateList = disableDateList.filter(
          (item) => item !== data.booking_date
        );
        handleUpdateSocketData(data.booking_time, updatedDisableDateList);
        if (bookInput.booking_time === data.booking_time) {
          setDisableDateList((prevDates) => [
            ...new Set([...updatedDisableDateList]),
          ]);
        }
      }
    }
  };

  useEffect(() => {
    if (bookInput.booking_time) {
      socket.current = new WebSocket(
        `${process.env.REACT_APP_WS_BASE_URL}/ws/bookings`
      );
      socket.current.onopen = () => {
        const { room_id, booking_time } = bookInput;
        const socketPayload = {
          event_type: "BOOKING_INIT",
          user_id: userId,
          room_id,
          booking_time,
        };
        sendMessage(socketPayload);
        console.log("WebSocket connection opened");
      };

      socket.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleSocketData(data.message);
      };
      socket.current.onclose = (event) => {
        console.log("Socket Disconnected ", event);
      };

      return () => socket.current.close();
    }
  }, [bookInput.booking_time]);

  useEffect(() => {
    if (room?.id) {
      getRoomAvailable(room.id)
        .then((resp) => {
          const { results } = resp.data;
          setAvailableData(results);
          const bookedDate = results
            .filter(
              (item) =>
                item.status === "Booked" &&
                item.booking_time === bookInput.booking_time
            )
            .map((item) => item.booking_date);
          setBookedDisableDateList([...bookedDate]);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    }
  }, [room?.id, bookInput.booking_time]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (bookInput.booking_date && showConfirmPage) {
        const socketPayload = {
          event_type: "BOOKING_CANCEL",
          user_id: userId,
          room_id: bookInput.room_id,
          booking_date: bookInput.booking_date
            ? format(bookInput.booking_date, "yyyy-MM-dd")
            : "",
          booking_time: bookInput.booking_time,
        };
        sendMessage(socketPayload);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [bookInput.booking_date, showConfirmPage]);

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length > 0) {
      setBookInput({ ...bookInput, booking_date: selectedDates[0] });
      setDateError(false);
    }
  };

  useEffect(() => {
    if (calendarRef.current) {
      const calendarElement = calendarRef.current;
      if (window.bootstrap && window.bootstrap.Tooltip) {
        const tooltipTriggerList = [].slice.call(
          calendarElement.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
      }
    }
  }, [availableData]);

  const getDateStatus = (date, slotType) => {
    const found = availableData.find((item) => {
      const slotDate = new Date(item.booking_date);
      return (
        slotDate.toDateString() === date.toDateString() &&
        item.booking_time === slotType
      );
    });
    return found
      ? {
          status: found.status,
          username: `${found.booking_user?.user?.first_name} ${found.booking_user?.user?.last_name}`,
        }
      : {};
  };

  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  const handleOnDayCreate = (dObj, dStr, fp, dayElem) => {
    const { status, username } = getDateStatus(
      dayElem.dateObj,
      bookInput.booking_time
    );
    if (status === "Booked") {
      if (userRole === "SuperAdmin" || userRole === "Admin") {
        dayElem.setAttribute("data-bs-toggle", "tooltip");
        dayElem.setAttribute("data-bs-placement", "top");
        dayElem.setAttribute("title", `Booked by: ${username}`);
      }
    }
  };

  const handleTimeChange = (e) => {
    const value = e.target.value;
    setBookInput({ ...bookInput, booking_time: value });
    setBookedDisableDateList(
      availableData
        .filter(
          (item) => item.status === "Booked" && item.booking_time === value
        )
        .map((item) => item.booking_date)
    );
  };

  useEffect(() => {
    setShowConfirmPage(false);
    const { room_id, booking_date, booking_time } = bookInput;
    const socketPayload = {
      event_type: "BOOKING_CANCEL",
      user_id: userId,
      room_id,
      booking_date: booking_date ? format(booking_date, "yyyy-MM-dd") : "",
      booking_time,
    };
    sendMessage(socketPayload);
    setBookInput({
      room_id: room?.id,
      booking_time:
        room?.room_slot_type === "Full Day"
          ? "09:00 AM to 11:00 PM"
          : "09:00 AM to 03:00 PM",
      booking_date: "",
    });
  }, [room]);

  const handleSelectChange = (name, value, _) => {
    setBookInput({ ...bookInput, [name]: value });
  };

  const handleBook = (e) => {
    e.preventDefault();
    if (!bookInput.booking_date) {
      setDateError(true);
      return;
    }
    setShowConfirmPage(true);
    const { room_id, booking_date, booking_time } = bookInput;
    const socketPayload = {
      event_type: "BOOKING_START",
      user_id: userId,
      room_id,
      booking_date: booking_date ? format(booking_date, "yyyy-MM-dd") : "",
      booking_time,
    };
    sendMessage(socketPayload);
  };

  const handleCancelBook = (e) => {
    e.preventDefault();
    setShowConfirmPage(false);
    const { room_id, booking_date, booking_time } = bookInput;
    const socketPayload = {
      event_type: "BOOKING_0CANCEL",
      user_id: userId,
      room_id,
      booking_date: booking_date ? format(booking_date, "yyyy-MM-dd") : "",
      booking_time,
    };
    sendMessage(socketPayload);
  };

  const handleCouponSelect = (coupon) => {
    setSelectedCoupon(coupon);
    setIsApplyDisabled(false);
  };

  return (
    <>
      {userRole !== "User" ? <Header /> : ""}
        <Container
          className={`${
            userRole === "User"
              ? "mt-8 mb-9 px-sm-7 px-0 py-sm-3 py-1"
              : "mt-sm-0 mt-7 mb-sm-0  mb-7 p-sm-2 p-1"
          }  `}
          fluid
        >
          <div className="row p-2 mb-3">
            <div className="col-md-8 col-sm-12 mb-3">
              <div className="card p-3">
                <div className="table-responsive">
                  <table className="table table-borderless table-shopping-cart">
                    <thead className="text-muted">
                      <tr className="small text-uppercase">
                        <th scope="col">Room</th>
                        <th scope="col" width="200">
                          Details
                        </th>
                        {!isMobile && (
                          <th scope="col" width="150">
                            Price
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            className="mb-3"
                            style={{
                              height: "auto",
                              width: "100%",
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}${room.roomphoto[0]?.photo}`}
                              alt={room.name}
                              className="img-fluid"
                              style={{ maxHeight: "200px", objectFit: "cover" }}
                            />
                          </div>
                        </td>
                        <td>
                          <figcaption className="info">
                            <a
                              href="#"
                              className="title text-dark"
                              data-abc="true"
                            >
                              {room.name}
                            </a>
                            <p className="text-muted small">
                              Type: {room?.room_type}
                            </p>
                          </figcaption>
                        </td>
                        {!isMobile && (
                          <td>
                            <div className="price-wrap">
                              <var className="price">
                                ₹{room.price.toFixed(2)}
                              </var>
                            </div>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>

                {showConfirmPage ? (
                  <div className="payment-methods-container p-4 rounded shadow">
                    <h3 className="payment-title text-center mb-4">
                      Select Payment Method
                    </h3>

                    {!isMobile && userRole !== "User" ? (
                      <AdminPaymentDetails
                        roomId={room?.id}
                        room={bookInput}
                        coupon={selectedCoupon}
                        isMobile={isMobile}
                        handleCancelBook={handleCancelBook}
                      />
                    ) : (
                      <UserPaymentMode
                        orderId={orderId}
                        handleCancelBook={handleCancelBook}
                        coupon={selectedCoupon}
                        setShowConfirmPage={setShowConfirmPage}
                        isMobile={isMobile}
                        bookInput={bookInput}
                        room={room}
                      />
                    )}
                  </div>
                ) : (
                  <form onSubmit={(e) => handleBook(e)}>
                    <div className="card-data flex-fill flex-vertical">
                      {userRole === "User" ? (
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="booking_time">
                              <strong
                                style={{ fontSize: "0.85em" }}
                                className="me-1"
                              >
                                Booking time
                              </strong>
                              <span className="text-danger">*</span>
                            </label>
                            <div>
                              {room?.room_slot_type === "Full Day" && (
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="booking_time"
                                    id="wholeday"
                                    value="09:00 AM to 11:00 PM"
                                    checked={
                                      bookInput.booking_time ===
                                      "09:00 AM to 11:00 PM"
                                    }
                                    onChange={handleTimeChange}
                                  />
                                  <label
                                    className="form-check-label ml-2"
                                    htmlFor="wholeday"
                                  >
                                    Full Day
                                  </label>
                                </div>
                              )}
                              {room?.room_slot_type !== "Full Day" && (
                                <>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="booking_time"
                                      id="morning"
                                      value="09:00 AM to 03:00 PM"
                                      checked={
                                        bookInput.booking_time ===
                                        "09:00 AM to 03:00 PM"
                                      }
                                      onChange={handleTimeChange}
                                    />
                                    <label
                                      className="form-check-label ml-2"
                                      htmlFor="morning"
                                    >
                                      9 AM - 3 PM
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline ml-4">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="booking_time"
                                      id="evening"
                                      value="06:00 PM to 11:00 PM"
                                      checked={
                                        bookInput.booking_time ===
                                        "06:00 PM to 11:00 PM"
                                      }
                                      onChange={handleTimeChange}
                                    />
                                    <label
                                      className="form-check-label ml-2"
                                      htmlFor="evening"
                                    >
                                      6 PM - 11 PM
                                    </label>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="booking_date">
                              <strong
                                style={{ fontSize: "0.85em" }}
                                className="me-1"
                              >
                                Booking Date
                              </strong>
                              <span className="text-danger">*</span>
                            </label>
                            <RenderCalendar
                              handleDateChange={handleDateChange}
                              bookingDate={bookInput.booking_date}
                              disableDateList={[
                                ...disableDateList,
                                ...bookedDisableDateList,
                              ]}
                              calendarRef={calendarRef}
                              handleOnDayCreate={handleOnDayCreate}
                            />
                            {dateError && (
                              <div
                                className="text-danger mt-1"
                                style={{ fontSize: "0.8rem" }}
                              >
                                Please select a date.
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          <div>
                            <label htmlFor="booking_time">
                              <strong
                                style={{ fontSize: "0.85em" }}
                                className="me-1"
                              >
                                Booking time
                              </strong>
                              <span className="text-danger">*</span>
                            </label>
                            <div>
                              {room?.room_slot_type === "Full Day" && (
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="booking_time"
                                    id="wholeday"
                                    value="09:00 AM to 11:00 PM"
                                    checked={
                                      bookInput.booking_time ===
                                      "09:00 AM to 11:00 PM"
                                    }
                                    onChange={handleTimeChange}
                                  />
                                  <label
                                    className="form-check-label ml-2"
                                    htmlFor="wholeday"
                                  >
                                    Full Day
                                  </label>
                                </div>
                              )}
                              {room?.room_slot_type !== "Full Day" && (
                                <>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="booking_time"
                                      id="morning"
                                      value="09:00 AM to 03:00 PM"
                                      checked={
                                        bookInput.booking_time ===
                                        "09:00 AM to 03:00 PM"
                                      }
                                      onChange={handleTimeChange}
                                    />
                                    <label
                                      className="form-check-label ml-2"
                                      htmlFor="morning"
                                    >
                                      9 AM - 3 PM
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline ml-4">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="booking_time"
                                      id="evening"
                                      value="06:00 PM to 11:00 PM"
                                      checked={
                                        bookInput.booking_time ===
                                        "06:00 PM to 11:00 PM"
                                      }
                                      onChange={handleTimeChange}
                                    />
                                    <label
                                      className="form-check-label ml-2"
                                      htmlFor="evening"
                                    >
                                      6 PM - 11 PM
                                    </label>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-12">
                              <label htmlFor="booking_date">
                                <strong
                                  style={{ fontSize: "0.85em" }}
                                  className="me-1"
                                >
                                  Booking Date
                                </strong>
                                <span className="text-danger">*</span>
                              </label>
                              <RenderCalendar
                                handleDateChange={handleDateChange}
                                bookingDate={bookInput.booking_date}
                                disableDateList={[
                                  ...disableDateList,
                                  ...bookedDisableDateList,
                                ]}
                                calendarRef={calendarRef}
                                handleOnDayCreate={handleOnDayCreate}
                              />
                              {dateError && (
                                <div
                                  className="text-danger mt-1"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  Please select a date.
                                </div>
                              )}
                            </div>
                            {userRole !== "User" && (
                              <div className="col-md-6 col-sm-12">
                                <label htmlFor="on_behalf_id">
                                  <strong
                                    style={{ fontSize: "0.85em" }}
                                    className="me-1"
                                  >
                                    On Behalf Member
                                  </strong>
                                  <span className="text-gray-500">
                                    (Optional)
                                  </span>
                                </label>
                                <DynamicSelectInput
                                  parentClassName="mb-0 order"
                                  setParentInputValue={handleSelectChange}
                                  endpoint_name="onbehalf_member"
                                  name={"on_behalf_id"}
                                  id={"on_behalf_id"}
                                  placeholder={"Select Member"}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {!isMobile && (
                      <button
                        type="submit"
                        className="btn bg-black text-white w-full py-2"
                        disabled={isBookingLoading}
                      >
                        Proceed For Payment
                      </button>
                    )}
                  </form>
                )}
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div class="card shadow mb-3">
                <div class={`card-body ${showCouponPage ? "mb--4" : "mb-0"}`}>
                  <div>
                    {showCouponPage ? (
                      <span
                        className="d-flex text-sm cursor-pointer text-blue align-items-center"
                        onClick={() => setShowCouponPage(false)}
                      >
                        Hide All Coupons
                        <FaAngleRight className="ms-2" />
                      </span>
                    ) : (
                      <span
                        className="d-flex text-sm cursor-pointer text-blue align-items-center"
                        onClick={() => setShowCouponPage(true)}
                      >
                        View All Coupons
                        <FaAngleRight className="ms-2" />
                      </span>
                    )}

                    <label htmlFor="coupon_code" className="d-block mt-2">
                      <strong style={{ fontSize: "0.85em" }} className="me-1">
                        Do you have a coupon code?
                      </strong>
                      <span className="text-gray-500">(Optional)</span>
                    </label>

                    <div className="d-flex mt-1">
                      <input
                        type="text"
                        id="coupon_code"
                        className="form-control bg-transparent text-black flex-grow-1"
                        value={selectedCoupon?.code || ""}
                        readOnly
                      />

                      {selectedCoupon && (
                        <button
                          type="button"
                          className="ml-2 btn btn-danger"
                          onClick={() => setSelectedCoupon(null)}
                        >
                          &times;
                        </button>
                      )}
                    </div>

                    {selectedCoupon && (
                      <p className="coupon-message">Hurray! Coupon Applied</p>
                    )}
                  </div>
                </div>
                {showCouponPage ? (
                  <CouponPage
                    setShowCouponPage={setShowCouponPage}
                    onCouponSelect={handleCouponSelect}
                    price={room.price.toFixed(2)}
                    userRole={userRole}
                  />
                ) : null}
              </div>
              <PriceTable room={room} selectedCoupon={selectedCoupon} />
              {isMobile && (
                showConfirmPage && (
                  <div className="payment-methods-container p-4 rounded shadow">
                    <h3 className="payment-title text-center mb-4">
                      Select Payment Method
                    </h3>

                    {!isMobile && userRole !== "User" ? (
                      <AdminPaymentDetails
                        roomId={room?.id}
                        room={bookInput}
                        coupon={selectedCoupon}
                        isMobile={isMobile}
                        handleCancelBook={handleCancelBook}
                      />
                    ) : (
                      <UserPaymentMode
                        orderId={orderId}
                        handleCancelBook={handleCancelBook}
                        coupon={selectedCoupon}
                        setShowConfirmPage={setShowConfirmPage}
                        isMobile={isMobile}
                        bookInput={bookInput}
                        room={room}
                      />
                    )}
                  </div>
             ) )}
            </div>
          </div>
          {(isMobile && !showConfirmPage)  &&  (
            <button
              type="submit"
              className="btn bg-black text-white w-full py-2"
              disabled={isBookingLoading}
              onClick={(e) => handleBook(e)}
            >
              Proceed For Payment
            </button>
          )}
        </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "User",
  userId: state.auth.user?.id || "",
  orderId: state.auth.orderId,
});

export default connect(mapStateToProps)(BookingPage);
