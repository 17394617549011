import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getOpenRoomList } from "../../../../services/RoomService";
import CalendarPopup from "../PopUpPage/CalendarPopUp";
import Loader from "../../../Loader/Loader";

const Rooms = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const recordsPage = 15;

  useEffect(() => {
    setIsLoading(true);
    getOpenRoomList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error.response ? error.response.data.message : error);
        setIsLoading(false);
      });
  }, [currentPage, searchQuery]);

  const handleExploreAll = () => {
    navigate("/club-rooms");
  };

  const handleShowDetails = (room) => {
    navigate(`/room-details/${room.id}`, { state: { room } });
  };

  const handleBookNow = (room) => {
      navigate(`/booking/${room.id}`, { state: { room } });
  };

  return (
    <div className="dark:bg-gray-900 dark:text-white bg-gray-50 py-6">
      <section data-aos="fade-up" className="container">
        <h1 className="mb-5 mt-3 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-bold">
          Rooms
        </h1>
        {isLoading ? (
          <div
            className="text-center my-9"
            style={{ width: "100%", height: "100%" }}
          >
            <Loader />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {data.slice(0, 6).map((item, index) => (
              <div
                key={index}
                className="shadow-lg transition-all duration-500 hover:shadow-xl bg-slate-200 dark:text-white cursor-pointer rounded-2xl overflow-hidden"
              >
                <div className="overflow-hidden">
                  <div style={{ height: "285px" }}>
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${item.roomphoto[0]?.photo}`}
                      alt={item.name}
                      onClick={() => handleShowDetails(item)}
                      className="mx-auto w-full object-cover transition duration-700 hover:skew-x-2 hover:scale-110 cursor-pointer mt-0"
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        height: "100%",
                      }}
                    />
                  </div>
                  <p
                    className="absolute my--6 bg-yellow w-52 text-center py-2 text-base text-black font-bold"
                    style={{ borderRadius: "0px 10px 10px 0px" }}
                  >
                    Rs.{item.price}
                  </p>
                </div>
                <div className="p-3 space-y-2">
                  <h1 className="font-bold text-lg line-clamp-1">
                    {item.name}
                  </h1>
                  <div className="flex space-x-3 text-sm text-gray-600">
                    <small className="border-r pr-3">
                      {item.room_slot_type}
                    </small>
                    <small className="border-r pr-3">
                      {item.room_type}
                    </small>
                  </div>
                  <p className="line-clamp-1 text-gray-600">
                    {item.description}
                  </p>
                  <div className="flex justify-between border-t-2 pt-3 mt-3">
                    <CalendarPopup
                      roomId={item ? item.id : null}
                      roomSlotType={item ? item.room_slot_type : null}
                    />
                    {user ? (
                      <div>
                        <button
                          className="text-white bg-black px-3 py-1 rounded duration-200"
                          onClick={() => handleBookNow(item)}
                        >
                          Book Now
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="text-white bg-black px-3 py-1 rounded duration-200"
                          onClick={() =>
                            navigate(
                              `/login?next_url=${`/room-details/${item?.id}`}`
                            )
                          }
                        >
                          Book Now
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="text-center mt-4">
          <button
            className="btn bg-black text-white px-4 py-2 rounded"
            onClick={handleExploreAll}
          >
            Explore All
          </button>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Rooms);
