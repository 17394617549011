import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="container footer py-2 bg-black text-white" style={{ maxWidth: "100%" }}>
        <div className="footer-content">
          <p className="mb-1">
            © Copyright
            <a href="http://intelisparkz.com/" target="_blank" rel="noreferrer" className="ms-1 me-1 text-blue-400 hover:text-blue-600 transition-colors duration-300">
              Intelisparkz
            </a>
            {currentYear}
            <span className="mx-2">|</span>
            All Rights Reserved.
          </p>
          <div className="footer-links">
            <span
              className="text-xs text-muted cursor-pointer hover:underline hover:text-blue-600 transition-all duration-300"
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </span>
            <span className="mx-2">|</span>
            <span
              className="text-xs text-muted cursor-pointer hover:underline hover:text-blue-600 transition-all duration-300"
              onClick={() => navigate("/refund-policy")}
            >
              Refund
            </span>
            <span className="mx-2">|</span>
            <span
              className="text-xs text-muted cursor-pointer hover:underline hover:text-blue-600 transition-all duration-300"
              onClick={() => navigate("/terms")}
            >
              Terms
            </span>
          </div>
        </div>
      </div>


    </>
  );
};

export default Footer;
