import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import useWindowSize from "../../constant/WindowSize";
import { HiMenuAlt1 } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { logoutAction } from "../../store/actions/AuthActions";
import LogoutModal from "../Logout/Logout";
import Notification from "../Public&User/Component/Notification/Notification";
import { getNotification } from "../../services/NotificationService";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const size = useWindowSize();
  const isMobile = size.width <= 768;
  const [notifications, setNotifications] = useState([]);


  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const toggleExitModal = () => setExitModalOpen(!exitModalOpen);

  function onLogout() {
    dispatch(logoutAction(navigate));
    toggleExitModal();
  }
  useEffect(() => {
    getNotification()
      .then((data) => {
        setNotifications(data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch notifications", error);
      });
  }, []);

  const createLinks = (routes, userRole) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            className="nav-link-custom mx-2"
          >
            <i className={prop.icon} />
            <span className={`text-dark nav-link-text ${isMobile ? "ms-4" : ""}`}>{prop.name}</span>
          </NavLink>
        </NavItem>
      );
    });
  };

  const { routes, logo, sidebarToggle } = props;
  let navbarBrandProps = {};
  if (logo) {
    if (logo.innerLink) {
      navbarBrandProps = { to: logo.innerLink, tag: Link };
    } else if (logo.outterLink) {
      navbarBrandProps = { href: logo.outterLink, target: "_blank" };
    }
  }

  return (
    <>
      {isMobile ? (
        <>
          <Navbar
            className="navbar-vertical fixed-top bg-black"
            style={{ padding: "1rem 0" }}
          >
            <Container
              fluid
              className="d-flex justify-content-between align-items-center "
            >
              {logo && (
                <NavbarBrand
                  {...navbarBrandProps}
                  className="d-flex align-items-center"
                >
                  <img
                    alt={logo.imgAlt}
                    className="navbar-brand-img me-1"
                    src={logo.imgSrc}
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: "50%",
                      width: "2rem",
                    }}
                  />
                  <span className="mt-1 fw-bolder text-white">
                    Rourkela CLUB
                  </span>
                </NavbarBrand>
              )}
              <div className="d-flex align-items-center">
                <div className="me-1">
                  <Notification notifications={notifications} updateNotifications={setNotifications} />
                </div>
                <div className="me-5">
                  <i
                    className="fa-solid fa-gear text-base rotate-icon cursor-pointer text-white"
                    onClick={() => navigate("/settings")}
                  ></i>
                </div>
                {collapseOpen ? (
                  <RxCross2
                    onClick={toggleCollapse}
                    className="cursor-pointer text-white transition-all"
                    size={30}
                  />
                ) : (
                  <HiMenuAlt1
                    onClick={toggleCollapse}
                    className="cursor-pointer text-white transition-all"
                    size={30}
                  />
                )}
              </div>
            </Container>
          </Navbar>
          <div
            className={`mobile-sidebar ${collapseOpen ? "open" : ""
              } responsive-menu-admin fixed bottom-0 transition-all duration-500 ease-in-out`}
          >
            <Nav navbar>{createLinks(routes)}</Nav>
            <div className="logout-container flex flex-col items-center mt-auto space-y-3">
              <button
                onClick={toggleExitModal}
                className="btn btn-sm text-danger  text-lg mx-auto mb-3"
                style={{ width: "90%" }}
              >
                <i className="fa-solid fa-power-off me-3"></i>
                <span>Logout</span>
              </button>
            </div>
          </div>
        </>
      ) : (
        <Navbar
          className={`navbar-vertical fixed-left ${sidebarToggle ? "collapsed" : ""
            }`}
          style={{
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            transition: "margin-left 0.5s ease",
            overflowX: "hidden",
          }}
          expand="md"
          id="sidenav-main"
        >
          <Container fluid>
            {logo && (
              <NavbarBrand
                className="pt-0 d-flex align-items-center"
                {...navbarBrandProps}
              >
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
                <h1 className="mt-1 fw-bolde text-dark">
                  <strong> Rourkela CLUB</strong>
                </h1>
              </NavbarBrand>
            )}
            <div className={`navbar-collapse ${collapseOpen ? "show" : ""}`}>
              <Nav className="mt--4" navbar>
                {createLinks(routes)}
              </Nav>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="text-xs text-mute cursor-pointer hover:underline hover:text-blue-600 transition-all duration-300" onClick={() => navigate("/privacy-policy")}>Privacy Policy</span>
              <span className="mx-2">|</span>
              <span className="text-xs text-mute cursor-pointer hover:underline hover:text-blue-600 transition-all duration-300" onClick={() => navigate("/refund-policy")}>Refund</span>
              <span className="mx-2">|</span>
              <span className="text-xs text-mute cursor-pointer hover:underline hover:text-blue-600 transition-all duration-300" onClick={() => navigate("/terms")}>Terms</span>

            </div>
          </Container>
        </Navbar>
      )}
      <LogoutModal
        show={exitModalOpen}
        onHide={toggleExitModal}
        onLogout={onLogout}
      />
    </>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
  userRole: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "Admin",
  sidebarToggle: state.auth.sidebarToggle,
});

export default connect(mapStateToProps)(Sidebar);
