import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, Row } from "reactstrap";
import { Bar } from "react-chartjs-2";
import { getMostRoomBookingGraph } from "../../services/DashBoardService";
import useWindowSize from "../../constant/WindowSize";
import classnames from "classnames";
import Loader from "../../components/Loader/Loader";

var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

const RoomTypeGraph = () => {
  const [dataset, setDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState("weekly"); 

  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  const toggleTimePeriod = (tp) => {
    setTimePeriod(tp);
  };

  useEffect(() => {
    setIsLoading(true);
    getMostRoomBookingGraph(timePeriod) 
      .then((resp) => {
        setDataSet(resp.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [timePeriod]); 

  const wrapLabel = (label, maxLength) => {
    const words = label.split(" ");
    let wrapped = "";
    let currentLine = "";
    for (let word of words) {
      if (currentLine.length + word.length > maxLength) {
        wrapped += currentLine + "\n";
        currentLine = "";
      }
      currentLine += word + " ";
    }
    return wrapped + currentLine.trim();
  };

  const data = {
    labels: dataset.map((item) => wrapLabel(item.room_name, 10)),
    datasets: [
      {
        label: "Bookings",
        data: dataset.map((item) => item.booking_count),
        backgroundColor: "#007bff", 
        borderColor: "#0056b3", 
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: "top",
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return `${tooltipItem.xLabel}: ${tooltipItem.yLabel} bookings`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: isMobile ? false : true,
            labelString: "Room Name",
          },
          ticks: {
            autoSkip: false,
            maxRotation: isMobile ? 20 : 0,
            callback: function (value) {
              return value.split("\n");
            },
          },
          barThickness: isMobile ? 15 : 40,
          maxBarThickness: isMobile ? 15 : 40,
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: isMobile ? false : true,
            labelString: "Number of Bookings",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <Card className="shadow">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0">
              <strong>Most Booking Rooms</strong>
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", {
                    active: timePeriod === "yearly",
                  })}
                  style={{
                    backgroundColor: timePeriod === "yearly" ? colors.theme.primary : "transparent",
                    color: timePeriod === "yearly" ? "#fff" : colors.theme.default,
                  }}
                  onClick={() => toggleTimePeriod("yearly")}
                >
                  <span className="text-dark">Y</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", {
                    active: timePeriod === "monthly",
                  })}
                  style={{
                    backgroundColor: timePeriod === "monthly" ? colors.theme.primary : "transparent",
                    color: timePeriod === "monthly" ? "#fff" : colors.theme.default,
                  }}
                  onClick={() => toggleTimePeriod("monthly")}
                >
                  <span className="text-dark">M</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", {
                    active: timePeriod === "weekly",
                  })}
                  style={{
                    backgroundColor: timePeriod === "weekly" ? colors.theme.primary : "transparent",
                    color: timePeriod === "weekly" ? "#fff" : colors.theme.default,
                  }}
                  onClick={() => toggleTimePeriod("weekly")}
                >
                  <span className="text-dark">W</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      {isLoading ? (
                      <div
                      className="text-center my-7"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <Loader />
                    </div>
      ) : (
        <CardBody className="p-sm-4 p-0" style={{ position: "relative", height: isMobile ? "300px" : "380px" }}>
          <Bar data={data} options={options} />
        </CardBody>
      )}
    </Card>
  );
};

export default RoomTypeGraph;
