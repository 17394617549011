import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../../constant/Pagination";
import { getMemberListAPI } from "../../services/VerifyMemberService";
import "../Member/memberCustom.css";
import AddMemberPopup from "./AddMemberPopup";
import EditMemberPopup from "./EditMemberPopup";
import VerifyMemberPopup from "./VerifyMemberPopup";
import useWindowSize from "../../constant/WindowSize";
import UserDetailsPage from "./UserDetails";
import { LuArrowUpDown } from "react-icons/lu";
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";
import Loader from "../../components/Loader/Loader";
import TableNoResultFound from "../../constant/NoResultFound/TableNoResultFound";
import "./memberCustom.css";
import { BsListColumns } from "react-icons/bs";

const recordsPage = 15;

const MemberListPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState("ALL");
  const [data, setData] = useState([]);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userData, setUserData] = useState(null);
  const [sortBy, setSortBy] = useState("-created_at");
  const [expandedRows, setExpandedRows] = useState([]);
  const size = useWindowSize();
  const isMobile = size.width <= 1024;
  const navigate = useNavigate();
  const location = useLocation();
  const initialVisibleHeaders = [
    "Member",
    "Membership ID",
    "Membership Type",
  ];

  const headers = [
    {
      label: "Member",
      accessor: (item) => (
        <>
          <div
            className="d-flex align-items-center"
            onClick={() => handleProfilePhotoClick(item)}
            style={{ cursor: "pointer" }}
          >
            {item.profile_photo ? (
              <img
                alt="Profile"
                className="avatar avatar-sm rounded-circle"
                src={`${process.env.REACT_APP_BASE_URL}${item.profile_photo}`}
              />
            ) : (
              <span className="text-avatar">
                {item.first_name.slice(0, 1)}
              </span>
            )}
            <div className="ms-2">
              <span
                style={{
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {item.first_name} {item.last_name}
              </span>

              <br />
              <span>{item.email}</span>
            </div>
          </div>
        </>
      ),
      sortKey: "first_name",
    },
    {
      label: "Membership ID",
      accessor: (item) => item?.membership_id,
      sortKey: "membership_id",
    },
    {
      label: "Membership Type",
      accessor: (item) => item?.membership_type,
      sortKey: "membership_type",
    },
    {
      label: "Phone NO.",
      accessor: (item) => item?.phone_number,
      sortKey: "phone_number",
    },
    {
      label: "Date",
      accessor: (item) => item?.created_at,
      sortKey: "created_at",
    },
  ];

  const initialColumns = headers.filter((header) =>
    initialVisibleHeaders.includes(header.label)
  );
  const additionalColumns = headers.filter(
    (header) => !initialVisibleHeaders.includes(header.label)
  );

  const [visibleHeaders, setVisibleHeaders] = useState(initialColumns);
  const [selectedColumns, setSelectedColumns] = useState(
    () => new Set(initialColumns.map((column) => column.label))
  );
  const [columnOrder, setColumnOrder] = useState(
    visibleHeaders.map((header) => header.sortKey)
  );
  const [showModal, setShowModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleColumnSelection = (label) => {
    const updatedColumns = new Set(selectedColumns);

    if (updatedColumns.has(label)) {
      updatedColumns.delete(label);
    } else {
      updatedColumns.add(label);
    }

    setSelectedColumns(updatedColumns);
    setSelectAll(updatedColumns.size === headers.length);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedColumns(new Set());
    } else {
      setSelectedColumns(new Set(headers.map((header) => header.label)));
    }
    setSelectAll(!selectAll);
  };

  const handleApplyColumns = () => {
    const updatedVisibleHeaders = headers.filter((header) =>
      selectedColumns.has(header.label)
    );
    setVisibleHeaders(updatedVisibleHeaders);
    setColumnOrder(updatedVisibleHeaders.map((header) => header.sortKey));
    setShowModal(false);
  };

  const renderColumnOptions = (columns) => {
    return columns.map((header, index) => (
      <div
        key={index}
        className="table-component__column-option"
        onClick={() => handleColumnSelection(header.label)}
      >
        <label className="table-component__checkbox-label">
          <input
            type="checkbox"
            className="table-component__checkbox"
            checked={selectedColumns.has(header.label)}
            onChange={() => handleColumnSelection(header.label)}
          />
          {header.label}
        </label>
        {selectedColumns.has(header.label) && (
          <span
            className="cancel-icon"
            onClick={(e) => {
              e.stopPropagation(); // Prevents event bubbling to parent div
              handleColumnSelection(header.label);
            }}
          >
            ×
          </span>
        )}
      </div>
    ));
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const fromIndex = e.dataTransfer.getData("text/plain");
    const newOrder = [...columnOrder];
    const [movedItem] = newOrder.splice(fromIndex, 1);
    newOrder.splice(index, 0, movedItem);
    setColumnOrder(newOrder);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };


  useEffect(() => {
    if (location.state && location.state.membershipId) {
      setSearchQuery(location.state.membershipId);
    } 
  }, [location.state]);


  useEffect(() => {
    setIsLoading(true);
    getMemberListAPI(
      currentPage,
      recordsPage,
      searchQuery || "", 
      filterStatus,
      sortBy
    )
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [currentPage, searchQuery, filterStatus, sortBy]);
  useEffect(() => {
    setSearchQuery(null); 
    navigate("/member");
  }, []);


  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const handleRowExpandToggle = (index) => {
    let newExpandedRows = [...expandedRows];
    if (newExpandedRows.includes(index)) {
      newExpandedRows = newExpandedRows.filter(
        (rowIndex) => rowIndex !== index
      );
    } else {
      newExpandedRows.push(index);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilter = (status) => {
    setFilterStatus(status);
    setCurrentPage(1);
  };

  const handleEdit = (member) => {
    setCurrentMember(member);
    setShowEditPopup(true);
  };

  const handleProfilePhotoClick = (member) => {
    setUserData(member);
    setShowUserDetails(true);
  };

  const handleGoBack = () => {
    setShowUserDetails(false);
  };

  if (showUserDetails) {
    return <UserDetailsPage userData={userData} handleGoBack={handleGoBack} />;
  }

  const handleSort = (key) => {
    const sorbtbysplit = sortBy.split("-");
    const isAsc = sorbtbysplit.length > 1;
    const sortByValue = isAsc ? sorbtbysplit[1] : sortBy;
    if (sortByValue === key) {
      setSortBy(isAsc ? key : `-${key}`);
    } else {
      setSortBy(key);
    }
  };

  const renderSortIcon = (key) => {
    const sorbtbysplit = sortBy.split("-");
    const isAsc = sorbtbysplit.length > 1;
    const sortByValue = isAsc ? sorbtbysplit[1] : sortBy;
    if (sortByValue === key) {
      return isAsc ? <HiArrowNarrowUp /> : <HiArrowNarrowDown />;
    }
    return (
      <>
        <LuArrowUpDown />
      </>
    );
  };
  return (
    <>
      <Header />
      <Container
        className="Container mt-sm-0 mt-7 mb-sm-0 mb-7 p-sm-2 p-1"
        fluid
      >
        <div
          className="d-flex align-items-center justify-content-between container max"
          style={{ maxWidth: "100vw" }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h3
              className="text-dark fw-bold pb-2 me-3"
              style={{ fontSize: "15px" }}
            >
              <i className="fas fa-users me-2 text-black"></i>
              New Members
            </h3>

          </div>
          <AddMemberPopup data={data} setData={setData} />
        </div>
        <Row>
          <div className="col newCol">
            <Card className="shadow my-4">
              <CardHeader className="d-flex justify-content-between align-items-center flex-wrap border-0">
                <div
                  className="btn-group custom-btn-group mb-sm-0 mb-3"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    onClick={() => handleFilter("ALL")}
                    className={
                      filterStatus === "ALL"
                        ? "btn btn-dark"
                        : "btn btn-outline-dark"
                    }
                  >
                    ALL
                  </button>
                  <button
                    onClick={() => handleFilter("PENDING")}
                    className={
                      filterStatus === "PENDING"
                        ? "btn btn-dark"
                        : "btn btn-outline-dark"
                    }
                  >
                    PENDING
                  </button>
                  <button
                    onClick={() => handleFilter("VERIFIED")}
                    className={
                      filterStatus === "VERIFIED"
                        ? "btn btn-dark"
                        : "btn btn-outline-dark"
                    }
                  >
                    VERIFIED
                  </button>
                </div>
                <div className="d-flex align-items-center">
                  {!isMobile && (
                    <div className="me-3">
                      <button
                        className="btn btn-info"
                        onClick={() => setShowModal(true)}
                      >
                        <BsListColumns style={{ fontSize: "1.4rem" }} />
                      </button>
                      <Modal
                        className="table-component__modal"
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        size="lg"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Select Columns</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="table-component__column-options">
                            <div className="table-component__select-all">
                              <label className="table-component__checkbox-label">
                                <input
                                  type="checkbox"
                                  className="me-3"
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                                Select All
                              </label>
                            </div>
                            {renderColumnOptions(initialColumns)}
                            {renderColumnOptions(additionalColumns)}
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            className="table-component__close-btn"
                            onClick={() => setShowModal(false)}
                          >
                            Close
                          </button>
                          <button
                            className="table-component__apply-btn"
                            onClick={handleApplyColumns}
                          >
                            Apply
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  )}

                  <div className="ms-auto member-search">
                    <div className="search-bar">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <i className="fas fa-search search-icon"></i>
                    </div>
                  </div>
                </div>

              </CardHeader>
              {isMobile ? (
                <>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light disable-select">
                      <tr style={{ fontSize: "1rem" }}>
                        <th
                          scope="col"
                          className="py-3"
                          onClick={() => handleSort("first_name")}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Member
                            <span className="ms-1">
                              {renderSortIcon("first_name")}
                            </span>
                          </div>
                        </th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="3" className="text-center my-7">
                            <Loader />
                          </td>
                        </tr>
                      ) : data ? (
                        data.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td className="responsive-table">
                                <div
                                  className="d-flex align-items-center"
                                  onClick={() => handleProfilePhotoClick(item)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {item.profile_photo ? (
                                    <img
                                      alt="Profile"
                                      className="avatar avatar-sm rounded-circle"
                                      src={`${process.env.REACT_APP_BASE_URL}${item.profile_photo}`}
                                    />
                                  ) : (
                                    <span className="text-avatar">
                                      {item.first_name.slice(0, 1)}
                                    </span>
                                  )}
                                  <div className="ms-2">
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      {item.first_name} {item.last_name}
                                    </span>

                                    <br />
                                    <span>{item.email}</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  onClick={() => handleRowExpandToggle(index)}
                                >
                                  More...
                                </Button>
                              </td>
                            </tr>
                            {expandedRows.includes(index) && (
                              <tr>
                                <td colSpan="3">
                                  <div>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div
                                        style={{
                                          marginBottom: "10px",
                                          color: "#555",
                                        }}
                                      >
                                        <strong style={{ color: "#333" }}>
                                          Membership ID:{" "}
                                        </strong>
                                        {item.membership_id}
                                      </div>
                                      <i
                                        className="fa-sharp editIcon fa-regular fa-pen-to-square"
                                        onClick={() => handleEdit(item)}
                                      ></i>
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: "10px",
                                        color: "#555",
                                      }}
                                    >
                                      <strong style={{ color: "#333" }}>
                                        Membership Type:{" "}
                                      </strong>
                                      {item.membership_type}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: "10px",
                                        color: "#555",
                                      }}
                                    >
                                      <strong style={{ color: "#333" }}>
                                        Phone NO.:{" "}
                                      </strong>
                                      {item.phone_number}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: "10px",
                                        color: "#555",
                                      }}
                                    >
                                      <strong style={{ color: "#333" }}>
                                        Date:{" "}
                                      </strong>
                                      {item.created_at}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: "10px",
                                        color: "#555",
                                      }}
                                      className="d-flex align-items-center"
                                    >
                                      <strong
                                        className="me-3"
                                        style={{ color: "#333" }}
                                      >
                                        Status:{" "}
                                      </strong>
                                      <VerifyMemberPopup
                                        member={item}
                                        data={data}
                                        setData={setData}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <TableNoResultFound />
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <Pagination
                      currentPage={currentPage}
                      recordsPage={recordsPage}
                      dataLength={total}
                      handlePagination={handlePagination}
                    />
                  </CardFooter>
                </>
              ) : (
                <>

                  <Table
                    className="table-component__table align-items-center table-flush"
                    responsive
                  >
                    <thead className="thead-light disable-select">
                      <tr style={{ fontSize: "1rem" }}>
                        {columnOrder.map((sortKey, index) => {
                          const header = visibleHeaders.find(
                            (h) => h.sortKey === sortKey
                          );
                          return (
                            <th
                              key={index}
                              scope="col"
                              className="py-3"
                              onClick={() => handleSort(header.sortKey)}
                              draggable
                              onDragStart={(e) => handleDragStart(e, index)}
                              onDrop={(e) => handleDrop(e, index)}
                              onDragOver={handleDragOver}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {header.label}
                                <span className="ms-1">
                                  {renderSortIcon(header.sortKey)}
                                </span>
                              </div>
                            </th>
                          );
                        })}
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td
                            colSpan={columnOrder.length}
                            className="text-center my-7"
                          >
                            <div
                              className="text-center my-7"
                              style={{ width: "100%", height: "100%" }}
                            >
                              <div
                                className="spinner-grow text-black me-2"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : data ? (
                        data.map((item, index) => (
                          <tr key={index}>
                            {columnOrder.map((sortKey, i) => {
                              const header = visibleHeaders.find(
                                (h) => h.sortKey === sortKey
                              );
                              return <td key={i}>{header.accessor(item)}</td>;
                            })}
                            <td>
                              <VerifyMemberPopup
                                member={item}
                                data={data}
                                setData={setData}
                              />
                            </td>
                            <td>
                              <i
                                className="fa-sharp editIcon fa-regular fa-pen-to-square"
                                onClick={() => handleEdit(item)}
                              ></i>
                            </td>
                          </tr>
                        ))
                      ) : <TableNoResultFound />}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <Pagination
                      currentPage={currentPage}
                      recordsPage={recordsPage}
                      dataLength={total}
                      handlePagination={handlePagination}
                    />
                  </CardFooter>
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      {showEditPopup && (
        <EditMemberPopup
          member={currentMember}
          data={data}
          setData={setData}
          onClose={() => setShowEditPopup(false)}
        />
      )}
    </>
  );
};

export default MemberListPage;
