import React, { useState } from "react";
import { toast } from "react-toastify";
import TermsOfServiceModal from "./TOSModal"
import { Link, useNavigate } from "react-router-dom";
import { signupApi } from "../../services/AuthService";
import useWindowSize from "../../constant/WindowSize";
import Logo from "../../assets/img/brand/logo.png"
import "./Login.css";

const INPUT_FIELD_LIST = [
  {
    label: "First Name",
    name: "first_name",
    type: "text",
    placeholder: "Enter your first name",
  },
  {
    label: "Last Name",
    name: "last_name",
    type: "text",
    placeholder: "Enter your last name",
  },
  {
    label: "Membership ID",
    name: "membership_id",
    type: "text",
    placeholder: "Enter membership ID",
  },
  {
    label: "Email ID",
    name: "email",
    type: "email",
    placeholder: "Enter your Email ID",
  },
  {
    label: "Phone Number",
    name: "phone_number",
    type: "tel",
    placeholder: "Enter your Phone No.",
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    placeholder: "Enter your password",
  },
];

const initialFormState = {
  first_name: "",
  last_name: "",
  membership_id: "",
  email: "",
  phone_number: "",
  password: "",
};

function Register() {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialFormState);
  const navigate = useNavigate();
  const size = useWindowSize();
  const isMobile = size.width <= 768;
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleValidate = (name, value) => {
    if (name === "email") {
      if (!value) {
        return "Email is required";
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        return "Invalid Email format.";
      }
    } else if (name === "phone_number") {
      if (!value) {
        return "Phone number is required";
      } else if (!/^\d{10}$/.test(value)) {
        return "Invalid phone number.";
      }
    } else if (name === "password") {
      if (!value) {
        return "Password is required";
      }
    } else {
      if (!value) {
        const errorMsgObj = {
          first_name: "First name",
          last_name: "Last name",
          membership_id: "Membership Id",
        };
        return `${errorMsgObj[name]} is required`;
      }
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: handleValidate(name, value) });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSubmitLoading) {
      return;
    }

    let formErrors = {};
    let isValid = true;


    INPUT_FIELD_LIST.forEach(({ name }) => {
      const error = handleValidate(name, formData[name]);
      formErrors[name] = error;
      if (error) {
        isValid = false;
      }
    });

    if (!isValid) {
      setErrors(formErrors);
      return;
    }

    setIsSubmitLoading(true);
    signupApi(formData)
      .then((resp) => {
        const { results, message } = resp.data;
        toast.success(message);
        navigate("/login");
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        console.log(error);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  return (
    <>
      {isMobile ? (
        <div className="mobile-login-signup">
          <div className="background"></div>
          <div
            className="content"
            style={{ width: "95%" }}
          >
            <div>
              {" "}
              <Link to="/" className="text-white">
                <i className="fa-solid fa-house ms-1"></i>
              </Link>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={Logo}
                alt="Logo"
                className="logo"
                style={{
                  width: "70px",
                  height: "70px",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div className="club-title-container d-flex align-items-center justify-content-center">
              <h1
                className="club-title"
                style={{
                  fontFamily: "'Raleway', sans-serif",
                  fontSize: "2em",
                  color: "white",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                  margin: "0",
                }}
              >
                Rourkela Club
              </h1>
            </div>
            <small
              className="mb-3 d-flex justify-content-center"
              style={{ fontSize: "0.8em", color: "white" }}
            >
              Sign Up with credentials
            </small>

            <div className="formTitle">
              <Link to="/login" className="formTitleLink">
                Sign In
              </Link>
              <span className="me-3">or</span>
              <Link to="/signup" className="formTitleLink-active">
                Sign Up
              </Link>
            </div>

            <div className="formCenter">
              <form className="formFields" onSubmit={handleSubmit}>
                {INPUT_FIELD_LIST.map(({ label, name, type, placeholder }) => (
                  <div className="formField row mb-3" key={name}>
                    <label className="formFieldLabel col-4" htmlFor={name}>
                      {label}:
                    </label>

                    <div className="signUpInput-group col-8">
                      <input
                        type={type}
                        name={name}
                        value={formData[name]}
                        className="formFieldInput"
                        onChange={handleChange}
                        placeholder={placeholder}
                        autoComplete="false"
                      />
                      {errors[name] && (
                        <div className="form-error">{errors[name]}</div>
                      )}
                    </div>
                  </div>
                ))}
                <div>
                  <label className="formFieldCheckboxLabel mt-2">
                    <input
                      className="formFieldCheckbox"
                      type="checkbox"
                      name="hasAgreed"
                    />{" "}
                    I agree to all{" "}
                    <a href="#" className="formFieldTermsLink" onClick={handleShow}>
                      terms of service
                    </a>
                  </label>

                  <TermsOfServiceModal show={showModal} handleClose={handleClose} />
                </div>
                <button className="formFieldButton w-100 mx-1 mb-3">Sign Up</button>{" "}
                <Link
                  to="/login"
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    color: "#ffffffcf"
                  }}
                >
                  <span style={{ borderBottom: "1.5px solid #6b5b95" }}>
                    I'm already a member
                  </span>
                </Link>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="login-signup row">
          <div className="col-7 bg-image"></div>

          <div className="col-5 appForm">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={Logo}
                alt="Logo"
                className="logo"
                style={{
                  width: "70px",
                  height: "70px",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div className="club-title-container d-flex align-items-center justify-content-center">
              <h1
                className="club-title"
                style={{
                  fontFamily: "'Raleway', sans-serif",
                  fontSize: "2em",
                  color: "white",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                  margin: "0",
                }}
              >
                Rourkela Club
              </h1>
            </div>
            <small
              className="mb-3"
              style={{ fontSize: "0.8em", color: "white" }}
            >
              Sign Up with credentials
            </small>

            <div className="formTitle">
              <Link to="/login" className="formTitleLink">
                Sign In
              </Link>
              <span className="me-3">or</span>
              <Link to="/signup" className="formTitleLink-active">
                Sign Up
              </Link>
            </div>

            <div className="formCenter">
              <form className="formFields" onSubmit={handleSubmit}>
                {INPUT_FIELD_LIST.map(({ label, name, type, placeholder }) => (
                  <div className="formField row mb-3" key={name}>
                    <label className="formFieldLabel col-5" htmlFor={name}>
                      {label}:
                    </label>

                    <div className="signUpInput-group col-7">
                      <input
                        type={type}
                        name={name}
                        value={formData[name]}
                        className="formFieldInput"
                        onChange={handleChange}
                        placeholder={placeholder}
                        autoComplete="false"
                      />
                      {errors[name] && (
                        <div className="form-error">{errors[name]}</div>
                      )}
                    </div>
                  </div>
                ))}

                <div>
                  <label className="formFieldCheckboxLabel mt-2">
                    <input
                      className="formFieldCheckbox"
                      type="checkbox"
                      name="hasAgreed"
                    />{" "}
                    <span className="text-white">I agree to all{" "}</span>
                    <a href="#" className="formFieldTermsLink" onClick={handleShow}>
                      terms of service
                    </a>
                  </label>

                  <TermsOfServiceModal show={showModal} handleClose={handleClose} />
                </div>
                <div className="formField">
                  <button className="formFieldButton me-4">Sign Up</button>{" "}
                  <Link to="/login" className="formFieldLink" style={{
                    color: "#ffffffcf"
                  }}>
                    I'm already a member
                  </Link>
                </div>
                <Link to="/" className="d-flex justify-content-center">
                  Back to Home <i className="fa-solid fa-house ms-2 my-1"></i>
                </Link>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Register;
