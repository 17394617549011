import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../components/Headers/Header.js";

const PrivacyPolicy = () => {
    return (
        <>
            <Header />
            <Container className="Container mt-sm-0 mt-5 mb-sm-0 mb-7 p-sm-2 p-1" fluid>
                <div className="text-center mb-3">
                    <div className="d-flex align-items-center justify-content-center text-lg">
                        <img
                            className="me-3 header-logo h-10"
                            alt="..."
                            src={require("../../assets/img/brand/logo.png")}
                        />
                        <h1 className="m-0 text-uppercase">
                            <strong>Rourkela CLUB</strong>
                        </h1>
                    </div>
                    <h1 className="text-lg">Privacy Policy</h1>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>1. Introduction</strong></h2>
                    <p>At Rourkela Club, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you use our website and services.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>2. Information We Collect</strong></h2>
                    <h3>2.1 Personal Information</h3>
                    <p>We collect personal information you provide to us, such as your name, email address, phone number, and payment details, when you register or make a purchase.</p>

                    <h3>2.2 Automatically Collected Information</h3>
                    <p>We automatically collect information such as your IP address, browser type, and operating system when you visit our site to improve your experience and service delivery.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>3. How We Use Your Information</strong></h2>
                    <h3>3.1 Providing Services</h3>
                    <p>We use the information we collect to provide, maintain, and improve our services, process transactions, and communicate with you.</p>

                    <h3>3.2 Personalization</h3>
                    <p>We may use your information to personalize your experience on our website and deliver relevant content and advertisements.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>4. Sharing Your Information</strong></h2>
                    <h3>4.1 Third-Party Service Providers</h3>
                    <p>We may share your information with third-party service providers who assist us in providing services, such as payment processors or hosting services. They are required to protect your information and use it only for the purposes for which we disclose it.</p>

                    <h3>4.2 Legal Obligations</h3>
                    <p>We may disclose your information if required by law or in response to valid requests by public authorities.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>5. Data Security</strong></h2>
                    <h3>5.1 Security Measures</h3>
                    <p>We implement industry-standard security measures to protect your data. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.</p>

                    <h3>5.2 Your Responsibility</h3>
                    <p>You are responsible for maintaining the confidentiality of your account information, such as your password, and for restricting access to your devices.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>6. Your Data Rights</strong></h2>
                    <h3>6.1 Access and Correction</h3>
                    <p>You have the right to request access to your personal information and to request that we correct or delete any inaccuracies.</p>

                    <h3>6.2 Opt-Out</h3>
                    <p>You may opt-out of receiving marketing communications from us by following the unsubscribe instructions in our emails.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>7. Cookies and Tracking</strong></h2>
                    <h3>7.1 Cookies</h3>
                    <p>We use cookies and similar technologies to collect information about your browsing behavior to improve our services and provide a personalized experience. You can manage your cookie preferences through your browser settings.</p>
                </div>

                <div className="section px-4 py-2">
                    <h2 className="section-heading"><strong>8. Changes to Privacy Policy</strong></h2>
                    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Effective Date."</p>
                </div>

                <div class="section contact-info px-4 py-2">
                    <h2><strong>6. Contact Information</strong></h2>
                    <p>For any queries or concerns regarding refunds, please contact:</p>
                    <p><strong>Email:</strong> <a href="mailto:booking@rourkelaclub.org">booking@rourkelaclub.org</a></p>
                    <p><strong>Phone:</strong> [98XXXXXXX]</p>
                </div>
            </Container>
        </>
    );
};

export default PrivacyPolicy;
