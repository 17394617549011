import { login, logoutApi, whoami } from "../../services/AuthService";

export const AUTH_CHECK_CONFIRMED_ACTION = "[Auth Action] auth check confirmed";
export const AUTH_CHECK_FAILED_CONFIRMED_ACTION =
  "[Auth Action] auth check failed confirmed";
export const LOGIN_CONFIRMED_ACTION = "[Auth Action] confirmed login";
export const LOGIN_FAILED_ACTION = "[Auth Action] failed login";
export const LOADING_TOGGLE_ACTION = "[Auth Action] toggle loading";
export const LOGOUT_ACTION = "[Auth Action] logout action";
export const UPDATE_PROFILE = "UPDATE PROFILE";
export const UPDATE_ORDERID = "UPDATE ORDERID";
export const UPDATE_LAST_ACTIVITY_TIME = "UPDATE LAST ACTIVITY TIME";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const RESET_STORE = "RESET_STORE";
export const NAVTOGGLE = "NAVTOGGLE";
const IS_HEADER_TOKEN = process.env.REACT_APP_HEADER_TOKEN

export function checkAutoLoginAction(navigate) {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    if (!user) {
      dispatch(authCheckConfirmAction(true));
      whoami()
        .then((response) => {
          const { data } = response.data;
          dispatch(loginConfirmedAction(data));
        })
        .catch((error) => {
          dispatch(authCheckFailedConfirmAction());
          // navigate('/');
        });
    }
  };
}

export function logoutAction(navigate) {
  console.log("logout");
  return (dispatch) => {
    logoutApi()
      .then((response) => {
        dispatch({ type: LOGOUT_ACTION });
        dispatch({ type: RESET_STORE });
        navigate("/");
        if(IS_HEADER_TOKEN === "LOCALSTORAGE"){
          localStorage.removeItem("TOKEN")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function loginAction(email, password, navigate, nextUrl = null) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        const { data, token } = response.data;
        dispatch(loginConfirmedAction({ ...data }));
        let url;

        if (data.role === "Admin" || data.role === "SuperAdmin") {
          url = "/dashboard";
        } else if (data.role === "User" && nextUrl) {
          url = nextUrl;
        } else {
          url = "/";
        }

        navigate(url);
        if (IS_HEADER_TOKEN === "LOCALSTORAGE") {
          localStorage.setItem("TOKEN", token);
        }
      })
      .catch((error) => {
        dispatch(loginFailedAction(error.response.data.message));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function authCheckConfirmAction(payload) {
  return {
    type: AUTH_CHECK_CONFIRMED_ACTION,
    payload,
  };
}

export function authCheckFailedConfirmAction() {
  return { type: AUTH_CHECK_FAILED_CONFIRMED_ACTION };
}

export function updateLastActivityTimeAction() {
  return { type: UPDATE_LAST_ACTIVITY_TIME };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function updateProfileAction(data) {
  return {
    type: UPDATE_PROFILE,
    payload: data,
  };
}

export function updateOrderId(order_id) {
  return {
    type: UPDATE_ORDERID,
    payload: order_id,
  };
}

export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR,
  };
}

export function resetStore() {
  return {
    type: RESET_STORE,
  };
}

