import React from 'react'
import Header from '../Headers/Header'
import { Container } from "react-bootstrap";
import Coupon from './Coupon';

function Settings() {
  return (
    <>
    <Header />
      <Container className="mt-sm-0 mt-6 p-2" fluid>
      <Coupon />
      </Container>
      </>
  )
}

export default Settings