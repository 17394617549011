import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCurrentBookings } from "../../services/DashBoardService.js";
import { Card, Table, Container, Row, CardFooter } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { format, getMonth, getYear } from "date-fns";
import Header from "../../components/Headers/Header.js";
import Pagination from "../../constant/Pagination.jsx";
import { getBookingStatus } from "../../services/RoomService.js";
import { OverlayTrigger, Tooltip, Button, Modal } from "react-bootstrap";
import EditBookingModal from "./EditBooking.js";
import { LuArrowUpDown } from "react-icons/lu";
import useWindowSize from "../../constant/WindowSize.jsx";
import ApproveBookingModal from "./ApproveBooking.js";
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";
import ConfirmBookingModal from "./ConfirmBooking.js";
import DownloadExcel from "./DownloadExcel.js";
import "./BookingStatus.css";
import { FaTimes } from "react-icons/fa";
import Loader from "../../components/Loader/Loader.js";
import TableNoResultFound from "../../constant/NoResultFound/TableNoResultFound.js";
import UserRefundRequest from "./UserRefundRequest.js";
import { BsListColumns } from "react-icons/bs";
import RoomTypeDropDown, { StatusTypeDropDown } from "./DropDown.js";
const recordsPage = 15;

const BookingStatus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState({
    room_type: "",
    start_date: "",
    end_date: "",
  });
  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = useState("-created_at");
  const [expandedRows, setExpandedRows] = useState([]);
  const [filterStatus, setFilterStatus] = useState(null);
  const [bookingStatusType, setBookingStatusType] = useState("");
  const navigate = useNavigate();

  const location = useLocation();
  const fetchBookings = location.state?.fetchBookings;
  const size = useWindowSize();
  const isMobile = size.width <= 1024;
  const initialVisibleHeaders = [
    "Booking ID",
    "Room Name",
    "Member Name",
    "Booking Date",
 ];

  const headers = [
    {
      label: "Booking ID",
      accessor: (item) => item?.booking_number,
      sortKey: "booking_number",
      width: 15,
    },
    {
      label: "Room Name",
      accessor: (item) => item?.booking_slot?.room?.name,
      sortKey: "booking_slot__room__name",
      width: 20,
    },
    {
      label: "Booking Date",
      accessor: (item) => (
        <>
          <ApproveBookingModal
            booking={item}
            updateBookingData={updateBookingData}
          />{" "}
        </>
      ),
      sortKey: "booking_slot__booking_date",
      width: 20,
    },

    {
      label: "Booking Time",
      accessor: (item) => item?.booking_slot?.booking_time,
      sortKey: "booking_slot__booking_time",
      width: 20,
    },
    {
      label: "Member Name",
      accessor: (item) => (
        <>
          {item?.user?.first_name} {item?.user?.last_name}
          {item.is_on_behalf_booking && (
            <div style={{ fontSize: "0.6rem", color: "gray" }}>
              (Booked By Super Admin)
            </div>
          )}
        </>
      ),
      sortKey: "user__first_name",
      width: 25,
    },
    {
      label: "Mobile No.",
      accessor: (item) => item?.user?.phone_number,
      sortKey: "user__phone_number",
      width: 20,
    },
    {
      label: "Date of Booking",
      accessor: (item) => item?.created_at,
      sortKey: "created_at",
      width: 20,
    },
  ];

  const initialColumns = headers.filter((header) =>
    initialVisibleHeaders.includes(header.label)
  );
  const additionalColumns = headers.filter(
    (header) => !initialVisibleHeaders.includes(header.label)
  );

  const [visibleHeaders, setVisibleHeaders] = useState(initialColumns);
  const [selectedColumns, setSelectedColumns] = useState(
    () => new Set(initialColumns.map((column) => column.label))
  );
  const [columnOrder, setColumnOrder] = useState(
    visibleHeaders.map((header) => header.sortKey)
  );
  const [showModal, setShowModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleColumnSelection = (label) => {
    const updatedColumns = new Set(selectedColumns);

    if (updatedColumns.has(label)) {
      updatedColumns.delete(label);
    } else {
      updatedColumns.add(label);
    }

    setSelectedColumns(updatedColumns);
    setSelectAll(updatedColumns.size === headers.length);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedColumns(new Set());
    } else {
      setSelectedColumns(new Set(headers.map((header) => header.label)));
    }
    setSelectAll(!selectAll);
  };

  const handleApplyColumns = () => {
    const updatedVisibleHeaders = headers.filter((header) =>
      selectedColumns.has(header.label)
    );
    setVisibleHeaders(updatedVisibleHeaders);
    setColumnOrder(updatedVisibleHeaders.map((header) => header.sortKey));
    setShowModal(false);
  };

  const renderColumnOptions = (columns) => {
    return columns.map((header, index) => (
      <div
        key={index}
        className="table-component__column-option"
        onClick={() => handleColumnSelection(header.label)}
      >
        <label className="table-component__checkbox-label">
          <input
            type="checkbox"
            className="table-component__checkbox"
            checked={selectedColumns.has(header.label)}
            onChange={() => handleColumnSelection(header.label)}
          />
          {header.label}
        </label>
        {selectedColumns.has(header.label) && (
          <span
            className="cancel-icon"
            onClick={(e) => {
              e.stopPropagation();
              handleColumnSelection(header.label);
            }}
          >
            ×
          </span>
        )}
      </div>
    ));
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const fromIndex = e.dataTransfer.getData("text/plain");
    const newOrder = [...columnOrder];
    const [movedItem] = newOrder.splice(fromIndex, 1);
    newOrder.splice(index, 0, movedItem);
    setColumnOrder(newOrder);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRowExpandToggle = (index) => {
    let newExpandedRows = [...expandedRows];
    if (newExpandedRows.includes(index)) {
      newExpandedRows = newExpandedRows.filter(
        (rowIndex) => rowIndex !== index
      );
    } else {
      newExpandedRows.push(index);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setFilter({
      ...filter,
      start_date: start ? format(start, "dd-MM-yyyy") : "",
      end_date: end ? format(end, "dd-MM-yyyy") : "",
    });
  };

  const handleResetDateFilter = () => {
    setFilter({
      ...filter,
      start_date: "",
      end_date: "",
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (fetchBookings) {
      const { bookingType } = location.state;
      setBookingStatusType(bookingType);

      getCurrentBookings()
        .then((resp) => {
          let bookingData;
          switch (bookingType) {
            case "today":
              bookingData = resp.data.todays_bookings?.todays_bookings_data;
              break;
            case "tomorrow":
              bookingData =
                resp.data.tomorrows_bookings?.tomorrows_bookings_data;
              break;
            case "pending":
              bookingData = resp.data.pending_bookings?.pending_bookings_data;
              break;
            case "cancelled":
              bookingData =
                resp.data.cancelled_bookings?.cancelled_bookings_data;
              break;
            default:
              bookingData = resp.data.todays_bookings?.todays_bookings_data;
          }
          setData(bookingData);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching bookings:", error);
          setIsLoading(false);
        });
    } else {
      getBookingStatus(
        currentPage,
        recordsPage,
        searchQuery,
        filter.room_type,
        filter.start_date,
        filter.end_date,
        sortBy,
        filterStatus
      )
        .then((resp) => {
          setData(resp.data.results);
          setTotal(resp.data.count);

          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching bookings:", error);
          setIsLoading(false);
        });
    }
  }, [
    fetchBookings,
    currentPage,
    searchQuery,
    filter.room_type,
    filter.start_date,
    filter.end_date,
    sortBy,
    filterStatus,
  ]);
  useEffect(() => {
    if (location.state && location.state.bookingNumber) {
      setSearchQuery(location.state.bookingNumber);
      navigate("/booking-status", { state: { ...location.state, bookingNumber: null } });
      
    }
  }, [location.state, navigate]);

  useEffect(() => {
    setSearchQuery(null);

  }, []);

  const handleCloseBadge = () => {
    navigate("/booking-status", { state: { fetchBookings: false } });
  };

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilter = (status) => {
    setFilterStatus(status);
    setCurrentPage(1);
  };

  const handleFilterChange = (name, value) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
    setCurrentPage(1);
  };

  const updateBookingData = (updatedBooking) => {
    setData(
      data.map((item) => {
        if (item.id === updatedBooking.id) {
          return updatedBooking;
        }
        return item;
      })
    );
  };

  const disableDates = (date) => {
    const startMonth = filter.start_date
      ? getMonth(new Date(filter.start_date))
      : null;
    const startYear = filter.start_date
      ? getYear(new Date(filter.start_date))
      : null;
    return startMonth !== null && startYear !== null
      ? getMonth(date) !== startMonth || getYear(date) !== startYear
      : false;
  };

  const handleSort = (key) => {
    const sorbtbysplit = sortBy.split("-");
    const isAsc = sorbtbysplit.length > 1;
    const sortByValue = isAsc ? sorbtbysplit[1] : sortBy;
    if (sortByValue === key) {
      setSortBy(isAsc ? key : `-${key}`);
    } else {
      setSortBy(key);
    }
  };

  const renderSortIcon = (key) => {
    const sorbtbysplit = sortBy.split("-");
    const isAsc = sorbtbysplit.length > 1;
    const sortByValue = isAsc ? sorbtbysplit[1] : sortBy;
    if (sortByValue === key) {
      return isAsc ? <HiArrowNarrowUp /> : <HiArrowNarrowDown />;
    }
    return (
      <>
        <LuArrowUpDown />
      </>
    );
  };

  return (
    <>
      <Header />
      <Container className="Container mt-sm-0 mt-6 mb-sm-0 mb-7 p-sm-2" fluid>
        <div className="d-flex flex-wrap align-items-center justify-content-between p-2">
          {fetchBookings ? (
            bookingStatusType ? (
              <div className="badge-container mb-3">
                <span
                  className="mb-3 text-dark fw-bold pb-2"
                  style={{ color: "#6c757d" }}
                >
                  <i className="fas fa-check-circle me-2"></i>
                  Booking Status:{" "}
                  <div className="badge-container">
                    <div className="badge-text">
                      {bookingStatusType}
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={handleCloseBadge}
                      >
                        <FaTimes />
                      </button>
                    </div>
                  </div>
                </span>
              </div>
            ) : null
          ) : (
            <h3
              className="mb-3 text-dark fw-bold pb-2"
              style={{ color: "#6c757d" }}
            >
              <i className="fas fa-check-circle me-2"></i>
              Booking Status
            </h3>
          )}
          <div className={`row align-items-end ${isMobile ? "w-100" : "w-50"}`}>
            <div className="col-md-6 mb-3 position-relative">
              <Flatpickr
                value={[filter.start_date, filter.end_date]}
                onChange={handleDateChange}
                options={{
                  mode: "range",
                  dateFormat: "d-m-Y",
                  disable: [
                    {
                      from: new Date(0),
                      to: new Date(),
                      function(date) {
                        return disableDates(date);
                      },
                    },
                  ],
                }}
                className="form-control custom-flatpickr w-full p-2 mx-auto border border-gray-800 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2"
                placeholder="Select date range"
                style={{ fontSize: "0.75rem" }}
              />
              {filter.start_date && filter.end_date && (
                <i
                  className="fas fa-times position-absolute"
                  style={{
                    right: "46px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "#999999",
                  }}
                  onClick={handleResetDateFilter}
                />
              )}
            </div>

            <div className="col-md-6 mb-3">
              <div className="search-bar">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <i className="fas fa-search search-icon"></i>
              </div>
            </div>
          </div>

          <style jsx>{`
            .custom-flatpickr {
              border: 1px solid #ced4da !important;
            }
            .custom-flatpickr:focus {
              border-color: black !important;
            }
            .sorting {
              font-size: 0.6rem;
            }
            .disable-select {
              user-select: none;
            }
          `}</style>
        </div>

        <div className="summary-section d-flex align-items-center justify-content-between p-2 mx-2">
          <div className="d-flex align-items-center">
            {!fetchBookings ? (
              <>
                <RoomTypeDropDown setFilter={setFilter} filter={filter} setCurrentPage={setCurrentPage} />
                <StatusTypeDropDown setFilterStatus={setFilterStatus} filterStatus={filterStatus} setCurrentPage={setCurrentPage} />
              </>
            ) : (
              ""
            )}
          </div>
          {!isMobile && (
            <div className="me-3 ms-auto">
              <button
                className="btn btn-info"
                onClick={() => setShowModal(true)}
              >
                <BsListColumns style={{ fontSize: "1.4rem" }} />
              </button>
              <Modal
                className="table-component__modal"
                show={showModal}
                onHide={() => setShowModal(false)}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Select Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-component__column-options">
                    <div className="table-component__select-all">
                      <label className="table-component__checkbox-label">
                        <input
                          type="checkbox"
                          className="me-3"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                        Select All
                      </label>
                    </div>
                    {renderColumnOptions(initialColumns)}
                    {renderColumnOptions(additionalColumns)}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="table-component__close-btn"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="table-component__apply-btn"
                    onClick={handleApplyColumns}
                  >
                    Apply
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
          <div>
            <DownloadExcel
              currentPage={currentPage}
              recordPage={recordsPage}
              searchQuery={searchQuery}
              roomType={filter.room_type}
              startDate={filter.start_date}
              endDate={filter.end_date}
              fromDashboard={fetchBookings}
              bookingType={bookingStatusType}
              status={filterStatus}
            />

          </div>
        </div>

        {isMobile ? (
          <Row>
            <div className="col" id="table-to-download">
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light disable-select">
                    <tr style={{ fontSize: "1rem" }}>
                      <th
                        scope="col"
                        className="py-3"
                        onClick={() => handleSort("booking_slot__room__name")}
                      >
                        <div className="d-flex align-items-center">
                          Room
                          <span className="ms-1">
                            {renderSortIcon("booking_slot__room__name")}
                          </span>
                        </div>
                      </th>

                      <th
                        scope="col"
                        className="py-3"
                        onClick={() => handleSort("booking_slot__booking_date")}
                      >
                        <div className="d-flex align-items-center">
                          Booking Dt
                          <span className="ms-1">
                            {renderSortIcon("booking_slot__booking_date")}
                          </span>
                        </div>
                      </th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="3" className="text-center my-7">
                          <Loader />
                        </td>
                      </tr>
                    ) : data ? (
                      data?.map((item, index) => (
                        <React.Fragment key={index}>
                          <tr
                            key={index}
                            className={`${item?.booking_status === "Cancelled" ||
                              item?.booking_slot?.status === "Closed" || item?.booking_status === "Refunded"
                              ? "text-gray"
                              : ""
                              }`}
                          >
                            <td>
                              {item.booking_slot?.room?.name}
                              {item.booking_status === "Modified" &&
                                item.booking_slot?.status === "Booked" && (
                                  <OverlayTrigger
                                    trigger="click"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-modify-${index}`}>
                                        <div
                                          style={{
                                            padding: "15px",
                                            color: "#fff",
                                            backgroundColor: "#343a40",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <strong style={{ fontSize: "14px" }}>
                                            Previous Details
                                          </strong>
                                          <hr
                                            style={{
                                              color: "white",
                                              margin: "5px 0",
                                              borderColor: "white",
                                            }}
                                          />
                                          <div style={{ fontSize: "12px" }}>
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Booking Date:{" "}
                                            </span>
                                            {item.previous_booking_date
                                              ? item.previous_booking_date
                                              : "N/A"}
                                            <br />
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Booking Time:{" "}
                                            </span>
                                            {item.previous_booking_time
                                              ? item.previous_booking_time
                                              : "N/A"}
                                          </div>
                                        </div>
                                      </Tooltip>
                                    }
                                    rootClose={true}
                                  >
                                    <i
                                      className="fas fa-info-circle ms-1"
                                      style={{
                                        color: "blue",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </OverlayTrigger>
                                )}
                            </td>
                            <ApproveBookingModal
                              booking={item}
                              updateBookingData={updateBookingData}
                            />{" "}
                            <td>
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={() => handleRowExpandToggle(index)}
                              >
                                More...
                              </Button>
                            </td>
                          </tr>
                          {expandedRows.includes(index) && (
                            <tr>
                              <td colSpan="3">
                                <div>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      color: "#555",
                                    }}
                                  >
                                    <strong style={{ color: "#333" }}>
                                      Booking Number:{" "}
                                    </strong>
                                    {item.booking_number}
                                  </div>

                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      color: "#555",
                                    }}
                                  >
                                    <strong style={{ color: "#333" }}>
                                      Booking Time:{" "}
                                    </strong>
                                    {item.booking_slot?.booking_time}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      color: "#555",
                                    }}
                                  >
                                    <strong style={{ color: "#333" }}>
                                      Member Name:{" "}
                                    </strong>
                                    {item?.user?.first_name}{" "}
                                    {item?.user?.last_name}
                                    {item.is_on_behalf_booking && (
                                      <span
                                        style={{
                                          fontSize: "0.6rem",
                                          color: "gray",
                                        }}
                                      >
                                        (Booked By Super Admin)
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      color: "#555",
                                    }}
                                  >
                                    <strong style={{ color: "#333" }}>
                                      Email:{" "}
                                    </strong>
                                    {item?.user?.email}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      color: "#555",
                                    }}
                                  >
                                    <strong style={{ color: "#333" }}>
                                      Mobile No.:{" "}
                                    </strong>
                                    {item?.user?.phone_number}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      color: "#555",
                                    }}
                                  >
                                    <strong style={{ color: "#333" }}>
                                      Date of Booking:{" "}
                                    </strong>
                                    {item.created_at}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      color: "#555",
                                    }}
                                  >
                                    <strong style={{ color: "#333" }}>
                                      Price:{" "}
                                    </strong>
                                    {(
                                      item.booking_slot?.room.price * 1.18
                                    ).toFixed(2)}
                                  </div>

                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      color: "#555",
                                    }}
                                    className="d-flex align-items-center"
                                  >
                                    <strong
                                      className="me-3"
                                      style={{ color: "#333" }}
                                    >
                                      Status:{" "}
                                    </strong>
                                    <span>
                                      {item?.booking_status === "Cancelled" && item?.refund === null ? (
                                        <span className="text-danger px-2">
                                          Cancelled
                                        </span>
                                      ) : item?.booking_status === "Pending" ? (
                                        <ConfirmBookingModal
                                          transactionId={
                                            item?.transaction?.transaction_id
                                          }
                                          payment_source={
                                            item?.booking_payment_source
                                          }
                                          booking={item}
                                          updateBookingData={updateBookingData}
                                        />
                                      ) : item?.refund?.refund_status === "Refund Requested" || item?.refund?.refund_status === "Refunded" ? (
                                        <UserRefundRequest
                                          booking={item}
                                          updateBookingData={updateBookingData}
                                        />
                                      ) : item?.booking_slot?.status ===
                                      "Booked" &&
                                      (<EditBookingModal
                                        booking={item}
                                        updateBookingData={updateBookingData}
                                      />
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableNoResultFound />
                    )}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <Pagination
                    currentPage={currentPage}
                    recordsPage={recordsPage}
                    dataLength={total}
                    handlePagination={handlePagination}
                  />
                </CardFooter>
              </Card>
            </div>
          </Row>
        ) : (
          <Row>
            <div className="col" id="table-to-download">
              <Card className="shadow">
                <Table className="table-component__table align-items-center table-flush" responsive>
                  <thead className="thead-light disable-select">
                    <tr style={{ fontSize: "1rem" }}>
                      {columnOrder.map((sortKey, index) => {
                        const header = visibleHeaders.find((h) => h.sortKey === sortKey);
                        return (
                          <th
                            key={index}
                            scope="col"
                            className="py-3"
                            onClick={() => handleSort(header.sortKey)}
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDrop={(e) => handleDrop(e, index)}
                            onDragOver={handleDragOver}
                          >
                            <div style={{ display: "flex", alignItems: "center" }}>
                              {header.label}
                              <span className="ms-1">{renderSortIcon(header.sortKey)}</span>
                            </div>
                          </th>
                        );
                      })}
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={columnOrder.length} className="text-center my-7">
                          <div className="text-center my-7" style={{ width: "100%", height: "100%" }}>
                            <div className="spinner-grow text-black me-2" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : data ? (
                      data.map((item, index) => (
                        <tr
                          key={index}
                          className={`${item?.booking_status === "Cancelled" ||
                            item?.booking_slot?.status === "Closed" ||
                            item?.booking_status === "Refunded"
                            ? "text-gray"
                            : ""
                            }`}
                        >
                          {columnOrder.map((sortKey, i) => {
                            const header = visibleHeaders.find((h) => h.sortKey === sortKey);
                            return (
                              <td key={i}>
                                {sortKey === "booking_number" && item.booking_status === "Modified" ? (
                                  <>
                                    {header.accessor(item)}
                                    {item.booking_slot?.status === "Booked" && (
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="top"
                                        overlay={
                                          <Tooltip id={`tooltip-modify-${index}`}>
                                            <div
                                              style={{
                                                padding: "15px",
                                                color: "#fff",
                                                backgroundColor: "#343a40",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <strong style={{ fontSize: "14px" }}>Previous Details</strong>
                                              <hr
                                                style={{
                                                  color: "white",
                                                  margin: "5px 0",
                                                  borderColor: "white",
                                                }}
                                              />
                                              <div style={{ fontSize: "12px" }}>
                                                <span style={{ fontWeight: "bold" }}>Booking Date: </span>
                                                {item?.previous_booking_date || "N/A"}
                                                <br />
                                                <span style={{ fontWeight: "bold" }}>Booking Time: </span>
                                                {item.previous_booking_time || "N/A"}
                                              </div>
                                            </div>
                                          </Tooltip>
                                        }
                                        rootClose
                                      >
                                        <i className="fas fa-info-circle ms-1" style={{ color: "blue", cursor: "pointer" }}></i>
                                      </OverlayTrigger>
                                    )}
                                  </>
                                ) : (
                                  header.accessor(item)
                                )}
                              </td>
                            );
                          })}
                          <td>
                            {item?.booking_status === "Cancelled" && item?.refund === null ? (
                              <span className="text-danger px-2">Cancelled</span>
                            ) : item?.booking_status === "Pending" ? (
                              <ConfirmBookingModal
                                transactionId={item?.transaction?.transaction_id}
                                booking={item}
                                updateBookingData={updateBookingData}
                                payment_source={item?.booking_payment_source}
                              />
                            ) : item?.refund?.refund_status === "Refund Requested" || item?.refund?.refund_status === "Refunded" ? (
                              <UserRefundRequest booking={item} updateBookingData={updateBookingData} />
                            ) : item?.booking_status === "Refunded" ? (
                              <span className="text-purple px-2">Refunded</span>
                            ) : (
                              <EditBookingModal booking={item} updateBookingData={updateBookingData} />
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <TableNoResultFound />
                    )}
                  </tbody>
                </Table>


                <CardFooter className="py-4">
                  <Pagination
                    currentPage={currentPage}
                    recordsPage={recordsPage}
                    dataLength={total}
                    handlePagination={handlePagination}
                  />
                </CardFooter>
              </Card>
            </div>
          </Row>
        )}
      </Container>
    </>
  );
};

export default BookingStatus;
